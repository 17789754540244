import strings from '../config/strings'

export const SET_LOADING = 'SET_LOADING'


export const setLoading = loading_state => {
  // console.log("setLoading to " + loading_state);
  return {
    type: SET_LOADING,
    payload: loading_state
  }
}
