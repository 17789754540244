import firebase from 'firebase'
import 'firebase/firestore'

import {
    API_KEY,
    AUTH_DOMAIN,
    DATABASE_URL,
    PROJECT_ID,
    MESSAGE_SENDER_ID,
    APP_ID,
    STORAGE_BUCKET,
    MEASUREMENT_ID
} from 'react-native-dotenv'
//
// //THIS IS WORKAROUND DUE FIREBASE FIRESTONE BUG AFTER V7.9.0
// //TODO: UPDATE WHEN Firebase sdk is fixed, https://github.com/expo/expo/issues/7507
// import { decode, encode } from 'base-64'
// global.crypto = require("@firebase/firestore");
// global.crypto.getRandomValues = byteArray => { for (let i = 0; i < byteArray.length; i++) { byteArray[i] = Math.floor(256 * Math.random()); } }
// if (!global.btoa) { global.btoa = encode; }
// if (!global.atob) { global.atob = decode; }


const firebaseConfig = {
    apiKey: API_KEY,
    authDomain: AUTH_DOMAIN,
    databaseURL: DATABASE_URL,
    projectId: PROJECT_ID,
    storageBucket: STORAGE_BUCKET,
    messagingSenderId: MESSAGE_SENDER_ID,
    appId: APP_ID,
    storageBucket: STORAGE_BUCKET,
    measurementId: MEASUREMENT_ID
}

// Initialize Firebase
const Firebase = firebase.initializeApp(firebaseConfig)
Firebase.analytics();

export const db = firebase.firestore()
export const fb_functions = firebase.functions();

export default Firebase
