import React from 'react'
import {Dimensions,ScrollView,Linking,TouchableOpacity,Image, View, Text, StyleSheet, Button } from 'react-native'
import {connect} from 'react-redux'
import Firebase, { fb_functions }  from '../config/Firebase'
import * as RootNavigation from '../controllers/RootNavigation';
import strings from "../config/strings"
import colors from "../config/colors"
import PopUpButton from "../components/PopUpButton"
import imagepaths from '../config/imagepaths';
import ServiceTermsScreen from '../screens/ServiceTermsScreen'
import metrics, { logowidth } from "../config/metrics"
import { updateEmail, updatePassword, logout} from '../actions/User'
import { bindActionCreators } from 'redux'
// import * as MailComposer from 'expo-mail-composer';
import Constants from 'expo-constants'

class ProfileScreen extends React.Component {

  handleSignout = async () => {
    // console.log("handleSignout for user " + this.props.user.email);
    this.props.logout();
  }

  // handleSubscriptionCancel = async () => {
  //   // console.log("handleSubscriptionCancel for user " + this.props.user.email);
  //    // Linking.openURL("mailto:support@popupyogaoulu.com");
  //    MailComposer.composeAsync({
  //           subject: 'Videopalvelun tilauksen peruutus',
  //           body: 'Peruisin videopalvelun tilauksen \nkäyttäjälle: '+ this.props.user.email + ' \nalkaen ' + Date(),
  //           recipients: ['support@popupyogaoulu.com'],
  //           isHtml: true
  //       }).then( (response) => {
  //         alert("Mikäli lähetit sähköpostin niin peruutuksesi käsitellään mahdollisimman pian eikä sinulta veloiteta seuraavaa katselukautta.")
  //       })
  //       .catch( error => {
  //         // console.log("Error: " + error);
  //       });
  // }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', (e) => {
      const { width, height } = e.window;
      this.widthScale = this.calculateImageScaleFactor(width,height)
      this.width = width;
      this.height = height;
      this.forceUpdate();
    })
  }

  constructor() {
    super()
    this.width = Dimensions.get('window').width
    this.height = Dimensions.get('window').height
    this.widthScale = this.calculateImageScaleFactor(Dimensions.get('window').width)
  }

  calculateImageScaleFactor = (width,height) => {
    // console.log("widthScale =" + width/800*0.70);
    // console.log("heightScale = " + height/200);
    return width/240*0.70;
  }

  showServiceTerms = () => {
    // console.log("showServiceTerms");
    RootNavigation.navigate('ShowServiceTerms');
  }

  onCancelSubscription = () => {
    // console.log("showServiceTerms");
    RootNavigation.navigate('ShowCancelSubscriptionScreen');
  }

  onManageSubscription = () => {
    //console.log("onManageSubscription");
    var createPortalLink = Firebase.functions().httpsCallable('createPortalLink');
    createPortalLink({ returnUrl: Constants.manifest.extra.app_baseurl })
    .then((result) => {
      // // Read result of the Cloud Function.
      //console.log("Got billingportal session => ",JSON.stringify(result.data));
      var stripe_billingportal_url = result.data.url;
      if (stripe_billingportal_url != null) {
      Linking.openURL(stripe_billingportal_url);
    }
  }).catch((error) => {
    if ( error.code === 'not-found') {
      //console.log("This user doesn't have stripe customer id in subscription collection");
    } else {
      //console.log("Error while loading billing portal => " + error);
    }
    RootNavigation.navigate('ShowCancelSubscriptionScreen');
    alert(strings.TRY_CANCELLING_WITH_EMAIL)
  });
}

  onVerifyEmailAddress() {
    // console.log("onVerifyEmailAddress");
    var user = Firebase.auth().currentUser;
    user.sendEmailVerification().then(function() {
      alert("Email sent, check your inbox")
    }).catch(function(error) {
      // console.log("Error: " + error);
    });
  }

  onUpdateProfilePicture() {
    // console.log("onUpdateProfilePicture");
  }

  getUserProfilePicture(){
    // console.log("getUserProfilePicture");
  }

  onForgotPassword() {
    // console.log("onForgotPassword");
    RootNavigation.navigate('ShowForgotPasswordScreen')
  }

  render() {
    return (
      <ScrollView>
      <View style={styles.container}>
        <View style={styles.form}>
          {!Firebase.auth().currentUser || !Firebase.auth().currentUser.photoURL && (<Image style={[styles.logo,{width: 120, height:120}]} source={imagepaths.logo} />)}
          {Firebase.auth().currentUser.photoURL && (<Image style={styles.profileImage} source={{uri: Firebase.auth().currentUser.photoURL}} />)}
          <Text style={styles.formText}>{Firebase.auth().currentUser.displayName}</Text>
          <Text style={styles.formText}>{this.props.user.email}</Text>
          <Text style={styles.formText}></Text>
        </View>
        <View style={styles.sectionHeader}>
          <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, padding:10, alignSelf:'center'}} onPress={() => this.onForgotPassword()}>
             <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.FORGOT_PASSWORD} </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.sectionHeader}>
          <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, padding:15, alignSelf:'center'}} onPress={() => this.onManageSubscription()}>
             <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.MANAGE_SUBSCRIPTION} </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.sectionHeader}>
          <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, padding:15, alignSelf:'center'}} onPress={() => this.handleSignout()}>
             <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGOUT} </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.sectionHeader}>
          <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, padding:15, alignSelf:'center'}}  onPress={() => this.showServiceTerms()}>
            <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
         </TouchableOpacity>
       </View>
     </View>
     </ScrollView>
    )
  }
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.WHITE,
      alignItems: 'center',
      justifyContent: "space-between",
    },
    logo: {
      marginTop: "10%",
      marginBottom: 10,
      alignSelf: "center",
      // width: logowidth(),
      // height: logowidth(),
    },
    profileImage: {
      marginTop: "10%",
      marginBottom: 10,
      alignSelf: "center",
      width: logowidth(),
      height: logowidth(),
      borderRadius: 150 / 2,
      overflow: "hidden",
      borderWidth: 1,
      borderColor: colors.POPUP_LOGO_PURPLE
    },
    form: {
      flex: 1,
      backgroundColor: colors.WHITE,
      justifyContent:'flex-start',
      width: "80%",
    },
    formText: {
      fontSize: 18,
      color: colors.BLACK,
      padding:10,
      alignSelf:'center'
    },
     sectionHeader: {
       width: "80%",
       alignItems: 'flex-start',
       borderWidth: StyleSheet.hairlineWidth,
       borderColor: colors.POPUP_CYAN_D,
       backgroundColor: colors.WHITE,
     },
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateEmail, updatePassword, logout }, dispatch)
}

const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(
      mapStateToProps,
      mapDispatchToProps
)(ProfileScreen)
