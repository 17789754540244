import React, { Component } from 'react';
import { Platform, StyleSheet, Image, Button, View, Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import {createStackNavigator, StackNavigator} from '@react-navigation/stack'
import { navigationRef } from './src/controllers/RootNavigation';
import { useNavigation } from '@react-navigation/native';

import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import {createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import reducer from './src/reducers'

import SignUpScreen from './src/screens/SignUpReduxScreen'
import SplashScreen from './src/screens/SplashScreen'
import LoginScreen from './src/screens/LoginWebScreen'
import SubscriptionOfferScreen from './src/screens/SubscriptionOfferScreen'
import ForgotPasswordScreen from './src/screens/ForgotPasswordScreen'
import WaitToVerifyEmailScreen from './src/screens/WaitToVerifyEmailScreen'
import ProfileScreen from './src/screens/ProfileScreen'
import CancelSubscriptionScreen from './src/screens/CancelSubscriptionScreen'
import HomeScreen from './src/screens/HomeScreen'
import ServiceTermsScreen from './src/screens/ServiceTermsScreen'
import VideoItemDetailsScreen from './src/screens/VideoItemDetailsScreen'
import WebVideoPlayer from './src/screens/WebVideoPlayer'

import imagepaths from './src/config/imagepaths';
import colors from "./src/config/colors"
import strings from "./src/config/strings"

import * as Analytics from 'expo-firebase-analytics';


const Stack = createStackNavigator();
const middleware = applyMiddleware(thunkMiddleware)
const store = createStore(reducer, middleware)

function Login() {
  const navi = useNavigation();
  return (
     <LoginScreen/>
  );
}

function ShowSplash({navigation}) {
  const navi = useNavigation();
  return (
    <SplashScreen/>
  );
}

function SubscriptionOffer({route, navigation}) {
  const navi = useNavigation();
  const { userId, user_email} = route.params;
  return (
    <SubscriptionOfferScreen
      userId={userId}
      email={user_email}/>
  );
}

function SignUp({navigation}) {
  const navi = useNavigation();
  return (
    <SignUpScreen/>
  );
}

function ShowHomeScreen({route, navigation}) {
  const navi = useNavigation();
  const { userId } = route.params;
  return (
    <HomeScreen userId={userId}/>
  );
}

function WaitForEmailVerification({route, navigation}) {
  const navi = useNavigation();
  const { userId } = route.params;
  return (
    <WaitToVerifyEmailScreen userId={userId}/>
  );
}

function PlayWebVideo({route, navigation}) {
  const navi = useNavigation();
  const { videoUrl} = route.params;
  // console.log("videoUrl is " + videoUrl )
  return (
    <WebVideoPlayer videoUrl={videoUrl}/>
  );
}

function ShowCancelSubscriptionScreen() {
  const navi = useNavigation();
  // console.log("ShowProfile")
  return (
    <CancelSubscriptionScreen/>
  );
}

function ShowProfile() {
  const navi = useNavigation();
  // console.log("ShowProfile")
  return (
    <ProfileScreen/>
  );
}

function ShowForgotPasswordScreen() {
  const navi = useNavigation();
  // console.log("ShowForgotPasswordScreen")
  return (
    <ForgotPasswordScreen/>
  );
}

function ShowServiceTerms() {
  // console.log("ShowServiceTerms")
  return (
    <ServiceTermsScreen/>
  );
}

function ShowPurchaseVideoSubscription({route, navigation}) {
  // console.log("ShowPurchaseVideoSubscription")
  const { userId } = route.params;
  return (
    <PurchaseVideoSubscription
      userId={userId}/>
  );
}

function ShowVideoDetails({route, navigation}) {
  // console.log("ShowVideoDetails")
  const { videoItem} = route.params;
  return (
    <VideoItemDetailsScreen
      videoItem={videoItem}/>
  );
}


export default class App extends Component<{}, State> {

  state = {
    isReady: false,
  };

  constructor() {
    // console.log("App.js Construcor");
    super();
    //TODO: Use this for production and testing
    Analytics.setAnalyticsCollectionEnabled(true)
    // Analytics.setDebugModeEnabled(true)
    Analytics.setUserProperty('client_type','web_app')
  }

  async _cacheResourcesAsync() {
    const imageAssets = [
      require('./src/images/logo_240x240.jpeg'),
      require('./src/images/logo_320x320_transparent.png'),
      require('./src/images/icon_info_512x512.png'),
      require('./src/images/icon_profile_1600x1600.png'),
      require('./src/images/ope_heidi_768x960.jpg'),
      require('./src/images/ope_jenni_922x1229.jpg'),
      require('./src/images/ope_katariina_768x960.jpg'),
      require('./src/images/ope_katja_902x1353.jpg'),
      require('./src/images/ope_katriina_750x750.jpg'),
      require('./src/images/ope_maria_856x1284.jpg'),
      require('./src/images/ope_marjo_768x960.jpg'),
      require('./src/images/ope_marru_818x1228.jpg'),
      require('./src/images/ope_merja_902x1353.jpg'),
      require('./src/images/ope_minnamari_870x1305.jpg'),
      require('./src/images/ope_riikka_832x832.jpg'),
      require('./src/images/ope_saanatuulia_768x960.jpg'),
      require('./src/images/ope_saara_480x640.jpg'),
      require('./src/images/ope_seija_683x853.jpg'),
      require('./src/images/ope_tiitta_480x640.jpg'),
      require('./src/images/ope_heli_770x770.jpg'),
      require('./src/images/ope_anu_961x640.jpg'),
      require('./src/images/ope_david_768x960.jpg'),
      require('./src/images/icon_favourites_add_600×558.png'),
      require('./src/images/icon_favourites_remove_600×558.png'),
      require('./src/images/opet_background_800x216.png'),
      require('./src/images/background_800x900_20p_opaque.png')
  ];
    const images = imagepaths;
    const cacheImages = imageAssets.map(image => {
      return Asset.fromModule(image).downloadAsync();
    });
    return Promise.all(cacheImages);
  }


  getActiveRouteName(navigationState) {
    if (!navigationState) return null;
    const route = navigationState.routes[navigationState.index];
    // Parse the nested navigators
    if (route.routes) return getActiveRouteName(route);
    return route.name;
  }



  render() {
    if (!this.state.isReady) {
      return (
        <AppLoading
          startAsync={this._cacheResourcesAsync}
          onFinish={() => this.setState({ isReady: true })}
          onError={console.warn}
        />
      );
    }

    return (
      <Provider store ={store}>
        <NavigationContainer ref={navigationRef}
          onStateChange= {(state) => {
            const currentScreen = this.getActiveRouteName(state);
            // Update Firebase with the name of your screen
            // console.log("set current screen to " + currentScreen);
            Analytics.setCurrentScreen(currentScreen);
          }}
          >
          <Stack.Navigator   screenOptions={{ headerShown: true, headerBackTitle:strings.BACK_BUTTON, headerStyle: { backgroundColor: 'white' }}}>
            <Stack.Screen name="ShowSplash" component={ShowSplash} options={{headerShown: false, title: strings.EMPTY_SCREEN_TITLE}} />
            <Stack.Screen name="Login" component={Login} options={{headerShown:false, title: strings.EMPTY_SCREEN_TITLE}}/>
            <Stack.Screen name="WaitForEmailVerification" component={WaitForEmailVerification} options={{headerShown: false, title: strings.EMPTY_SCREEN_TITLE}} />
            <Stack.Screen name="SignUp" component={SignUp} options={{headerShown: false, title: strings.EMPTY_SCREEN_TITLE}} />
            <Stack.Screen name="SubscriptionOffer" component={SubscriptionOffer} options={{headerShown: false, title: strings.EMPTY_SCREEN_TITLE}} />
            <Stack.Screen name="ShowHomeScreen" component={ShowHomeScreen} options={{headerShown:false, gestureEnabled: false, title: strings.EMPTY_SCREEN_TITLE}} />
            <Stack.Screen name="PlayWebVideo" component={PlayWebVideo} options={{title: strings.EMPTY_SCREEN_TITLE }} />
            <Stack.Screen name="ShowProfile" component={ShowProfile} options={{title: strings.EMPTY_SCREEN_TITLE }} />
            <Stack.Screen name="ShowCancelSubscriptionScreen" component={ShowCancelSubscriptionScreen} options={{title: strings.EMPTY_SCREEN_TITLE }} />
            <Stack.Screen name="ShowServiceTerms" component={ShowServiceTerms} options={{title: strings.EMPTY_SCREEN_TITLE }} />
            <Stack.Screen name="ShowForgotPasswordScreen" component={ShowForgotPasswordScreen} options={{title: strings.EMPTY_SCREEN_TITLE,headerStyle: { backgroundColor: 'white'}}} />
            <Stack.Screen name="ShowVideoDetails" component={ShowVideoDetails} options={{title: strings.EMPTY_SCREEN_TITLE }} />
            <Stack.Screen name="ShowPurchaseVideoSubscription" component={ShowPurchaseVideoSubscription} options={{headerShown: false, title: strings.EMPTY_SCREEN_TITLE}} />
          </Stack.Navigator>
        </NavigationContainer>
      </Provider>
    );
  }
}
