import React from "react";
import { KeyboardAvoidingView, Linking, ScrollView, TouchableOpacity, Dimensions, Image, StyleSheet, Text, View} from 'react-native';
import {createStackNavigator, StackNavigator} from '@react-navigation/stack'
import { LinearGradient } from 'expo-linear-gradient';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateEmail, updatePassword, signup, getUser, logout } from '../actions/User'
import PopUpButton from '../components/PopUpButton'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import PopUpTextInput from '../components/PopUpTextInput';
import * as RootNavigation from '../controllers/RootNavigation';
import Firebase, { db } from '../config/Firebase'
import Constants from 'expo-constants'
import * as Analytics from 'expo-firebase-analytics';

interface Props {
  userId:string;
  email:string;
}

class SubscriptionOfferScreen extends React.Component<Props>  {


  width  = 0;
  height = 0;

  constructor() {
    super()
    this.width = Dimensions.get('window').width
    this.height = Dimensions.get('window').height
    this.widthScale = this.calculateImageScaleFactor(Dimensions.get('window').width)
  }


  componentDidMount = () => {
    // console.log("SubscriptionOfferScreen.componentDidMount");
    // console.log("h " + Dimensions.get('window').height);
    // console.log("w " + Dimensions.get('window').width);
    Dimensions.addEventListener('change', (e) => {
      // console.log("update " + e.window.width);
      const { width, height } = e.window;
      this.widthScale = this.calculateImageScaleFactor(width,height)
      this.width = width;
      this.height = height;
      this.forceUpdate();
    })
  }

  onLogout = async () => {
      // console.log("WaitToVerifyEmailScreen.onLogout");
      var currentRoute = RootNavigation.getCurrentRouteName();
      // console.log("current route is "+ currentRoute);
      this.props.logout();
  }

  onStartSubscription = () => {
      // console.log("SubscriptionOfferScreen.onStartSubscription");
      if (this.props.userId=="" && this.props.email=="") {
        // console.log("WARN: No user");
        this.props.logout();
     } else if (this.props.userId == "" || this.props.userId==null) {
        // console.log("Subscribe new user with email");
        Analytics.logEvent('subscription_v1', {
          result: 'in_progress',
          reason: 'show payment page',
          message: 'subscribe as new user with email',
        })
        Linking.openURL(Constants.manifest.extra.stripe_checkout_baseurl+'subscribe_new_customer_with_email.html?customerEmail='+this.props.email)
      } else {
        // console.log("Activate subscription for existing user");
        Analytics.logEvent('subscription_v1', {
          result: 'in_progress',
          reason: 'show payment page',
          message: 'subscribe as existing user with email and userId',
        })
        Linking.openURL(Constants.manifest.extra.stripe_checkout_baseurl+'activate_subscription_for_old_customer.html?UserId='+this.props.userId+'&customerEmail='+this.props.email)
      }
  }

  showLoginScreen = () => {
      // console.log("SubscriptionOfferScreen.showLoginScreen");
      RootNavigation.navigate('Login');
  }

  showServiceTerms = () => {
    // console.log("showServiceTerms");
    RootNavigation.navigate('ShowServiceTerms');
  }

  calculateImageScaleFactor = (width,height) => {
    // console.log("widthScale =" + width/800*0.70);
    // console.log("heightScale = " + height/200);
    return width/800*0.70;
  }

  render() {
    const width = this.width;
    const height = this.height;
    var scale = this.widthScale;
    if ( scale > 1.5 ) {
      scale = 1.5
    }
//    console.log("HEIGHT " + height);
    if (width< 360
      ) {
      return (
        <View style={styles.containerPhonePortrait}>
          <View style={styles.containerTopPhonePortrait}>
            <Image
              style={styles.logoLeftPhonePortrait}
              source={imagepaths.logo_transparent}
              />
            <Image
              style={[styles.opeImagePhonePortrait,{width: 800*(scale), height:200*(scale)}]}
              source={imagepaths.opet_background}
              />
          </View>
          <View style={styles.containerPhonePortraitBottom}>
            <KeyboardAvoidingView style={styles.formPhonePortrait}>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.POPUP_LOGO_PINK,fontWeight: 'bold', textAlign: 'left',marginBottom: "2%"}}>{strings.SUBSCRIPTION_OFFER_TITLE}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 12, color: colors.WHITE, textAlign: 'left', marginBottom: "4%"}}>{strings.PAYMENT_PITCH}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 12, color: colors.WHITE, textAlign: 'left', marginBottom: "1%"}}>{strings.PAYMENT_INFO}</Text>
              <PopUpButton
                textStyle={{fontSize:14}}
                containerStyle={{width:"100%",alignSelf:'center'}}
                label={strings.JOIN_POP_UP_COMMUNITY}
                 onPress={() => this.onStartSubscription()}
                />
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.onLogout()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "1%"}}>
                <Text style={{fontSize: 12, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.LOGOUT} </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.showServiceTerms()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "2%"}}>
                <Text style={{fontSize: 12, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                <Text style={{fontSize: 12, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
              </View>
            </TouchableOpacity>
           </View>
        </View>
      );
    }
    else if (width< 420  ) {
      return (
        <View style={styles.containerPhonePortrait}>
          <View style={styles.containerTopPhonePortrait}>
            <Image
              style={styles.logoLeftPhonePortrait}
              source={imagepaths.logo_transparent}
              />
            <Image
              style={[styles.opeImagePhonePortrait,{width: 800*(scale), height:200*(scale)}]}
              source={imagepaths.opet_background}
              />
          </View>
          <View style={styles.containerPhonePortraitBottom}>
            <KeyboardAvoidingView style={styles.formPhonePortrait}>
              <Text style={{fontFamily: 'Arial', fontSize: 18, color: colors.POPUP_LOGO_PINK,fontWeight: 'bold', textAlign: 'left',marginBottom: "2%"}}>{strings.SUBSCRIPTION_OFFER_TITLE}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'left', marginBottom: "4%"}}>{strings.PAYMENT_PITCH}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'left', marginBottom: "1%"}}>{strings.PAYMENT_INFO}</Text>
              <PopUpButton
                textStyle={{fontSize:16}}
                containerStyle={{width:"100%",alignSelf:'center'}}
                label={strings.JOIN_POP_UP_COMMUNITY}
                 onPress={() => this.onStartSubscription()}
                />
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.onLogout()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "1%"}}>
                <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.LOGOUT} </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.showServiceTerms()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "2%"}}>
                <Text style={{fontSize: 10, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                <Text style={{fontSize: 10, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
              </View>
            </TouchableOpacity>
           </View>
        </View>
      );
    }
    else if (height < 330) {
      return (
        <View style={styles.container}>
          <View style={styles.firstPartPhoneLanscape}>
           <Image
               style={styles.logoLeftPhoneLanscape}
                 source={imagepaths.logo_transparent}
               />
         </View>
           <View style={styles.secondPartPhoneLanscape}>
             <KeyboardAvoidingView
               style={styles.formPhoneLanscape} behavior="padding">
               <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.POPUP_LOGO_PINK,fontWeight: 'bold', textAlign: 'left',marginBottom: "1%",marginTop: "1%"}}>{strings.SUBSCRIPTION_OFFER_TITLE}</Text>
               <Text style={{fontFamily: 'Arial', fontSize: 12, color: colors.WHITE, textAlign: 'left', marginBottom: "4%"}}>{strings.PAYMENT_PITCH}</Text>
               <Text style={{fontFamily: 'Arial', fontSize: 12, color: colors.WHITE, textAlign: 'left', marginBottom: "1%"}}>{strings.PAYMENT_INFO}</Text>
                <PopUpButton
                  textStyle={{fontSize:12}}
                  containerStyle={{width: "100%", alignSelf:'center' }}
                  label={strings.JOIN_POP_UP_COMMUNITY}
                   onPress={() => this.props.onStartSubscription()}
                 />
              </KeyboardAvoidingView>
              <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "5%"}}  onPress={() => this.showServiceTerms()}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                  <Text style={{fontSize: 10, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                    <Text style={{fontSize: 10, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                </View>
              </TouchableOpacity>
           </View>
           <View style={styles.thirdPartPhoneLanscape}>
             <PopUpButton
               textStyle={{fontSize:12}}
                containerStyle={{width:100,alignSelf:'center'}}
                label={strings.LOGOUT}
                 onPress={this.onLogout}
               />
            </View>
        </View>
      );
    }
    else if (height < 365) {
      return (
        <View style={styles.container}>
          <View style={styles.firstPartPhoneLanscape}>
           <Image
               style={styles.logoLeftPhoneLanscape}
                 source={imagepaths.logo_transparent}
               />
         </View>
           <View style={styles.secondPartPhoneLanscape}>
             <KeyboardAvoidingView
               style={styles.formPhoneLanscape} behavior="padding">
               <Text style={{fontFamily: 'Arial', fontSize: 18, color: colors.POPUP_LOGO_PINK,fontWeight: 'bold', textAlign: 'left',marginBottom: "1%",marginTop: "1%"}}>{strings.SUBSCRIPTION_OFFER_TITLE}</Text>
               <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'left', marginBottom: "4%"}}>{strings.PAYMENT_PITCH}</Text>
               <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'left', marginBottom: "1%"}}>{strings.PAYMENT_INFO}</Text>
                <PopUpButton
                  textStyle={{fontSize:12}}
                  containerStyle={{width: "90%", alignSelf:'center' }}
                  label={strings.JOIN_POP_UP_COMMUNITY}
                   onPress={() => this.props.onStartSubscription()}
                 />
              </KeyboardAvoidingView>
              <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "5%"}}  onPress={() => this.showServiceTerms()}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                  <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                    <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                </View>
              </TouchableOpacity>
           </View>
           <View style={styles.thirdPartPhoneLanscape}>
             <PopUpButton
               textStyle={{fontSize:12}}
                containerStyle={{width:100,alignSelf:'center'}}
                label={strings.LOGOUT}
                 onPress={this.onLogout}
               />
            </View>
        </View>
      );
    }
    else if (height< 820 ) {
      return (
        <View style={styles.container}>
          <View style={styles.firstPart}>
         <Image
             style={styles.logoLeft}
               source={imagepaths.logo_transparent}
             />
           </View>
           <View style={styles.secondPart}>
             <Image
               style={styles.opeImageSmall}
               source={imagepaths.opet_background}
             />
           <KeyboardAvoidingView style={styles.form} behavior="padding">
             <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: "1%"}}>{strings.SUBSCRIPTION_OFFER_TITLE}</Text>
             <Text style={{fontFamily: 'Arial', fontSize: 20, color: colors.WHITE, textAlign:'left', marginBottom: "1%"}}>{strings.PAYMENT_PITCH} </Text>
              <Text style={{fontFamily: 'Arial', fontSize: 20, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: "1%"}}></Text>
             <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: "1%"}}>Tilaus</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 20, color: colors.WHITE, textAlign:'left', marginBottom: "1%"}}>{strings.PAYMENT_INFO}</Text>
              <Text style={{fontSize: 22, color: colors.BLACK,paddingBottom: 10, opacity: 0.7, fontWeight: 'bold', textAlign:'left'}}> {strings.PROCEED_TO_PAYMENT} </Text>
              <PopUpButton
                width="50%"
                label={strings.JOIN_POP_UP_COMMUNITY}
                onPress={this.onStartSubscription}
               />
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
              </View>
            </TouchableOpacity>
           </View>
         <View style={styles.thirdPart}>
           <PopUpButton
            textStyle={{fontSize: 16}}
             containerStyle={{width: 100 }}
              label={strings.LOGOUT}
               onPress={this.onLogout}
             />
         </View>
       </View>
      );
    } else {
      return (
        <View style={styles.container}>
          <View style={styles.firstPart}>
         <Image
             style={styles.logoLeft}
               source={imagepaths.logo_transparent}
             />
           </View>
           <View style={styles.secondPart}>
             <Image
               style={styles.opeImageLarge}
               source={imagepaths.opet_background}
             />
           <KeyboardAvoidingView style={styles.form} behavior="padding">
             <Text style={{fontFamily: 'Arial', fontSize: 28, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: "1%"}}>{strings.SUBSCRIPTION_OFFER_TITLE}</Text>
             <Text style={{fontFamily: 'Arial', fontSize: 22, color: colors.WHITE, textAlign:'left', marginBottom: "1%"}}>{strings.PAYMENT_PITCH}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 22, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: "1%"}}></Text>
             <Text style={{fontFamily: 'Arial', fontSize: 28, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: "1%"}}>Maksu</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 22, color: colors.WHITE, textAlign:'left', marginBottom: "1%"}}>{strings.PAYMENT_INFO}</Text>
              <Text style={{fontSize: 22, color: colors.BLACK,paddingBottom: 10, opacity: 0.7, fontWeight: 'bold', textAlign:'left'}}> {strings.JOIN_POP_UP_COMMUNITY} </Text>
              <PopUpButton
                width="50%"
                label={strings.JOIN_POP_UP_COMMUNITY}
                onPress={this.onStartSubscription}
               />
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
              </View>
            </TouchableOpacity>
           </View>
         <View style={styles.thirdPart}>
           <PopUpButton
             containerStyle={{width: 150 }}
              label={strings.LOGOUT}
               onPress={this.onLogout}
             />
         </View>
       </View>
      );
    }
  }
}


const styles = StyleSheet.create ({
    containerPhonePortrait: {
      flex: 1,
      flexDirection: 'column',
    },
    containerTopPhonePortrait: {
      backgroundColor: colors.BLACK,
      width: "100%",
      height: "15%",
      flexDirection: 'row',
    },
    logoLeftPhonePortrait: {
      marginTop: "3%",
      marginLeft: "3%",
      width: 50,
      height: 50,
    },
    containerPhonePortraitBottom: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "100%",
      height: "85%",
      paddingBottom: "5%"
    },
    opeImagePhonePortrait: {
    alignSelf: 'center',
    marginTop: "0%",
    marginLeft: "3%",
    // marginBottom: "3%",
    transform: [
      { rotateX: '25deg'},
    ],
    opacity: 0.7
    },
    formPhonePortrait: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "85%",
      backgroundColor: colors.BLACK
    },
    firstPartPhoneLanscape: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "15%",
    },
    secondPartPhoneLanscape: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      alignItems: 'center',
      width: "70%",
    },
    thirdPartPhoneLanscape: {
      backgroundColor: colors.BLACK,
      justifyContent:'flex-start',
      alignItems: 'center',
      paddingTop: "3%",
      width: "15%",
    },
    formPhoneLanscape: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      backgroundColor: colors.BLACK
    },
   logoLeftPhoneLanscape: {
     position: 'absolute',
     top:"5%",
     left: "25%",
     width: 60,
     height: 60,
    },
    opeImageSmallPhoneLanscape: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      marginTop: "3%",
      // width: "80%",
      // height: 216,
      transform: [
      //   { perspective: 850 },
      //   { translateX: - Dimensions.get('window').width * 0.24 },
        { rotateX: '25deg'},
        // { rotateZ: '1deg'}
      ],
      opacity: 0.5
   },
    container: {
      flex: 1,
      flexDirection: 'row',
    },
    firstPart: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "15%",
    },
    secondPart: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      alignItems: 'center',
      width: "70%",
    },
    thirdPart: {
      backgroundColor: colors.BLACK,
      justifyContent:'flex-start',
      alignItems: 'center',
      paddingTop: 30,
      width: "15%",
    },
    form: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      backgroundColor: colors.BLACK
    },
   logoLeft: {
     position: 'absolute',
     top:20,
     left: "15%",
     width: 120,
     height: 120,
    },
    opeImageSmall: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: "5%",
      width: "80%",
      height: 216,
      opacity: 0.9,
      transform: [
      //   { perspective: 850 },
      //   { translateX: - Dimensions.get('window').width * 0.24 },
        { rotateX: '25deg'},
        // { rotateZ: '1deg'}
      ],
      opacity: 0.7
   },
   opeImageLarge: {
     alignItems: 'center',
     justifyContent: 'center',
     marginTop: "10%",
     width: "80%",
     height: 216*1.3,
     opacity: 0.9,
     transform: [
     //   { perspective: 850 },
     //   { translateX: - Dimensions.get('window').width * 0.24 },
       { rotateX: '25deg'},
       // { rotateZ: '1deg'}
     ],
     opacity: 0.7
  },
});


const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateEmail, updatePassword, signup, getUser, logout }, dispatch)
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionOfferScreen)
