import React from 'react';
import {ImageBackground, TouchableOpacity, ScrollView, Button, Image, StyleSheet, Text, View} from 'react-native';
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import * as RootNavigation from '../controllers/RootNavigation';
import metrics, { logowidth } from "../config/metrics"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as Analytics from 'expo-firebase-analytics';

interface Props {
  uid:string;
  url : string;
  title : string;
  duration: string;
  teacher: string;
  teacherImage: string;
  thumbnail_ipad:string;
  class_type: string;
  is_favourite: boolean;
}

export default class VideoItem extends React.Component<Props>  {

  state: {
    new_align_right: false,
    show_duration: true,
    is_new: false,
  }


    constructor(props) {
      super(props)
      this.state = {
        new_align_right: false,
        show_duration: true,
        is_new: false,
      }
      function setState(){
        this.setState();
      }

      var addedDate = this.props.added.toDate();
      var sevenDaysAgo = new Date(new Date().setDate(new Date().getDate()-5))
      if ( sevenDaysAgo < addedDate ) {
        this.state.is_new = true;
      }
    }

  onPlayvideo = (url) => {
//      console.log("VideoItem.onPlayvideo: " + url.toString());
      RootNavigation.navigate('PlayWebVideo', {videoUrl: url});
  }

  onShowVideoDetails = () => {
//    console.log("onShowVideoDetails");

    Analytics.logEvent("view_video_details_v1", {
        id: this.props.uid,
        title: this.props.title,
        class_type: this.props.class_type,
        teacher: this.props.teacher,
        duration: this.props.duration,
        is_favourite: this.props.is_favourite ? 'favourite' : 'not_favourite'
      })

    var item = {
      uid : this.props.uid,
      url : this.props.url,
      title : this.props.title,
      duration : this.props.duration,
      teacher : this.props.teacher,
      teacherImage : this.props.teacherImage,
      thumbnail_ipad: this.props.thumbnail_ipad,
      class_type : this.props.class_type,
      long_description: this.props.long_description,
      short_description: this.props.short_description,
      is_favourite: this.props.is_favourite,
      added: this.props.added.toDate().toLocaleDateString("fi-FI")
    }
    RootNavigation.navigate('ShowVideoDetails', {videoItem: item});
  }

  hasThumbnailImage(){
//    console.log("hasThumbnailImage: " + this.props.thumbnail_ipad);
    if ( this.props.thumbnail_ipad==null ||this.props.thumbnail_ipad==="") {
      // console.log("false");
//      return false;
    }
    else {
//      console.log("true");
      return true;
    }
  }

  render() {
    const {
      uid,
      url,
      title,
      duration,
      teacher,
      teacherImage,
      thumbnail_ipad,
      class_type,
      is_favourite,
      // genre,
      short_description,
      long_description,
      // rating,
      // onPlayvideo
     } = this.props;

     const is_new = this.state.is_new;


     if ( metrics.DEVICE_WIDTH<361) {
       return (
         <TouchableOpacity style={styles.videoItemContainerSmallPhone} onPress={this.onShowVideoDetails.bind(this)}>
           { !this.hasThumbnailImage() && (
             <Image
               style={styles.videoScreenshot}
               source={teacherImage}
             />
           )}
           { this.hasThumbnailImage() && (
             <Image
               style={styles.videoScreenshot}
               source={this.props.thumbnail_ipad}
             />
           )}
             <View style={styles.textContainer}>
                <View style={styles.circleContainer}>
                  {is_new && (<View style={styles.NewVideoIndicator}/>)}
                </View >
                <View style={styles.titleContainer}>
                   <Text style={styles.videoTitleTextSmallPhone}> {title} - {duration}min</Text>
                 </View>
                <View style={styles.circleContainer}/>
              </View>
         </TouchableOpacity>
       )

     }else if (metrics.DEVICE_WIDTH>361 && metrics.DEVICE_WIDTH<1450) {
       return (
         <TouchableOpacity style={styles.videoItemContainerLargePhone} onPress={this.onShowVideoDetails.bind(this)}>
           { !this.hasThumbnailImage() && (
             <Image
               style={styles.videoScreenshot}
               source={teacherImage}
             />
           )}
           { this.hasThumbnailImage() && (
             <Image
               style={styles.videoScreenshot}
               source={this.props.thumbnail_ipad}
             />
           )}
            <View style={styles.textContainer}>
                <View style={styles.circleContainer}>
                  {is_new && (<View style={styles.NewVideoIndicator}/>)}
                </View >
                <View style={styles.titleContainer}>
                  <Text style={styles.videoTitleTextLargePhone}> {short_description} - {duration}min</Text>
                 </View>
                <View style={styles.circleContainer}/>
              </View>
         </TouchableOpacity>
       )
     } else if (metrics.DEVICE_WIDTH>1450) {
       //iPad landscape
       return (
         <TouchableOpacity style={styles.videoItemContainerTablet} onPress={this.onShowVideoDetails.bind(this)}>
           { !this.hasThumbnailImage() && (
             <Image
               style={styles.videoScreenshot}
               source={teacherImage}
             />
           )}
           { this.hasThumbnailImage() && (
             <Image
               style={styles.videoScreenshot}
               source={this.props.thumbnail_ipad}
             />
           )}
             <View style={styles.textContainer}>
                <View style={styles.circleContainer}>
                  {is_new && (<View style={styles.NewVideoIndicator}/>)}
                </View >
                <View style={styles.titleContainer}>
                  <Text style={styles.videoTitleTextTabletLandscape}> {short_description} - {duration}min</Text>
                 </View>
                <View style={styles.circleContainer}/>
              </View>
         </TouchableOpacity>
       )
     }
   }
}

const styles = StyleSheet.create({
  videoItemContainerSmallPhone: {
    flex: 1,
    width: metrics.DEVICE_WIDTH/2.1,
    height: metrics.DEVICE_HEIGHT/2.9,
    backgroundColor: colors.WHITE,
    alignItems: 'center',
  },
  videoItemContainerLargePhone: {
    flex: 1,
    width: metrics.DEVICE_WIDTH/3.1,
    height: metrics.DEVICE_HEIGHT/3.5,
    backgroundColor: colors.WHITE,
    alignItems: 'center',
  },
  videoItemContainerTablet: {
    flex: 1,
    width: metrics.DEVICE_WIDTH/4.1,
    height: metrics.DEVICE_HEIGHT/3.5,
    backgroundColor: colors.WHITE,
    alignItems: 'center',
  },
  videoScreenshot: {
    alignSelf: 'center',
    width: "95%",
    height: "80%",
 },
 textContainer: {
   flex:1,
   flexDirection: 'row',
   width: "100%",
   height: "15%",
   justifyContent:'center',
   alignItems: 'flex-start',
   backgroundColor: colors.WHITE
 },
 circleContainer: {
   flex:1,
   justifyContent:'flex-start',
   alignItems: 'center',
   backgroundColor: colors.WHITE
 },
 NewVideoIndicator: {
    marginTop: 3,
    alignSelf: 'center',
    width: 8,
    height: 8,
    borderRadius: 100/2,
    backgroundColor: colors.DODGER_BLUE,
},
 titleContainer: {
   width: "85%",
   justifyContent: 'center',
   alignItems: 'center',
   backgroundColor: colors.WHITE,
 },
 videoTitleTextSmallPhone: {
   flex:1,
   width: "90%",
   color: colors.POPUP_LOGO_PURPLE,
   fontSize: 14,
   fontWeight: 'bold',
   textAlign: 'center',
 },
 videoTitleTextLargePhone: {
   flex:1,
   width: "90%",
   color: colors.POPUP_LOGO_PURPLE,
   fontSize: 13,
   fontWeight: 'bold',
   textAlign: 'center',
 },
 videoTitleTextTabletLandscape: {
   flex:1,
   width: "90%",
   color: colors.POPUP_LOGO_PURPLE,
   fontSize: 16,
   marginTop: 5,
   fontWeight: 'bold',
   textAlign: 'center',
 },
})
