import React from 'react';
import {KeyboardAvoidingView, Button, Image, StyleSheet, Text, View} from 'react-native';
import PopUpButton from '../components/PopUpButton'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import PopUpTextInput from '../components/PopUpTextInput';
import * as RootNavigation from '../controllers/RootNavigation';
import Firebase from '../config/Firebase'
import metrics, { logowidth } from "../config/metrics"
import {connect} from 'react-redux'
import * as Analytics from 'expo-firebase-analytics';


class ForgotPasswordScreen extends React.Component  {

  state = {
    email: "",
    confirmed_email: "",
  };

  componentDidMount = () => {
    // console.log("ForgotPasswordScreen.componentDidMount");
    // console.log("Firebase auth current user " + Firebase.auth().currentUser);
  }


  updateEmail = email => {
    this.setState({email});
    // console.log("updateEmail email: " + this.state.email);
  }

  updateConfirmedEmail = confirmed_email => {
    this.setState({confirmed_email});
    // console.log("updateConfirmedEmail email: " + this.state.confirmed_email);
  }


  onForgotPassword() {
    // console.log("onForgotPassword");
    var emailToReset = "";
    if ( Firebase.auth().currentUser) {
      emailToReset = Firebase.auth().currentUser.email;
    } else {
      emailToReset = this.state.email;
    }
    // console.log("Email to reset is " + emailToReset);
    Firebase.auth().sendPasswordResetEmail(emailToReset).then(function() {
      Analytics.logEvent('reset_password_link_sent_v1', {
        logged_in: Firebase.auth().currentUser ? true : false
      })
      alert(strings.RESET_PASSWORD_EMAIL_SENT);
      RootNavigation.pop();
    }).catch(function(error) {
      // console.log("Error: " + error.code + " " + error.message);
        Analytics.logEvent('error_v1', {
         action: 'send_password_reset_link',
         reason: error.code,
         message: ''
       })
      switch (error.code) {
        case 'auth/user-not-found':
          alert(strings.USER_NOT_FOUND)
          break;
        case 'auth/wrong-password':
          alert(strings.WRONG_PASSWORD);
          break;
        case 'auth/argument-error':
          alert(strings.SIGNUP_ARGUMENT_ERROR);
          break;
        case 'auth/invalid-email':
          alert(strings.INVALID_EMAIL);
          break;
        default:
        alert(strings.RESET_PASSWORD_REQUEST_FAILED)
      }
    });
  }

  onBack() {
  // console.log("onBack");
  RootNavigation.navigate('Login')
}


  render() {
    return (
      <KeyboardAvoidingView style={styles.container} behavior="padding">
        <View style={styles.form}>
          <Image
            style={styles.image}
              source={imagepaths.logo}
            />
          {(Firebase.auth().currentUser== null )&& (
            <PopUpTextInput
              style={{color: 'black'}}
              value={this.state.email}
              onChangeText={email => this.updateEmail(email)}
              placeholder={strings.EMAIL_PLACEHOLDER}
            />
          )}
          {(Firebase.auth().currentUser!= null )&& (
            <Text style={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 18,
              color: colors.BLACK,
              padding:10,
            }}>{Firebase.auth().currentUser.email} </Text>
          )}


            <PopUpButton
              label={strings.RESET_PASSWORD}
               onPress={() => this.onForgotPassword()}
             />
           {(Firebase.auth().currentUser== null )&& (<Text style={{fontSize: 14, color: colors.BLACK, padding:10, textAlign: 'center', alignSelf: "center"}}>{strings.REQUEST_TO_RESET_PAWSSWORD_DESCRIPTION}</Text>)}

           </View>
       </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create ({
    container : {
      flex: 1,
      backgroundColor: colors.WHITE,
      alignItems: 'center',
      justifyContent: "space-between",
    },
    image: {
      marginTop: "10%",
      marginBottom: 10,
      alignSelf: "center",
      width: logowidth(),
      height: logowidth(),
    },
   text: {
     flex: 1,
     alignSelf: "center",
     textAlign: 'center',
   },
  form: {
    flex: 1,
    backgroundColor: colors.WHITE,
    justifyContent: "center",
    width: "80%"
  },
});

//TODO: Remove as using Firebase.auth.currentuser
const mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(ForgotPasswordScreen)
