import React from 'react';
import {ActivityIndicator,ScrollView, Button, Image, BackHandler, StyleSheet, Text, View, Alert,TouchableOpacity} from 'react-native';
import HomeHeader from '../components/HomeHeader';
import VideoItemRow from '../components/VideoItemRow';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setLoading, SET_LOADING} from '../actions/ContentLoader'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import { MapTeacherPath } from '../config/imagepaths'
import * as RootNavigation from '../controllers/RootNavigation';
import Firebase, { db } from '../config/Firebase'
import metrics, { logowidth } from "../config/metrics"
import { Notifications } from 'expo';
import PopUpButton from '../components/PopUpButton'
import { LinearGradient } from 'expo-linear-gradient';

const videoItems = [
];

class HomeScreen extends React.Component  {

  state = {
    videos: [],
    favourite_videos: [],
    init_ongoing: true,
    teacher_filter: "",
    duration_filter: "",
    class_type_filter: "",
    yoga_sub_type_filter: "",
    searchActive: false,
  };

  constructor() {
    // console.log("HomeScreen.Construcor");
    super();
  }

  backAction = () => {
    // console.log("Back HW Button pressed");
    var currentRoute = RootNavigation.getCurrentRouteName();
    // console.log("current route is "+ currentRoute);
    switch (currentRoute) {
      case "ShowHomeScreen":
      BackHandler.exitApp();
        break;
      case "Login":
      case "ShowVideoDetails":
      case "ShowHomeScreen":
      case "SignUp":
      case "PlayWebVideo":
      case "ShowProfile":
      case "ShowServiceTerms":
      case "ShowForgotPasswordScreen":
        RootNavigation.pop();
      break;
      default:
      // console.log("Unhandled route, request to exit");
      Alert.alert(strings.HEY, strings.CONFIRM_LOGOUT, [
        {
          text: "Cancel",
          onPress: () => null,
          style: "cancel"
        },
        { text: "YES", onPress: () => {
          // console.log("handleSignout for user " + Firebase.auth().currentUser.uid);
          Firebase.auth().signOut()
          RootNavigation.navigate('Login')
        }}
      ]);
    }
    return true;
  };


  componentWillUnmount() {
    // console.log("componentWillUnmount");
    this.backHandler.remove();
    this.detachSubscribers();
  }

  detachSubscribers = () => {
    // console.log("HomeScreen.detachSubscribers");
    if (this.state.unSubscribeFavouriteUpdates != null) {
      // console.log("Unsubscribe from favourite updates");
      this.state.unSubscribeFavouriteUpdates();
    }
    if (this.state.unSubscribedVideoUpdates != null) {
      // console.log("Unsubscrive from video store updates");
      this.state.unSubscribedVideoUpdates() ;
    }
  }

  onVideoListChanged = (snapshot) => {
    // console.log("onVideoListChanged");
    // console.log(this.state.videos.length);
    if (this.state ==null  || this.state.videos ==null ) {
      // console.log("initialisation ongoing, skip");
      return;
    }

    const current_videolist = [];
    this.state.videos.forEach((video, i) => {
        current_videolist.push(video);
    });
    const new_videos = [];
    const removed_videos = [];
    const updated_videos = [];

    //TODO: Should handle if current video list is empty, just add videos

    snapshot.docChanges().forEach(function(change) {
      var found = false;
        const changed_video = {
          uid: change.doc.id,
          title: change.doc.data().title,
          difficulty_rating: change.doc.data().difficulty_rating,
          long_description: change.doc.data().long_description,
          short_description: change.doc.data().short_description,
          class_type: change.doc.data().class_type,
          teacher: change.doc.data().teacher,
          tags: change.doc.data().tags,
          teacherImage: MapTeacherPath(change.doc.data().teacher),
          thumbnail_ipad: change.doc.data().thumb_ipad,
          url: change.doc.data().vimeoid,
          duration: change.doc.data().durationmin,
          added: change.doc.data().added,
          is_favourite: false
        }
        current_videolist.forEach((video, i) => {
          if ( video.uid == changed_video.uid) {
            if ( change.type == "removed") {
              current_videolist.splice(i,1);
            } else if (change.type == "modified") {
              current_videolist[i] = changed_video;
            } else if (change.type == "added") {
              found = true;
            }
          }
        });
        if (!found && change.type =="added") {
          current_videolist.push(changed_video);
        }
      });
      //Set videos to cronological order
      current_videolist.sort((a, b) => b.added.seconds - a.added.seconds)
      this.setState({videos:current_videolist});
  }

  onFavouritesListChanged = (doc) => {
    // console.log("onFavouritesListChanged for user :" + JSON.stringify(Firebase.auth().currentUser));
    // console.log(this.state.videos.length);
    if (this.state.videos ==null || this.state.videos.length ==0) {
      // console.log("initialisation ongoing, skip");
      return;
    }
    let favourite_videos;
    const new_videoList = [];
    db.collection('favourite_videos')
    .doc(Firebase.auth().currentUser.uid)
    .get()
    .then( (doc) => {
      if (doc.exists) {
        // console.log("User " + Firebase.auth().currentUser.uid + " has favourites");
        favourite_videos = [...doc.data().video_uids];
        this.state.videos.forEach((video, i) => {
            video.is_favourite = favourite_videos.includes(video.uid)
            new_videoList.push(video);
        });
        this.setState({new_videoList})
      }
      else {
        // console.log("No such document!");
      }
    })
    .catch( error => {
      // console.log("Error: " + error);
    });
  }

  componentDidMount = () => {
//    console.log("HomeScreen.componentDidMount()");
    this.backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      this.backAction
    );

    // //TODO: Workaround to avoid crashes,
    // // should move unsubsrtiption functions to redux state and have proper logout function
    // Firebase.auth().onAuthStateChanged( user => {
    //   if (user) {
    //     console.log("Check if there's existing subscribers, then detach: " + user.email);
    //     //TODO: Remove previous subscriptions
    //     this.detachSubscribers();
    //   } else {
    //     // console.log("No users");
    //     // //TODO: This will cause resetting navi stack, i.e. sign out -> no user -> reset navi stack -> unmount component -> detach subscribers
    //   }
    // })

    //Get user favourite videos first and add to videolist
    // console.log("Favourites for user :" + Firebase.auth().currentUser.uid);
    let favourite_videos;
    db.collection('favourite_videos')
    .doc(Firebase.auth().currentUser.uid)
    .get()
    .then( (doc) => {
      if (doc.exists) {
        // console.log("doc.id: " + doc.id);
        // console.log("video_uids: " + doc.data().video_uids.toString());
        favourite_videos = [...doc.data().video_uids];
        }
      else {
        // console.log("User doesn't have favourites!");
      }
    })
    .catch( error => {
      // console.log("Error: " + error);
    });

    //Get current video list from db
    db.collection('videos')
    .get()
    .then( (snapshot) => {
      const videos = [];
      snapshot.forEach( (doc) => {
        videos.push( {
          uid: doc.id,
          title: doc.data().title,
          difficulty_rating: doc.data().difficulty_rating,
          long_description: doc.data().long_description,
          short_description: doc.data().short_description,
          class_type: doc.data().class_type,
          teacher: doc.data().teacher,
          tags: doc.data().tags,
          teacherImage: MapTeacherPath(doc.data().teacher),
          thumbnail_ipad: doc.data().thumb_ipad,
          url: doc.data().vimeoid,
          duration: doc.data().durationmin,
          added: doc.data().added,
          is_favourite: favourite_videos==null? false : favourite_videos.includes(doc.id)
        });
        // console.log(doc.data().title + " " + doc.id);
      })
      //Set videos to cronological order
      videos.sort((a, b) => b.added.seconds - a.added.seconds)
      //Subscribe for video list changes
      const unSubscribedVideoUpdates = this.unsubscribe = db.collection("videos")
        .onSnapshot({includeMetadataChanges: false}, this.onVideoListChanged).bind(this);
      //Subscrive for favourite list changes
      const unSubscribeFavouriteUpdates = this.unsubscribe = db.collection("favourite_videos")
        .doc(Firebase.auth().currentUser.uid)
        .onSnapshot({includeMetadataChanges: false},this.onFavouritesListChanged).bind(this);
      this.setState({ videos: videos, favourite_videos:favourite_videos, unSubscribedVideoUpdates: unSubscribedVideoUpdates, unSubscribeFavouriteUpdates:unSubscribeFavouriteUpdates });
      this.props.setLoading(false);
    })
    .catch( error => {
      // console.log("Error: " + error);
    });
  }

  hasFavourites() {
    // console.log("hasFavourites");
    var favourites = this.state.videos.filter(obj => {
      return obj.is_favourite == true})
    // console.log("favourites.length: " + favourites.length);
    return favourites.length > 0 ? true : false
  }


  hasSearchFilters() {
    let teacher_filter = this.state.teacher_filter;
    let duration_filter = this.state.duration_filter;
    let class_type_filter = this.state.class_type_filter;
    let yoga_sub_type_filter = this.state.yoga_sub_type_filter;
    if ( teacher_filter !== "" || duration_filter !== "" || class_type_filter !== "" || yoga_sub_type_filter!== "") {
      // console.log("search filters: teacher - " + teacher_filter + " duration - " + duration_filter + " class_type - " + class_type_filter + " yoga_sub_type - " + yoga_sub_type_filter );
      return true;
    } else
    {
      // console.log("no filters");
      return false;
    }
  }

  getSearchFilterText() {
    // console.log("getSearchFilterText");
    let teacher_filter = this.state.teacher_filter;
    let teacher_filter_text = teacher_filter===""? "" : " " + teacher_filter + " "
    let duration_filter = this.state.duration_filter;
    let duration_filter_text = duration_filter===""? "" : " alle " + duration_filter + "-min"
    let class_type_filter = this.state.class_type_filter;
    let class_type_filter_text = class_type_filter===""? "" : " " + class_type_filter + " "
    let yoga_sub_type_filter = this.state.yoga_sub_type_filter;
    let yoga_sub_type_filter_text = yoga_sub_type_filter===""? "" : " " + yoga_sub_type_filter + ""
    return teacher_filter_text+duration_filter_text+class_type_filter_text+yoga_sub_type_filter
    // return " " + this.state.teacher_filter + "n alle " + this.state.duration_filter + " minuutin " + this.state.class_type_filter
  }

  onTeacherFilterChanged(new_filter) {
    // console.log("onTeacherFilterChanged - " + new_filter);
    if ( new_filter === this.state.teacher_filter) {
      // console.log("clearing teacher filter");
      this.setState({teacher_filter:""});
    } else {
      // console.log("filter changed from " + this.state.teacher_filter + " to " + new_filter);
      this.setState({teacher_filter:new_filter});
    }
  }

  onClassTypeFilterChanged(new_filter) {
    // console.log("onClassTypeFilterChanged - " + new_filter);
    if ( new_filter === this.state.class_type_filter ) {
      // console.log("clearing class and subtype filters");
      this.setState({yoga_sub_type_filter:"",class_type_filter:""});
    }
    if ( new_filter != this.state.class_type_filter) {
      // console.log("filter changed from " + this.state.class_type_filter + " to " + new_filter);
      if ( new_filter === "jooga") {
        this.setState({class_type_filter:new_filter});
      } else {
        this.setState({yoga_sub_type_filter:"",class_type_filter:new_filter});
      }
    }
  }

  onYogaSubTypeFilterChanged(new_filter) {
    // console.log("onYogaSubTypeFilterChanged - " + new_filter);
    if ( new_filter === this.state.yoga_sub_type_filter ) {
      // console.log("clearing subtype filters");
      this.setState({yoga_sub_type_filter:""});
    }
    if ( new_filter != this.state.yoga_sub_type_filter) {
      // console.log("filter changed from " + this.state.yoga_sub_type_filter + " to " + new_filter);
      this.setState({yoga_sub_type_filter:new_filter});
    }
  }

  onSearchToggled(searchActive) {
    // console.log("onSearchToggled");
    this.setState({searchActive:!searchActive})
  }

  getFilteredVideos() {
    // console.log("getFilteredVideos");
    let filtered_videos=this.state.videos;
    let teacher_filter = this.state.teacher_filter===""? false : true
    let duration_filter = this.state.duration_filter===""? false : true;
    let class_type_filter = this.state.class_type_filter===""? false : true;
    let yoga_sub_type_filter = this.state.yoga_sub_type_filter===""? false : true;
    let afilte=[]

    if ( teacher_filter) {
      // console.log("teacher_filter");
      filtered_videos = this.state.videos.filter(obj => {return obj.teacher == this.state.teacher_filter})
    }
    if (class_type_filter) {
      // console.log("class_type_filter");
      if ( filtered_videos.length>1) {
        filtered_videos = filtered_videos.filter(obj => {return obj.class_type == this.state.class_type_filter})
      }
    }
    if ( yoga_sub_type_filter ) {
      // console.log("yoga_sub_type_filter");
      if ( filtered_videos.length>1) {
        filtered_videos.forEach((video, i) => {
          let videotags = video.tags;
          var result = videotags.findIndex(item => this.state.yoga_sub_type_filter.toLowerCase() === item.toLowerCase());
          if(result>-1) {
            afilte.push(filtered_videos[i])
          }
        });
        filtered_videos = afilte
      }
    }
    if (duration_filter) {

    }
    return filtered_videos;
  }

  render() {
    // console.log(this.props);

    const loading_content = this.props.loading;
    if (loading_content) {
      // console.log("Rendering HomeScreen with loading_content=true");
      return (
        <View style={styles.loaderContainer}>
          <ActivityIndicator size='large' color={colors.POPUP_LOGO_PURPLE} />
        </View>
      );
    } else {
      return (
        <ScrollView style={styles.container} scrollviewContent={styles.scrollviewContent}>

           <HomeHeader
             id  = { (this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].uid : ""}
             class_type = { (this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].class_type : ""}
             is_favourite = { (this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].is_favourite : false}
             url = { (this.state.videos != null && this.state.videos.length > 0)  ? this.state.videos[0].url : ""}
             title = { (this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].title : ""}
             duration= { (this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].duration : ""}
             teacher={(this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].teacher : ""}
             teacherImage={(this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].teacherImage : ""}
             thumbnail_ipad={(this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].thumbnail_ipad : ""}
             long_description={(this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].long_description : ""}
             short_description={(this.state.videos != null && this.state.videos.length > 0) ? this.state.videos[0].short_description : ""}
             />
            <View style={styles.headerContainer}>
             <View style={styles.headerTopRowContainer}>
               <View style={styles.headerLeft}>
                 <TouchableOpacity onPress={() => {RootNavigation.navigate('ShowProfile')}}>
                   <Image
                     style={styles.logoImage}
                     source={imagepaths.logo_transparent}
                   />
                 </TouchableOpacity>
               </View>
               <View style={styles.headerRight}>
                 <TouchableOpacity style={styles.searchButtonContainer} onPress={() => {this.onClassTypeFilterChanged("");this.onTeacherFilterChanged("");this.onSearchToggled(this.state.searchActive)}}>
                   <Text style={styles.filterText}>Haku</Text>
                 </TouchableOpacity>

                 {this.state.searchActive && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}>|</Text>
                 </View> )}
                 {this.state.searchActive && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onTeacherFilterChanged("Maria")}>
                   {this.state.teacher_filter==="Maria" && (<Text style={styles.filterTextActive}>Maria</Text>)}
                   {this.state.teacher_filter!="Maria" && (<Text style={styles.filterText}>Maria</Text>)}
                   </TouchableOpacity> )}
                 {this.state.searchActive && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onTeacherFilterChanged("Jenni")}>
                 {this.state.teacher_filter==="Jenni" && (<Text style={styles.filterTextActive}>Jenni</Text>)}
                 {this.state.teacher_filter!="Jenni" && (<Text style={styles.filterText}>Jenni</Text>)}
                 </TouchableOpacity> )}
                 {this.state.searchActive && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onTeacherFilterChanged("Katja")}>
                 {this.state.teacher_filter==="Katja" && (<Text style={styles.filterTextActive}>Katja</Text>)}
                 {this.state.teacher_filter!="Katja" && (<Text style={styles.filterText}>Katja</Text>)}
                 </TouchableOpacity> )}
                 {this.state.searchActive && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onTeacherFilterChanged("Anu")}>
                 {this.state.teacher_filter==="Anu" && (<Text style={styles.filterTextActive}>Anu</Text>)}
                 {this.state.teacher_filter!="Anu" && (<Text style={styles.filterText}>Anu</Text>)}
                 </TouchableOpacity> )}
                 {this.state.searchActive && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}>|</Text>
                 </View> )}
                 {this.state.searchActive && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onClassTypeFilterChanged("jooga")}>
                 {this.state.class_type_filter==="jooga" && (<Text style={styles.filterTextActive}>Jooga</Text>)}
                 {this.state.class_type_filter!="jooga" && (<Text style={styles.filterText}>Jooga</Text>)}
                 </TouchableOpacity> )}
                 {this.state.searchActive && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onClassTypeFilterChanged("pilates")}>
                 {this.state.class_type_filter==="pilates" && (<Text style={styles.filterTextActive}>Pilates</Text>)}
                 {this.state.class_type_filter!="pilates" && (<Text style={styles.filterText}>Pilates</Text>)}
                 </TouchableOpacity> )}
                   {this.state.searchActive && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onClassTypeFilterChanged("meditaatio")}>
                   {this.state.class_type_filter==="meditaatio" && (<Text style={styles.filterTextActive}>Meditaatio</Text>)}
                   {this.state.class_type_filter!="meditaatio" && (<Text style={styles.filterText}>Meditaatio</Text>)}
                 </TouchableOpacity> )}
                 {this.state.searchActive && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}>|</Text>
                 </View> )}
               </View>
             </View>
             <View style={styles.headerTopRowContainer}>
               <View style={styles.headerLeft}>
               </View>
               <View style={styles.headerRight}>
                 <View style={styles.searchButtonContainer}>
                   <Text style={styles.filterText}>    </Text>
                 </View>
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onYogaSubTypeFilterChanged("yin")}>
                 {this.state.yoga_sub_type_filter==="yin" && (<Text style={styles.filterTextActive}>Yin</Text>)}
                 {this.state.yoga_sub_type_filter!="yin" && (<Text style={styles.filterText}>Yin</Text>)}
                  </TouchableOpacity> )}
               </View>
             </View>
             <View style={styles.headerTopRowContainer}>
               <View style={styles.headerLeft}>
               </View>
               <View style={styles.headerRight}>
                 <View style={styles.searchButtonContainer}>
                   <Text style={styles.filterText}>    </Text>
                 </View>
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                   {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onYogaSubTypeFilterChanged("flow")}>
                   {this.state.yoga_sub_type_filter==="flow" && (<Text style={styles.filterTextActive}>Flow</Text>)}
                   {this.state.yoga_sub_type_filter!="flow" && (<Text style={styles.filterText}>Flow</Text>)}
                   </TouchableOpacity> )}
               </View>
             </View>
             <View style={styles.headerTopRowContainer}>
               <View style={styles.headerLeft}>
               </View>
               <View style={styles.headerRight}>
                 <View style={styles.searchButtonContainer}>
                   <Text style={styles.filterText}>    </Text>
                 </View>
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                 {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <View style={styles.filterButtonContainer}  onPress="">
                   <Text style={styles.filterText}></Text>
                 </View> )}
                   {this.state.searchActive && this.state.class_type_filter==="jooga" && ( <TouchableOpacity style={styles.filterButtonContainer}  onPress={() => this.onYogaSubTypeFilterChanged("opetus")}>
                   {this.state.yoga_sub_type_filter==="opetus" && (<Text style={styles.filterTextActive}>Tekniikka</Text>)}
                   {this.state.yoga_sub_type_filter!="opetus" && (<Text style={styles.filterText}>Tekniikka</Text>)}
                   </TouchableOpacity> )}
               </View>
             </View>
           </View>


          {this.hasSearchFilters() && ( <View style={styles.sectionHeader}><Text style={styles.sectionHeaderText}> {strings.VIDEO_SEARCH_FILTER_TITLE} - {this.getSearchFilterText()}</Text></View>)}
          {(this.hasSearchFilters() && this.getFilteredVideos().length >0) && ( <VideoItemRow videoItems = {this.getFilteredVideos()}/>)}

          {this.hasFavourites() && ( <View style={styles.sectionHeader}><Text style={styles.sectionHeaderText}> Favourites</Text></View>)}
          {this.hasFavourites() && ( <VideoItemRow videoItems = {this.state.videos.filter(obj => {return obj.is_favourite == true})}/>)}

           <View style={styles.sectionHeader}>
             <Text style={styles.sectionHeaderText}> Jooga</Text>
           </View>
           <VideoItemRow
             videoItems = {this.state.videos.filter(obj => {
               return obj.class_type === 'jooga'})}
             />

           <View style={styles.sectionHeader}>
             <Text style={styles.sectionHeaderText}> Pilates</Text>
           </View>
           <VideoItemRow
             videoItems = {this.state.videos.filter(obj => {
               return obj.class_type === 'pilates'})}
             />

           <View style={styles.sectionHeader}>
             <Text style={styles.sectionHeaderText}> Meditaatio</Text>
           </View>
           <VideoItemRow
             videoItems = {this.state.videos.filter(obj => {
               return obj.class_type === 'meditaatio'})}
             />

             <View style={styles.sectionHeader}>
               <Text style={styles.sectionHeaderText}> Kehonpainoharjoittelu</Text>
             </View>
             <VideoItemRow
               videoItems = {this.state.videos.filter(obj => {
                 return obj.class_type === 'kehonpaino'})}
               />
          </ScrollView>
      )
    }
 }
}

const styles = StyleSheet.create({
 loaderContainer: {
   flex:1,
   justifyContent: 'center'
 },
 container: {
   flex: 1,
   backgroundColor: colors.BLACK,
 },
 headerContainer: {
   position: 'absolute',
   top: 0,
   left: 0,
   flexDirection: 'column',
   width: "100%"
 },
 headerTopRowContainer: {
   width: "100%",
   flexDirection: "row",
   // backgroundColor: colors.POPUP_CYAN_D,
 },
 headerLeft: {
   flexDirection: "row",
   width:"15%",
 },
 headerRight: {
   flexDirection: "row-reverse",
   width:"85%",
 },
 filterButtonContainer: {
   width: "7%",
   justifyContent: "center",
//   backgroundColor: colors.BLACK,
   borderRadius: 4,
 },
 searchButtonContainer: {
   width: "7%",
   justifyContent: "center",
   backgroundColor: colors.BLACK,
   borderRadius: 4,
 },
 filterText: {
   color: colors.WHITE,
   textAlign: "center",
   height: 20,
   fontSize: 14,
 },
 filterTextActive: {
   color: colors.WHITE,
   textAlign: "center",
   fontWeight: 'bold',
   height: 20,
   fontSize: 16,
 },
 scrollviewContent: {
   padding: 0,
 },
  sectionHeader: {
    width: "100%",
    height: 25,
    alignItems: 'flex-start',
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.POPUP_CYAN_D,
    backgroundColor: colors.WHITE,
  },
  sectionHeaderText: {
    color: colors.BLACK,
    marginTop: 5,
    marginLeft: 3,
    fontSize: 16,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  logoImage: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    width: 50,
    height: 50,
   },
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ setLoading }, dispatch)
}


const mapStateToProps = state => {
    return {
        loading: state.app.loading,
        user: state.user
    }
}

export default connect(
    mapStateToProps,
   mapDispatchToProps
)(HomeScreen)
