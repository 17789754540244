import React from 'react';
import {TouchableOpacity, ScrollView, Button, Image, StyleSheet, Text, View} from 'react-native';
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import * as RootNavigation from '../controllers/RootNavigation';
import VideoItem from '../components/VideoItem'
import metrics, { logowidth } from "../config/metrics"

interface VideoItemProps {
  uid : string;
  url : string;
  title : string;
  duration: string;
  teacher: string;
  teacherImage: string;
  thumbnail_ipad:string;
  class_type : string;
  long_description: string;
  is_favourite: boolean;
//
}

interface VideoItemRowProps {
  videoItems: VideoItemProps[];
}

export default class VideoItemRow extends React.Component<VideoItemRowProps>  {

  render() {
    const {
      videoItems
     } = this.props;

   return (
     <ScrollView horizontal style={styles.scrollView}>
      {videoItems.map((item) => (<VideoItem
        key = {item.uid}
        uid = {item.uid}
        url = {item.url}
        title = {item.title}
        duration= {item.duration}
        teacher={item.teacher}
        teacherImage={item.teacherImage}
        thumbnail_ipad={item.thumbnail_ipad}
        class_type={item.class_type}
        long_description={item.long_description}
        short_description={item.short_description}
        is_favourite={item.is_favourite}
        added={item.added}
        />))}
      </ScrollView>
   )
 }
}

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: colors.WHITE,
  }
})
