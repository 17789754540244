import React from "react";
import { KeyboardAvoidingView, Linking, ScrollView, TouchableOpacity, Dimensions, Image, StyleSheet, Text, View} from 'react-native';
import {createStackNavigator, StackNavigator} from '@react-navigation/stack'
import { LinearGradient } from 'expo-linear-gradient';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { reSendVerificationEmail, logout } from '../actions/User'
import PopUpButton from '../components/PopUpButton'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import PopUpTextInput from '../components/PopUpTextInput';
import * as RootNavigation from '../controllers/RootNavigation';
import Firebase, { db } from '../config/Firebase'
import * as Analytics from 'expo-firebase-analytics';


class WaitToVerifyEmailScreen extends React.Component<Props>  {

  width  = 0;
  height = 0;

  constructor() {
    super()
    this.width = Dimensions.get('window').width
    this.height = Dimensions.get('window').height
    this.widthScale = this.calculateImageScaleFactor(Dimensions.get('window').width)
  }

  componentDidMount = async () => {
    // console.log("SplashScreen.componentDidMount");
    Dimensions.addEventListener('change', (e) => {
      // console.log("update " + e.window.width);
      const { width, height } = e.window;
      this.widthScale = this.calculateImageScaleFactor(width,height)
      this.width = width;
      this.height = height;
      this.forceUpdate();
    })
  }

  onLogout = async () => {
      // console.log("WaitToVerifyEmailScreen.onLogout");
      var currentRoute = RootNavigation.getCurrentRouteName();
      this.props.logout();
  }

  onEmailVerified = async () => {
    // console.log("WaitToVerifyEmailScreen.onEmailVerified");
    await Firebase.auth().currentUser.reload()
    if ( Firebase.auth().currentUser.emailVerified)  {
        // console.log("Email is verified");
        //Check subscription
        db.collection('subscribers')
        .doc(Firebase.auth().currentUser.uid)
        .get()
        .then( (doc) => {
          if (doc.exists) {
            if ( doc.data().is_active ) {
              // console.log("User has active subscription");
              Analytics.logEvent('signup_v1', {
                result: 'success',
                screen: this.constructor.name,
                reason: 'user action - email verified',
                message: 'subscriber verified email',
              })
              RootNavigation.navigate('ShowHomeScreen', {userId: Firebase.auth().currentUser.email})
            } else {
              // console.log("User doesn't have active subscription");
              Analytics.logEvent('signup_v1', {
                result: 'failed',
                screen: this.constructor.name,
                reason: 'user action - no active subscription',
                message: 'show subscription page',
              })
              RootNavigation.navigate('SubscriptionOffer', {userId: Firebase.auth().currentUser.uid, user_email:Firebase.auth().currentUser.email})
             }
          } else {
            // console.log("No subscriber doc, new customer");
            Analytics.logEvent('signup_v1', {
              result: 'failed',
              screen: this.constructor.name,
              reason: 'user action - no subscriber document',
              message: 'show subscription page',
            })
            RootNavigation.navigate('SubscriptionOffer', {userId: Firebase.auth().currentUser.uid, user_email:Firebase.auth().currentUser.email})
          }
        })
        .catch( error => {
          // console.log("Error: " + error);
        });
    } else {
      alert("Emme ole rekistereet sinun sähköpostia. Kokeile uudestaan.")
    }
  }

  calculateImageScaleFactor = (width,height) => {
    // console.log("widthScale =" + width/800*0.70);
    // console.log("heightScale = " + height/200);
    return width/800*0.70;
  }

  render() {
// console.log(this.props.user);
// console.log(this.props);
      var scale = this.widthScale;
      if ( scale > 1.5 ) {
        scale = 1.5
      }

    const width = this.width;
    const height = this.height;
    if (width< 420  ) {
      return (
        <View style={styles.containerPhonePortrait}>
          <View style={styles.containerTopPhonePortrait}>
            <Image
              style={styles.logoLeftPhonePortrait}
              source={imagepaths.logo_transparent}
              />
          </View>
          <View style={styles.containerPhonePortraitBottom}>
            <Image
              style={[styles.opeImagePhonePortrait,{width: 800*(scale), height:200*(scale)}]}
              source={imagepaths.opet_background}
              />
            <KeyboardAvoidingView style={styles.formPhonePortrait}>
              <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.POPUP_LOGO_PINK,fontWeight: 'bold', textAlign: 'center',marginBottom: "3%"}}>{strings.VERIFY_EMAIL_SCREEN_TITLE}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "1%"}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_ONE}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "2%"}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_TWO} </Text>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "2%"}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_SUPPORT} </Text>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "2%"}}> </Text>
              <PopUpButton
                textStyle={{fontSize:16}}
                containerStyle={{width:"100%",alignSelf:'center'}}
                label={strings.RESEND_EMAIL}
                 onPress={() => this.props.reSendVerificationEmail()}
                />
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.onEmailVerified()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.CONTINUE} </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.onLogout()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "4%"}}>
                <Text style={{fontSize: 12, color: colors.DODGER_BLUE, opacity: 0.7, textAlign: 'center'}}>  {strings.LOGOUT} </Text>
              </View>
            </TouchableOpacity>
           </View>
        </View>
      );
    }
    else if (height<365 ) {
      return (
        <View style={styles.container}>
          <View style={styles.firstPartPhoneLanscape}>
           <Image
               style={styles.logoLeftPhoneLanscape}
                 source={imagepaths.logo_transparent}
               />
           </View>
           <View style={styles.secondPartPhoneLanscape}>
             <KeyboardAvoidingView
               style={styles.formPhoneLanscape} behavior="padding">
               <Text style={{fontSize: 24, color: colors.POPUP_LOGO_PINK, paddingTop: "5%",paddingBottom: "2%", fontWeight: 'bold', textAlign:'center'}}> {strings.VERIFY_EMAIL_SCREEN_TITLE} </Text>
               <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "1%"}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_ONE} </Text>
               <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "1%"}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_TWO} </Text>
              <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "1%"}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_SUPPORT} </Text>
            <PopUpButton
              textStyle={{fontSize:16}}
              containerStyle={{width: "90%", alignSelf:'center' }}
              label={strings.RESEND_EMAIL}
               onPress={() => this.props.reSendVerificationEmail()}
             />
              </KeyboardAvoidingView>

              <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "5%"}}  onPress={() => this.onEmailVerified()}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                    <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.CONTINUE} </Text>
                </View>
              </TouchableOpacity>
           </View>
           <View style={styles.thirdPartPhoneLanscape}>
             <PopUpButton
               textStyle={{fontSize:12}}
                containerStyle={{width:100,alignSelf:'center'}}
                label={strings.LOGOUT}
                 onPress={this.onLogout}
               />
            </View>
        </View>
      );
    }
    else if (height< 750 ) {
      return (
        <View style={styles.container}>
        <View style={styles.firstPart}>
        <Image
          style={styles.logoLeft}
          source={imagepaths.logo_transparent}
        />
       </View>
       <View style={styles.secondPart}>
           <Image
             style={styles.opeImageSmall}
             source={imagepaths.opet_background}
           />
         <KeyboardAvoidingView style={styles.form} behavior="padding">
           <Text style={{fontFamily: 'Arial', fontSize: 30, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: 15}}>{strings.VERIFY_EMAIL_SCREEN_TITLE}</Text>
           <Text style={{fontFamily: 'Arial', fontSize: 22, color: colors.WHITE, textAlign:'left', marginBottom: 15}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_ONE}</Text>
           <Text style={{fontFamily: 'Arial', fontSize: 22, color: colors.WHITE, textAlign:'left', marginBottom: 15}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_TWO}</Text>
           <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign:'left', marginBottom: 15}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_SUPPORT}</Text>
           <Text style={{fontSize: 22, color: colors.WHITE,paddingBottom: 10, opacity: 0.7, fontWeight: 'bold', textAlign:'left'}}></Text>
           <PopUpButton
             width="50%"
             label={strings.RESEND_EMAIL}
              onPress={() => this.props.reSendVerificationEmail()}
            />
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 10}}  onPress={() => this.onEmailVerified()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>   Sähköposti vahvistettu? </Text>
                <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.CONTINUE} </Text>
              </View>
            </TouchableOpacity>
        </View>
           <View style={styles.thirdPart}>
             <PopUpButton
              textStyle={{fontSize: 16}}
               containerStyle={{width: 150 }}
                label={strings.LOGOUT}
                 onPress={this.onLogout}
               />
            </View>
        </View>
      );
    } else {
      return (
        <View style={styles.container}>
        <View style={styles.firstPart}>
        <Image
          style={styles.logoLeft}
          source={imagepaths.logo_transparent}
        />
       </View>
       <View style={styles.secondPart}>
           <Image
             style={styles.opeImageLarge}
             source={imagepaths.opet_background}
           />
         <KeyboardAvoidingView style={styles.form} behavior="padding">
           <Text style={{fontFamily: 'Arial', fontSize: 30, color: colors.POPUP_LOGO_PINK, textAlign:'left', marginBottom: 15}}>{strings.VERIFY_EMAIL_INFO_TEXT}</Text>
           <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.WHITE, textAlign:'left', marginBottom: 15}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_ONE}</Text>
           <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.WHITE, textAlign:'left', marginBottom: 15}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_TWO}</Text>
           <Text style={{fontFamily: 'Arial', fontSize: 20, color: colors.WHITE, textAlign:'left', marginTop: 15}}>{strings.VERIFY_EMAIL_INSTRUCTIONS_SUPPORT}</Text>
           <Text style={{fontSize: 22, color: colors.WHITE,paddingBottom: 10, opacity: 0.7, fontWeight: 'bold', textAlign:'left'}}></Text>
           <PopUpButton
             width="50%"
             label={strings.RESEND_EMAIL}
              onPress={() => this.props.reSendVerificationEmail()}
            />
          <Text style={{fontSize: 18, color: colors.WHITE,  paddingBottom: 5, paddingTop:35, opacity: 0.7, fontWeight: 'bold', textAlign: 'center'}}> Sähköposti vahvistettu? </Text>
            <TouchableOpacity style={{width: "100%", padding:5, alignSelf:'center'}}  onPress={() => this.onEmailVerified()}>
              <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.CONTINUE} </Text>
            </TouchableOpacity>
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 10}}  onPress={() => this.showServiceTerms()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
              </View>
            </TouchableOpacity>
        </View>
           <View style={styles.thirdPart}>
             <PopUpButton
              textStyle={{fontSize: 16}}
               containerStyle={{width: 150 }}
                label={strings.LOGOUT}
                 onPress={this.onLogout}
               />
            </View>
        </View>
      );
    }
  }
}


const styles = StyleSheet.create ({
    containerPhonePortrait: {
      flex: 1,
      flexDirection: 'column',
    },
    containerTopPhonePortrait: {
      backgroundColor: colors.BLACK,
      width: "100%",
      height: "15%",
    },
    logoLeftPhonePortrait: {
      marginTop: "3%",
      marginLeft: "3%",
      width: 50,
      height: 50,
    },
    containerPhonePortraitBottom: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "100%",
      height: "85%",
      paddingBottom: "5%"
    },
    opeImagePhonePortrait: {
    alignSelf: 'center',
    marginTop: "5%",
    marginBottom: "10%",
    transform: [
      { rotateX: '25deg'},
    ],
    opacity: 0.7
    },
    formPhonePortrait: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "85%",
      backgroundColor: colors.BLACK
    },
    firstPartPhoneLanscape: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "15%",
    },
    secondPartPhoneLanscape: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      alignItems: 'center',
      width: "70%",
    },
    thirdPartPhoneLanscape: {
      backgroundColor: colors.BLACK,
      justifyContent:'flex-start',
      alignItems: 'center',
      paddingTop: "3%",
      width: "15%",
    },
    formPhoneLanscape: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      backgroundColor: colors.BLACK
    },
   logoLeftPhoneLanscape: {
     position: 'absolute',
     top:"5%",
     left: "25%",
     width: 60,
     height: 60,
    },
    opeImageSmallPhoneLanscape: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      marginTop: "3%",
      // width: "80%",
      // height: 216,
      transform: [
      //   { perspective: 850 },
      //   { translateX: - Dimensions.get('window').width * 0.24 },
        { rotateX: '25deg'},
        // { rotateZ: '1deg'}
      ],
      opacity: 0.5
   },
    container: {
      flex: 1,
      flexDirection: 'row',
    },
    firstPart: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "15%",
    },
    secondPart: {
      backgroundColor: colors.BLACK,
      justifyContent: "center",
      alignItems: 'center',
      width: "70%",
    },
    thirdPart: {
      backgroundColor: colors.BLACK,
      justifyContent:'flex-start',
      alignItems: 'center',
      paddingTop: 30,
      width: "15%",
    },
    form: {
      flex: 1,
      alignSelf: "center",
      justifyContent: "flex-start",
      width: "80%",
      backgroundColor: colors.BLACK
    },
   logoLeft: {
     position: 'absolute',
     top:20,
     left: "15%",
     width: 120,
     height: 120,
    },
    opeImageSmall: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: "5%",
      width: "80%",
      height: 216,
      opacity: 0.9,
      transform: [
      //   { perspective: 850 },
      //   { translateX: - Dimensions.get('window').width * 0.24 },
        { rotateX: '25deg'},
        // { rotateZ: '1deg'}
      ],
      opacity: 0.7
   },
   opeImageLarge: {
     alignItems: 'center',
     justifyContent: 'center',
     marginTop: "10%",
     width: "80%",
     height: 216*1.3,
     opacity: 0.9,
     transform: [
     //   { perspective: 850 },
     //   { translateX: - Dimensions.get('window').width * 0.24 },
       { rotateX: '25deg'},
       // { rotateZ: '1deg'}
     ],
     opacity: 0.7
  },
});


const mapDispatchToProps = dispatch => {
    return bindActionCreators({ reSendVerificationEmail, logout}, dispatch)
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WaitToVerifyEmailScreen)
