const colors = {
  BLACK: "#000",
  WHITE: '#fff',
  DODGER_BLUE: "#428AF8",
  SILVER: "#BEBEBE",
  TORCH_RED: "#F8262F",
  MISCHKA: "#E5E4E6",
  POPUP_LOGO_PURPLE: "#6f09aa",
  POPUP_LOGO_LIGHT_PURPLE: '#e0c4f2',
  POPUP_LOGO_PINK: "#d17bce",
  GREY: "#d2cece",
  LIGHT_GREY: "#e0dede",
  POPUP_CYAN_A: '#9ae2d5',
  POPUP_CYAN_B: '#c0ebe3',
  POPUP_CYAN_C: '#9aedde',
  POPUP_CYAN_D: '#b4e2e4',
};

export default colors;
