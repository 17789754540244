import { combineReducers } from 'redux'

import { LOGOUT, RESEND_VERIFICATION_EMAIL, LOGIN, SIGNUP, UPDATE_EMAIL, UPDATE_PASSWORD, UPDATE_FIRST_NAME, UPDATE_LAST_NAME, UPDATE_FIRST_PASSWORD, UPDATE_SECOND_PASSWORD } from '../actions/User'
import { SET_LOADING } from '../actions/ContentLoader'

const user = (state = {}, action) => {
  // console.log("Running user reducer with action: " + action.type +" and payload: " + JSON.stringify(action.payload));
  switch (action.type) {
    case LOGIN:
      // var user_object = action.payload;
      // user_object.retries=3;
      // return user_object;
      return action.payload;
      break;
    case SIGNUP:
      return action.payload;
      break;
    case UPDATE_EMAIL:
      return {...state, email: action.payload }
      break;
    case UPDATE_PASSWORD:
      return {...state, password: action.payload}
      break;
    case UPDATE_FIRST_NAME:
      return {...state, firstname: action.payload}
      break;
    case UPDATE_LAST_NAME:
      return {...state, lastname: action.payload}
      break;
    case UPDATE_FIRST_PASSWORD:
      return {...state, firstPassword: action.payload}
      break;
    case UPDATE_SECOND_PASSWORD:
      return {...state, secondPassword: action.payload}
      break;
    case RESEND_VERIFICATION_EMAIL:
      return {...state, retries: action.payload}
    default:
        return state
  }
}

// = {loading: true}
const contentLoadingReducer = (state={loading: true}, action) => {
  // console.log("Running contentLoadingReducer with action: " + action.type +" and payload: " + JSON.stringify(action.payload));
  switch (action.type) {
    case SET_LOADING:
//      console.log("SET_LOADING");
      return {...state, loading: action.payload}
    break;
    default:
        return state
  }
}

const rootReducer = (state,action) => {
  if ( action.type=== 'LOGOUT') {
    // console.log("LOGOUT state: " + JSON.stringify(state));
    state = undefined
  }
  return appReducer(state,action)

}

const appReducer = combineReducers({
  user,
  app: contentLoadingReducer,
})

export default rootReducer
