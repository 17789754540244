import Firebase, { db } from '../config/Firebase'
import strings from '../config/strings'
import Constants from 'expo-constants'
import * as RootNavigation from '../controllers/RootNavigation';
import * as Analytics from 'expo-firebase-analytics';


export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_FIRST_NAME = 'UPDATE_FIRST_NAME'
export const UPDATE_LAST_NAME = 'UPDATE_LAST_NAME'
export const UPDATE_FIRST_PASSWORD = 'UPDATE_FIRST_PASSWORD'
export const UPDATE_SECOND_PASSWORD = 'UPDATE_SECOND_PASSWORD'
export const LOGIN = 'LOGIN'
export const SIGNUP = 'SIGNUP'
export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL'
export const LOGOUT = "LOGOUT"


export const logout = () => {
  //console.log("logout");
  Firebase.auth().signOut()
  return {
    type: LOGOUT,
    payload: ''
  }
  // dispatch({type:LOGOUT})
}

export const updateEmail = email => {
  return {
    type: UPDATE_EMAIL,
    payload: email
  }
}

export const updatePassword = password => {
  return {
    type: UPDATE_PASSWORD,
    payload: password
  }
}

export const updateFirstName = firstname => {
  return {
    type: UPDATE_FIRST_NAME,
    payload: firstname
  }
}

export const updateLastName = lastname => {
  return {
    type: UPDATE_LAST_NAME,
    payload: lastname
  }
}

export const updateFirstPassword = password => {
  return {
    type: UPDATE_FIRST_PASSWORD,
    payload: password
  }
}

export const updateSecondPassword = password => {
  return {
    type: UPDATE_SECOND_PASSWORD,
    payload: password
  }
}

export const reSendVerificationEmail = () => {
  return async (dispatch, getState) => {
    //console.log("reSendVerificationEmail");
    try {
      var {email,firstname,lastname, retries} = getState().user
      //TODO: This is hack because retries doesn't carry over in state when autologin happens
      if ( !retries) {
        retries = 3
      }
      //console.log( email + " " + retries);
      const actionCodeSettings = {
        url: Constants.manifest.extra.app_baseurl,
       // url: 'https://popuptest-4a8dc.web.app/',
        // url: 'https://localhost:19006/',
        handleCodeInApp: false
      }
      const retries_left = retries -1;
      //console.log("Sending email verfication, retries for user " + retries);
      if ( retries > 0 ) {
       await Firebase.auth().currentUser.sendEmailVerification(actionCodeSettings);
       Analytics.logEvent('signup_v1', {
         result: 'in_progress',
         screen: '',
         reason: '',
         message: 'verification email re-sent',
       })
        //console.log("SENT");
      } else {
        Analytics.logEvent('signup_v1', {
          result: 'failed',
          screen: '',
          reason: 'max retries when sending verification email',
          message: 'asked to contact support',
        })
        alert("Emme voi lähettää vahvistusviestiä enää. Otatko yhteyttä support@popupyogaoulu.com")
      }
      dispatch({ type:RESEND_VERIFICATION_EMAIL, payload: retries_left})
    }catch(error) {
      //console.log("error when sending verification email " + error)
      alert(strings.VERIFICATION_EMAIL_SENDING_FAILED)
    }
  }
}

export const login = () => {
  return async (dispatch, getState) => {
    //console.log("login()");
    try {
      const {email, password } = getState().user
      const response = await Firebase.auth().signInWithEmailAndPassword(email, password)
      //TODO: Moved this to auth status changed in Splash Screen
      //      to make sure that user info is fetched for both manual and auto login
      //dispatch(getUser(response.user.uid))
    } catch (error) {
      //console.log("Error on login: " + error.code + " and " + error.message)
      Analytics.logEvent('login_v1', {
        result: 'failed',
        screen: '',
        reason: 'error.code',
        message: '',
      })
      switch (error.code) {
        case 'auth/user-not-found':
          alert(strings.USER_NOT_FOUND)
          break;
        case 'auth/wrong-password':
          alert(strings.WRONG_PASSWORD);
          break;
        case 'auth/argument-error':
          alert(strings.SIGNUP_ARGUMENT_ERROR);
          break;
        case 'auth/invalid-email':
          alert(strings.INVALID_EMAIL);
          break;
        default:
          alert(error.message);
      }
    }
  }
}

export const getUser = uid => {
  //console.log("getUser()");
  return async (dispatch, getState) => {
    try {
      const user = await db
        .collection('users')
        .doc(uid)
        .get()
//      //console.log("found user: " + user.data().email + " with uid: " + user.data().uid +" and with data " + JSON.stringify(user.data()));
      //TODO: Should get subsription here and do all magic instead of in screens
      //If subscription table not found for ID then it's new user and payment screen should be shown
      //If subscription table is found then check if subscription is active, ir yes login, if not show payment
      var user_object = user.data();
      user_object.retries=3;
      dispatch({ type: LOGIN, payload:  user_object})
    } catch(error) {
      //console.log("Error on GetUser: " + error);
      alert(error)
    }
  }
}

export const signup =() => {
  return async (dispatch, getState ) => {
    try {
      const {email, password,firstname,lastname} = getState().user
      //console.log(firstname + " " + email + " " + password);
      const response = await Firebase.auth().createUserWithEmailAndPassword(email, password)
      Analytics.logEvent('signup_v1', {
        result: 'in_progress',
        screen: '',
        reason: '',
        message: 'user account created',
      })
      if (response.user.uid) {
        //console.log("response" + JSON.stringify(response));
        const user = {
          uid: response.user.uid,
          email: email,
          is_first_login: true,
          name: firstname ? firstname : "",
        }
        db.collection('users')
          .doc(response.user.uid)
          .set(user)
        try {
          const actionCodeSettings = {
            url: Constants.manifest.extra.app_baseurl,
            //url: 'https://popuptest-4a8dc.web.app/',
            // url: 'https://localhost:19006/',
            handleCodeInApp: false
          }
          //console.log("Sending email verfication");
          await Firebase.auth().currentUser.sendEmailVerification(actionCodeSettings);
          Analytics.logEvent('signup_v1', {
            result: 'in_progress',
            screen: '',
            reason: '',
            message: 'verification email sent',
          })
          dispatch( { type: SIGNUP, payload: user } )
        }catch(error) {
          log("error when sending verification email " + error)
          Analytics.logEvent('signup_v1', {
            result: 'failed',
            screen: '',
            reason: 'verification email sending failed',
            message: '',
          })
          alert(strings.VERIFICATION_EMAIL_SENDING_FAILED)
        }
      }
    } catch (error) {
        // console.log("Error on signup: " + error.code + " and " + error.message);
        Analytics.logEvent('signup_v1', {
          result: 'failed',
          screen: '',
          reason: 'error.code',
          message: 'error when creating account',
        })
        switch (error.code) {
          case 'auth/wrong-password':
            alert(strings.WRONG_PASSWORD);
            break;
          case 'auth/invalid-email':
            alert(strings.INVALID_EMAIL);
            break;
          case 'auth/argument-error':
            alert(strings.SIGNUP_ARGUMENT_ERROR);
            break;
          default:
            alert(error.message);
        }
      }
    }
  }
