const strings_en = {
  SERVICE_TERMS:  "POP UP YOGA FINLAND OY - POPUPYOGAVIDEOS.COM \n"+
  "PALVELUN KÄYTTÖEHDOT SEKÄ VASTUUVAPAUTUS \n\n\n" +
  "MÄÄRITELMÄT \n\n"+
  "Videopalvelu - Pop Up Yoga Finlandin ylläpitämä videopalvelu osoitteessa popupyogavideos.com\n"+
  "Asiakas - Henkilö joka on rekisteröitynyt videopalveluun\n" +
  "Yritys - Pop Up Yoga Finland Oy\n" +
  "Katselukausi - 1 kuukausi tilauspäivästä eteenpäin, 28-31 päivää riippuen kuukaudesta.\n\n\n" +
  "1. VAPAAEHTOINEN OSALLISTUMINEN \n\n"+
  "Ymmärrän, etta osallistun Pop Up Yoga Finlandin tarjoamiin joogatunteihin, tapahtumiin ja harjoituksiin vapaaehtoisesti. Olen tietoinen harjoittelun riskeistä ja olen kykenevä harkitsemaan oman osallistumiseni terveyteni ja taitojeni kannalta. Jos olen epävarma omasta terveydentilastani, olen velvollinen itse käymään lääkärin tai fysioterapeutin tarkastuksessa ennen harjoittelun aloittamista tai jatkamista. Ymmärrän, etta joogaopettaja tai muu ohjaaja videopalvelun verkkotunneilla tai Pop Up Yoga Finlandin muissa tapahtumissa ei ole terveydenhuollon ammattilainen (kuten esimerkiksi lääkäri), eika näin ollen voi antaa diagnoosia tai luotettavaa arvioita terveydentilastani tai siita, mitka ovat juuri minun kohdallani harjoittelun riskit. \n\n\n" +
  "2. POP UP YOGA FINLANDIN JA SEN EDUSTAJAN VAPAUTUS VASTUUSTA \n\n" +
  "Tietoisena kaikkeen liikunta- joogaharjoitteluun sisaltyvista riskeista ilmoitan, että vapautan Pop Up Yoga Finlandin ja sen edustajat tuntien, kurssien sekä tapahtumien järjestäjinä ja/tai opettajina kaikesta vastuusta, mikäli minulle aiheutuu terveyteen kohdistuvaa vahinkoa harjoitellesani verkkotunneilla, online live-tunneilla tai Pop Up Yoga Finlandin eri tiloissa järjestämissä tilaisuuksissa. Valitsen harjoittelun, verkkotunneilla, online live-tunneilla sekä yrityksen eri toimipisteissa tarjoamissa tapahtumissa omalla riskillani. \n \n\n" +
  "3. VAKUUTUKSET \n\n" +
  "Olen tietoinen, että asiakkaana vastaan itse tapaturmavakuutuksestani.\n \n\n"+
  "4. MAKSUJEN MAKSAMINEN \n\n" +
  "Videopalvelun tilauksen ensimmäinen maksu veloitetaan heti tilauksen yhteydessä. Jatkossa maksu veloitetaan katselaukausittain, riippuen videopalvelun tilauspäivästä. Mikäli tilauksen veloitus epäonnistuu, asiakkaan tilaus peruutetaan. Tilauksen voi palauttaa päivittämällä maksutiedot sekä suorittamalla onnistunut tilausmaksu.\n \n\n"+
  "5. ASIAKKAAN OIKEUS IRTISANOA TILAUS \n\n" +
  "Videopalvelun peruutus tulee tehdä sähköpostitse: support@popupyogaoulu.com. Peruutus astuu voimaan ilmoituksen tekopäivänä. Peruutuksen jälkeen palvelun käyttöoikeus jatkuu kuluvan ja jo maksetun katselukauden loppuun, eikä katselukaudella jäljelläolevaa jo maksettua veloitusta palauteta.\n\n\n "+
  "6. VIDEOPALVELUN KÄYTETTÄVYYS \n\n" +
  "Pop Up Yoga Finland ei vastaa siitä että asiakkaan yhteys toimisi odotetulla tavalla tai että verkkopalvelu olisi aina tai keskeytyksettä saatavilla.\nPop Up Yoga Finlandilla on oikeus keskeyttää videopalvelun tarjonta tarpeellisten huolto- ja ylläpitotoimenpiteiden ajaksi. Asiakas ymmärtää, että tarjottava Verkkopalvelu ei välttämättä ole kaikkina aikoina Asiakkaan saatavilla, mikäli katkos johtuu tietojärjestelmä- tai tietoliikennehäiriöstä tai tietoliikenne- tai televerkkojen taikka tietojärjestelmien ruuhkautumisesta tai esteestä, joka huomattavasti vaikeuttaa videopalvelun toimintaa Pop Up Yoga Finlandin pystymättä vaikeuksitta vaikuttamaan esteen ilmenemiseen tai jatkumiseen\n\n\n" +
  "7. ASIAKKAAN TIETOJEN TALLENTAMINEN JA VIDEOPALVEUUN ANNETUT TIEDOT\n\n" +
  "Videopavelua varten asiakkaasta tallennetaan seuraavat tiedot: \n- nimi \n- puhelinnumero \n- sähköposti \n- sopimuksen aloituspäivämäärä \n- sopimuksen päättymispäivämäärä. \n\nNäitä tietoja käytetään pavelun mahdollistamiseksi. Asiakas voi pyytää hänestä tallennetut tiedot tai pyytää poistamaan asiakasrekisterin sähköpostilla osoitteesta support@popupyogaoulu.com\n \n \n"+
  "8. IMMATERIAALIOIKEUDET JA PALVELUN KÄYTTÄMINEN\n\n" +
  "Myönnämme sinulle rajatun, ei-yksinomaisen ja peruutettavissa olevan oikeuden käyttää videopalvelua sekä rajatun, ei-yksinomaisen ja peruutettavissa olevan oikeuden käyttää videopalvelun sisältöä henkilökohtaisiin, ei-kaupallisiin tarkoituksiin. Tämä Käyttöoikeus on voimassa, kunnes sinä irtisanot tai Pop Up Yoga Finland irtisanoo sen. Vakuutat ja hyväksyt sen, että käytät videopalvelua ja sen sisältöä omiin henkilökohtaisiin, ei-kaupallisiin tarkoituksiisi etkä jaa edelleen etkä siirrä videopalvelua tai sen sisältöä. Videopalvelun ohjelmistosovelluksia ja sen sisältöä ei myydä eikä siirretä sinulle, ja kaikkien videopalvelun ohjelmistosovellusten ja sen sisällön kopioiden omistusoikeus säilyy Pop Up Yoga Finlandilla myös sen jälkeen, kun ne on asennettu tietokoneisiisi, mobiililaitteisiisi, tabletteihisi, ja/tai muihin laitteisiisi.\n\nPop Up Yoga Finlandin videopalvelusivustolla osoitteessa popupyogavideos.com olevan aineiston omistusoikeus, tekijänoikeus, tavaramerkki- ja kaikki muut immateriaalioikeudet kuuluvat Pop Up Yoga Finlandille, ellei toisin ole ilmoitettu. Videopalvelun aineiston tai sen osan lainaaminen, kopiointi, tallentaminen, muokkaaminen, muuntelu, siirtäminen, levittäminen, luovuttaminen, muu hyväksikäyttäminen tai hyödyntäminen edes osittain ilman Pop Up Yoga Finlandin ennalta antamaa kirjallista lupaa on ehdottomasti kielletty. Myös videopalvelun käyttäjätunnuksen lainaaminen, siirtäminen, levittäminen, tai luovuttaminen ilman Pop Up Yoga Finlandin ennalta antamaa kirjallista lupaa on ehdottomasti kielletty.\n\n\n"+
  "9. TIETOTURVALLISUUS \n\n" +
  "Asiakas vastaa siitä, että hänellä on videopalvelun käytön ja asianmukaisen tietoturvan edellyttämät laitteet, ohjelmat ja järjestelmät, kuten päätelaitteet, selaimet ja liittymät mukaan lukien tietoliikenneyhteydet. Pop Up Yoga Finland ei takaa, että videopalvelua voi käyttää asiakkaan laitteilla, ohjelmilla, järjestelmillä tai liittymillä.\n\n\n" +
  "10. SOPIMUSEHTOJEN MUUTTAMINEN \n\n" +
  "Pop Up Yoga Finlandilla on oikeus yksipuoleisesti muuttaa näitä ehtoja. Pop Up Yoga Finland voi ajoittain muuttaa tilausten hintaa. Asiakkalle lähetetään tiedote hinnanmuutoksista hyvissä ajoin ja asiakkaan tulee hyväksyä hintamuutos kirjallisesti. Mikäli asiakas ei hyväksy hintamuutosta, hänen tilauksensa keskeytetään maksetun katselukauden loputtua.\n\n\n" +
  "11. TUTUSTUMISVELVOLLISUUS \n\n" +
  "Asiakas on velvollinen tutustumaan näihin sääntöihin ja ehtoihin ennen videopalvelun tilaamista. Asiakas sitoutuu tilauksen ja maksun yhteydessä noudattamaan edelläolevia käyttöehtoja. \n\n\n" +
  "Jos sinulla on kysyttävää sopimusehdoista niin voit ottaa yhteyttä meihin \n support@popupyogaoulu.com \n  +358 440 188 486\n\n Pop Up Yoga Finland ",
}

const serviceterms = {
  SERVICE_TERMS:  "POP UP YOGA FINLAND OY - POPUPYOGAVIDEOS.COM \n"+
  "PALVELUN KÄYTTÖEHDOT SEKÄ VASTUUVAPAUTUS \n\n\n" +
  "MÄÄRITELMÄT \n\n"+
  "Videopalvelu - Pop Up Yoga Finlandin ylläpitämä videopalvelu osoitteessa popupyogavideos.com\n"+
  "Asiakas - Henkilö joka on rekisteröitynyt videopalveluun\n" +
  "Yritys - Pop Up Yoga Finland Oy\n" +
  "Katselukausi - 1 kuukausi tilauspäivästä eteenpäin, 28-31 päivää riippuen kuukaudesta.\n\n\n" +
  "1. VAPAAEHTOINEN OSALLISTUMINEN \n\n"+
  "Ymmärrän, etta osallistun Pop Up Yoga Finlandin tarjoamiin video- sekä livetunneille, tapahtumiin ja harjoituksiin vapaaehtoisesti. Olen tietoinen harjoittelun riskeistä ja olen kykenevä harkitsemaan oman osallistumiseni terveyteni ja taitojeni kannalta. Jos olen epävarma omasta terveydentilastani, olen velvollinen itse käymään lääkärin tai fysioterapeutin tarkastuksessa ennen harjoittelun aloittamista tai jatkamista. Ymmärrän, etta joogaopettaja tai muu ohjaaja videopalvelun verkkotunneilla tai Pop Up Yoga Finlandin muissa tapahtumissa ei ole terveydenhuollon ammattilainen (kuten esimerkiksi lääkäri), eika näin ollen voi antaa diagnoosia tai luotettavaa arvioita terveydentilastani tai siita, mitka ovat juuri minun kohdallani harjoittelun riskit. \n\n\n" +
  "2. POP UP YOGA FINLANDIN JA SEN EDUSTAJAN VAPAUTUS VASTUUSTA \n\n" +
  "Tietoisena kaikkeen liikunta- joogaharjoitteluun sisaltyvista riskeista ilmoitan, että vapautan Pop Up Yoga Finlandin ja sen edustajat tuntien, kurssien sekä tapahtumien järjestäjinä ja/tai opettajina kaikesta vastuusta, mikäli minulle aiheutuu terveyteen kohdistuvaa vahinkoa harjoitellesani verkkotunneilla, online live-tunneilla tai Pop Up Yoga Finlandin eri tiloissa järjestämissä tilaisuuksissa. Valitsen harjoittelun, verkkotunneilla, online live-tunneilla sekä yrityksen eri toimipisteissa tarjoamissa tapahtumissa omalla riskillani. \n \n\n" +
  "3. VAKUUTUKSET \n\n" +
  "Olen tietoinen, että asiakkaana vastaan itse tapaturmavakuutuksestani.\n \n\n"+
  "4. MAKSUJEN MAKSAMINEN \n\n" +
  "Videopalvelun tilauksen ensimmäinen maksu veloitetaan heti tilauksen yhteydessä. Jatkossa maksu veloitetaan katselaukausittain, riippuen videopalvelun tilauspäivästä. Mikäli tilauksen veloitus epäonnistuu, asiakkaan tilaus peruutetaan. Tilauksen voi palauttaa käyttöön päivittämällä maksutiedot sekä suorittamalla onnistunut tilausmaksu.\n \n\n"+
  "5. ASIAKKAAN OIKEUS IRTISANOA TILAUS \n\n" +
  "Videopalvelun peruutus tulee tehdä sähköpostitse: support@popupyogaoulu.com. Peruutus astuu voimaan ilmoituksen tekopäivänä. Peruutuksen jälkeen palvelun käyttöoikeus jatkuu kuluvan ja jo maksetun katselukauden loppuun, eikä katselukaudella jäljelläolevaa jo maksettua veloitusta palauteta.\n\n\n "+
  "6. VIDEOPALVELUN KÄYTETTÄVYYS \n\n" +
  "Pop Up Yoga Finland ei vastaa siitä että asiakkaan yhteys toimisi odotetulla tavalla tai että verkkopalvelu olisi aina tai keskeytyksettä saatavilla.\nPop Up Yoga Finlandilla on oikeus keskeyttää videopalvelun tarjonta tarpeellisten huolto- ja ylläpitotoimenpiteiden ajaksi. Asiakas ymmärtää, että tarjottava Verkkopalvelu ei välttämättä ole kaikkina aikoina Asiakkaan saatavilla, mikäli katkos johtuu tietojärjestelmä- tai tietoliikennehäiriöstä tai tietoliikenne- tai televerkkojen taikka tietojärjestelmien ruuhkautumisesta tai esteestä, joka huomattavasti vaikeuttaa videopalvelun toimintaa Pop Up Yoga Finlandin pystymättä vaikeuksitta vaikuttamaan esteen ilmenemiseen tai jatkumiseen\n\n\n" +
  "7. ASIAKKAAN TIETOJEN TALLENTAMINEN JA VIDEOPALVEUUN ANNETUT TIEDOT\n\n" +
  "Videopavelua varten asiakkaasta tallennetaan seuraavat tiedot: \n- nimi \n- puhelinnumero \n- sähköposti \n- sopimuksen aloituspäivämäärä \n- sopimuksen päättymispäivämäärä. \n\nNäitä tietoja käytetään pavelun mahdollistamiseksi. Asiakas voi pyytää hänestä tallennetut tiedot tai pyytää poistamaan asiakasrekisterin sähköpostilla osoitteesta support@popupyogaoulu.com\n \n \n"+
  "8. IMMATERIAALIOIKEUDET JA PALVELUN KÄYTTÄMINEN\n\n" +
  "Myönnämme sinulle rajatun, ei-yksinomaisen ja peruutettavissa olevan oikeuden käyttää videopalvelua sekä rajatun, ei-yksinomaisen ja peruutettavissa olevan oikeuden käyttää videopalvelun sisältöä henkilökohtaisiin, ei-kaupallisiin tarkoituksiin. Tämä Käyttöoikeus on voimassa, kunnes sinä irtisanot tai Pop Up Yoga Finland irtisanoo sen. Vakuutat ja hyväksyt sen, että käytät videopalvelua ja sen sisältöä omiin henkilökohtaisiin, ei-kaupallisiin tarkoituksiisi etkä jaa edelleen etkä siirrä videopalvelua tai sen sisältöä. Videopalvelun ohjelmistosovelluksia ja sen sisältöä ei myydä eikä siirretä sinulle, ja kaikkien videopalvelun ohjelmistosovellusten ja sen sisällön kopioiden omistusoikeus säilyy Pop Up Yoga Finlandilla myös sen jälkeen, kun ne on asennettu tietokoneisiisi, mobiililaitteisiisi, tabletteihisi, ja/tai muihin laitteisiisi.\n\nPop Up Yoga Finlandin videopalvelusivustolla osoitteessa popupyogavideos.com olevan aineiston omistusoikeus, tekijänoikeus, tavaramerkki- ja kaikki muut immateriaalioikeudet kuuluvat Pop Up Yoga Finlandille, ellei toisin ole ilmoitettu. Videopalvelun aineiston tai sen osan lainaaminen, kopiointi, tallentaminen, muokkaaminen, muuntelu, siirtäminen, levittäminen, luovuttaminen, muu hyväksikäyttäminen tai hyödyntäminen edes osittain ilman Pop Up Yoga Finlandin ennalta antamaa kirjallista lupaa on ehdottomasti kielletty. Myös videopalvelun käyttäjätunnuksen lainaaminen, siirtäminen, levittäminen, tai luovuttaminen ilman Pop Up Yoga Finlandin ennalta antamaa kirjallista lupaa on ehdottomasti kielletty.\n\n\n"+
  "9. TIETOTURVALLISUUS \n\n" +
  "Asiakas vastaa siitä, että hänellä on videopalvelun käytön ja asianmukaisen tietoturvan edellyttämät laitteet, ohjelmat ja järjestelmät, kuten päätelaitteet, selaimet ja liittymät mukaan lukien tietoliikenneyhteydet. Pop Up Yoga Finland ei takaa, että videopalvelua voi käyttää asiakkaan laitteilla, ohjelmilla, järjestelmillä tai liittymillä.\n\n\n" +
  "10. SOPIMUSEHTOJEN MUUTTAMINEN \n\n" +
  "Pop Up Yoga Finlandilla on oikeus yksipuoleisesti muuttaa näitä ehtoja. Pop Up Yoga Finland voi ajoittain muuttaa tilausten hintaa. Asiakkalle lähetetään tiedote hinnanmuutoksista hyvissä ajoin ja asiakkaan tulee hyväksyä hintamuutos kirjallisesti. Mikäli asiakas ei hyväksy hintamuutosta, hänen tilauksensa keskeytetään maksetun katselukauden loputtua.\n\n\n" +
  "11. TUTUSTUMISVELVOLLISUUS \n\n" +
  "Asiakas on velvollinen tutustumaan näihin sääntöihin ja ehtoihin ennen videopalvelun tilaamista. Asiakas sitoutuu tilauksen ja maksun yhteydessä noudattamaan edelläolevia käyttöehtoja. \n\n\n" +
  "Jos sinulla on kysyttävää sopimusehdoista niin voit ottaa yhteyttä meihin \n support@popupyogaoulu.com \n  +358 440 188 486\n\n Pop Up Yoga Finland ",
}

export default serviceterms;
