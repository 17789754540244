const imagepaths = {
  logo: require('../images/logo_240x240.jpeg'),
  logo_transparent: require('../images/logo_320x320_transparent.png'),
  icon_info: require('../images/icon_info_512x512.png'),
  icon_profile: require('../images/icon_profile_1600x1600.png'),
  ope_heidi: require('../images/ope_heidi_768x960.jpg'),
  ope_jenni: require('../images/ope_jenni_922x1229.jpg'),
  ope_katariina: require('../images/ope_katariina_768x960.jpg'),
  ope_katja: require('../images/ope_katja_902x1353.jpg'),
  ope_katriina: require('../images/ope_katriina_750x750.jpg'),
  ope_maria: require('../images/ope_maria_856x1284.jpg'),
  ope_marjo: require('../images/ope_marjo_768x960.jpg'),
  ope_marru: require('../images/ope_marru_818x1228.jpg'),
  ope_merja: require('../images/ope_merja_902x1353.jpg'),
  ope_minnamari: require('../images/ope_minnamari_870x1305.jpg'),
  ope_riikka: require('../images/ope_riikka_832x832.jpg'),
  ope_saanatuulia: require('../images/ope_saanatuulia_768x960.jpg'),
  ope_saara: require('../images/ope_saara_480x640.jpg'),
  ope_seija: require('../images/ope_seija_683x853.jpg'),
  ope_tiitta: require('../images/ope_tiitta_480x640.jpg'),
  ope_heli: require('../images/ope_heli_770x770.jpg'),
  ope_anu: require('../images/ope_anu_961x640.jpg'),
  ope_david: require('../images/ope_david_768x960.jpg'),
  icon_add_to_favourites: require('../images/icon_favourites_add_600×558.png'),
  icon_remove_from_favourites: require('../images/icon_favourites_remove_600×558.png'),
  opet_background: require('../images/opet_background_800x216.png'),
  background: require('../images/background_800x900_20p_opaque.png')
}

export const MapTeacherPath = teacher => {
  switch (teacher) {
    case "Anu":
      return imagepaths.ope_anu;
      break;
    case "Heidi":
      return imagepaths.ope_heidi;
      break;
    case "Jenni":
      return imagepaths.ope_jenni;
      break;
    case "Katariina":
      return imagepaths.ope_katariina;
      break;
    case "Katja":
      return imagepaths.ope_katja;
      break;
    case "Katriina":
      return imagepaths.ope_katriina;
      break;
    case "Maria":
      return imagepaths.ope_maria;
      break;
    case "Marjo":
      return imagepaths.ope_marjo;
      break;
    case "Marru":
      return imagepaths.ope_marru;
      break;
    case "Merja":
      return imagepaths.ope_merja;
      break;
    case "Minna-Mari":
      return imagepaths.ope_minnamari;
      break;
    case "Riikka":
      return imagepaths.ope_riikka;
      break;
      case "Saana-Tuulia":
        return imagepaths.ope_saanatuulia;
        break;
    case "Saanatuulia":
      return imagepaths.ope_saanatuulia;
      break;
    case "Saara":
      return imagepaths.ope_saara;
      break;
    case "Seija":
      return imagepaths.ope_seija;
      break;
    case "Tiitta":
      return imagepaths.ope_tiitta;
      break;
    case "Heli":
      return imagepaths.ope_heli;
    case "David":
      return imagepaths.ope_david;
      break;
    default:
      return imagepaths.logo;
  }
}

export default imagepaths
