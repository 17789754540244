
const strings_en = {
SPLASH_TITLE: "Pop Up Yoga Video Service",
SPLASH_PITCH: "Unlimited access to Yoga, Pilates, Meditation and training videoclasses. Now also live events",
SUBSCRIPTION_OFFER_TITLE: "Watch on any device!",
LOGIN_SCREEN_TITLE: "Welcome Back!",
VERIFY_EMAIL_SCREEN_TITLE: "Vahvista Sähköpostiosoitteesi",
VERIFY_EMAIL_INSTRUCTIONS: "Send verification email with button below. Find email from your inbox, click verification link and you will be logged into  the service. If you did not get email check spam folder. If you encounter problems contact support@popupyogaoulu.com.",
RESEND_EMAIL: "Request to re-send email",
SEND_VERIFICATION_EMAIL: "Send verification email here",
ALREADY_USER: "I already have account",
LOGIN: "Log In",
WELCOME_TO_LOGIN: "Welcome to the login screen!",
NAME_PLACEHOLDER: "First  and Lastname",
EMAIL_PLACEHOLDER: "Email",
ACCOUNT_PLACEHOLDER: "Email",
FIRSTNAME_PLACEHOLDER: "Firstname",
VERIFICATION_EMAIL_SENDING_FAILED: "We had issues to verification email to you. Please contact popupyogaoulu@gmail.com",
LASTNAME_PLACEHOLDER: "Lastname",
CONFIMRM_EMAIL_PLACEHOLDER: "Confirm email",
PASSWORD_PLACEHOLDER: "Password",
CREATE_PASSWORD_PLACEHOLDER: "Give new password",
VERIFY_PASSWORD_PLACEHOLDER: "Verify new password",
START_CREATING_ACCOUNT: "Start creating your account here",
CREATE_ACCOUNT: "Create account",
LEARN_HOW_TO_JOIN: "Learn more and subscribe",
CREATE_ACCOUNT_INFO: "Create free acoount",
FAMILIARIZE_FOR_POPUP_SERVICE: "Read more on Pop Up Video Service",
JOIN_POP_UP_COMMUNITY:"Join Pop Up Video Community",
FORGOT_PASSWORD: "I don't remember my password",
RESET_PASSWORD_REQUEST_FAILED: "There was error while sending password reset email. Try again later",
RESET_PASSWORD_EMAIL_SENT: "We have sent you link to reset password. Check your email.",
REGISTER: "Sign Up",
LOGOUT: "Logout",
SUBSCRIBE:"Subscribe for video service, 10€ per month",
MISSING_ACCOUNT: "Start here if you don't have account",
WELCOME_MESSAGE: "Welcome ",
VIDEO_QUICK_ACTION_ONE: "Jooga",
VIDEO_QUICK_ACTION_TWO: "Meditaatio",
VIDEO_QUICK_ACTION_THREE: "Kehonpaino",
VIDEO_PLAY: "Play",
VIDEO_INFO: "Info",
INVALID_EMAIL: "Email address is badly formatted",
USER_DISABLED: "User acocunt is disabled",
USER_NOT_FOUND: "This user is not member of Pop Up Yoga Oulu yet",
WRONG_PASSWORD: "Incorrect password",
PROFILE_TITLE: "Profile Page",
SIGNUP_ARGUMENT_ERROR: "Either email or password is missing",
UPDATE_NEEDED: "Your app is too old and needs to be updated",
NO_SUBSCRIPTION_TEXT: "If you have not yet member of Pop Up Yoga Oulu video service. You can subscribe by sending email to popupyogaoulu@gmail.com. Service costs 10€ month.",
SERVICE_TERMS_LINK: "Service terms",
SERVICE_TERMS: "Paljonko maksaa, miten veloitetaan, mitä tietoa tallennetaan...",
REQUEST_TO_RESET_PAWSSWORD_DESCRIPTION: "Here you can request to reset password. Input your user id below twice and requets to reset. You will receive email with link to update your password",
BACK_BUTTON: "Back",
BACK_TO_VIDEOS: "< Back",
ADD_AS_FAVOURITE: "Add as favourite",
REMOVE_FAVOURITE: "Remove as favourite",
VERIFY_EMAIL_ADDRESS: "Verify your email address",
VERIFY_EMAIL_INFO_TEXT: "Confirm your email",
RESET_PASSWORD: "Request to rest password",
EMAIL_MISMATCH_TEXT: "Emails doesn't match",
EMPTY_SCREEN_TITLE: " ",
CONFIRM_LOGOUT: "Are you sure you want to log out from the app?",
HEY: "Hi",
CLASS_ADDED_DATE: "Class is published",
LOGIN_ACCEPT_TOS: "By logging in you accept Pop Up Yoga Finland ",
SIGN_UP_ACCEPT_TOS: "By signing up you accept Pop Up Yoga Finland ",
LOGIN_ACCEPT_TOS: "By logging in you accept Pop Up Yoga Finland terms of services",
START_LOGIN_HERE: "Login here",
CONTINUE : "Continue",
INSTRUCTIONS: "How to proceed",
SIGNUP_INFO_TEXT: "Katsele millä laitteella tahansa, iOS, Android sekä Webissä. 10€/kuukaudessa, voit irtisanoa milloin tahansa. \n Luo tunnukset, vahivsta sähköposti, maksa ja katsele.",
PAYMENT_PITCH_SHORT: "10€/kuukaudessa, voit irtisanoa milloin tahansa. \n Luo tunnukset, vahivsta sähköposti, maksa ja katsele.",
VIDEO_SEARCH_FILTER_TITLE: "Search",
WHAT_IS_VIDEOSERVICE:" Mikä on Pop Up Yoga videopalvelu?",
WHAT_IS_VIDEOSERVICE_DESC:"Videopalvelu on Pop Upin Porukoiden kotiharjoittelumahdollisuus. Videopalvelustamme löydät runsaasti harjoitusvideoita niin joogaan, pilatekseen, meditaatioon kuin kehonpainoharjoitteluunkin. Videopalvelun tilaajilla on mahdollisuus liittyä omaan suljettuun Facebook-ryhmään jossa lähetämme viikoittain useita Live-tunteja.",
DOES_CLASSES_FIT_FOR_ME:" Sopivatko tunnit minulle?",
DOES_CLASSES_FIR_FOR_ME_DESC:"Kokeneiden opettajiemme johdolla harjoittelet Joogaa ja Pilatesta turvallisesti. Opettajamme antavat tunneilla vaihtoehtoja, jolloin tunnit soveltuvat niin aloitteleville kuin edistyneemmille joogeille. Harjoittelu pohjautuu arvoihimme ilo, yhteenkuuluvuus ja turvallisuus.",
WHERE_CAN_I_WATCH:" Missä voin katsoa?",
WHERE_CAN_I_WATCH_DESC:"Katselu onnistuu kaikilla laitteilla josta löytyy verkkoselain ja internetyhteys. Lisäksi voit ladata iOS ja Android mobiilisovelluksen puhelimeesi tai tabletille, jolloin videokirjasto on sinulla aina mukanasi.",
WHAT_DOES_IT_COST:" Mitä se maksaa?",
WHAT_DOES_IT_COST_DESC:"Voit harjoitella rajattomasti kiinteään kuukausihintaan - 10€/kk. Tilaus laskutetaan kuukausittain automaattisesti.",
HOW_TO_CANCEL_SUBSCRIPTION:" Miten perun tilauksen?",
HOW_TO_CANCEL_SUBSCRIPTION_DESC:"Tilauksen voi irtisanoa milloin vain, maksat silloin vain kuluvan maksukauden (30-päivää) loppuun. Voit perua tilauksen suoraan verkkopalvelusta tai lähettämällä sähköpostia osoitteeseen support@popupyogaoulu.com.",
READ_MORE_ABOUT_VIDEOSERVICE:"Lue Lisää videopalvelusta",
MANAGE_SUBSCRIPTION: "Manage service subscription",
CANCEL_SUBSCRIPTION: "Cancel service subscription by email",
INVITE_FRIEND: "Invite your friend to join Pop Up Yoga Videoservice",
TRY_CANCELLING_WITH_EMAIL: "Could not load subscription management page. You can cancel subscription or update payment information by sending email to support@popupyogaoulu.com",
};


const strings = {
SPLASH_TITLE: "Harjoittele Kotona!",
SPLASH_PITCH_ONE: "Joogaa suurella sydämellä",
SPLASH_PITCH_TWO: "",
SPLASH_PITCH_THREE: "Katso milloin ja missä vain",
SPLASH_PITCH: "Rajaton katseluoikeus sekä viikottaisia livetunteja.",
SUBSCRIPTION_OFFER_TITLE: "Videopalvelu aina mukanasi!",
LOGIN_SCREEN_TITLE:"Kirjaudu",
VERIFY_EMAIL_SCREEN_TITLE: "Vahvista Sähköpostiosoitteesi",
SIGNUP_INFO_TEXT: "Rajaton katseluoikeus, 10€ kuukaudessa. Voit irtisanoa koska tahansa.",
//VERIFY_EMAIL_INFO_TEXT: "Haluamme varmistaa että teimme sinulle tunnukset oikealla sähköpostilla ennen kuin siiryt maksamaan.",
VERIFY_EMAIL_INFO_TEXT: "Vahvista sähköpostiosoitteesi",
INSTRUCTIONS: "Ohjeet",
VERIFY_EMAIL_INSTRUCTIONS: "Avaa sähköposti ja paina siinäolevaa linkkiä niin pääset kirjautumaan sovellukseen. Jos et saanut linkkiä niin tarkista myös roskapostilaatikko. Ongelmatilanteissa Ota yhteyttä support@popupyogaoulu.com.",
VERIFY_EMAIL_INSTRUCTIONS_ONE: "Lähetimme sinulle vahvistusviestin sähköpostiin. Avaa viesti ja seuraa siinä olevia ohjeita. ",
VERIFY_EMAIL_INSTRUCTIONS_TWO: "",
VERIFY_EMAIL_INSTRUCTIONS_SUPPORT: "Jos et saanut linkkiä niin tarkista myös roskapostilaatikko. Ongelmatilanteissa Ota yhteyttä support@popupyogaoulu.com",
RESEND_EMAIL: "Lähetä vahvistusviesti uudelleen",
SEND_VERIFICATION_EMAIL: "Lähetä vahvistusviesti tästä",
ALREADY_USER: "Jos sinulla on jo tunnukset niin",
LOGIN: "Kirjaudu Sisään",
WELCOME_TO_LOGIN: "Welcome to the login screen!",
NAME_PLACEHOLDER: "Etu- ja sukunimi",
EMAIL_PLACEHOLDER: "Sähköpostiosoite",
LASTNAME_PLACEHOLDER: "Sukunimi",
FIRSTNAME_PLACEHOLDER: "Etunimi",
VERIFICATION_EMAIL_SENDING_FAILED: "Emme voineet lähettää sinulle sähköpostin varimistuviestiä. Otatko yhteyttä popupyogaoulu@gmail.com",
ACCOUNT_PLACEHOLDER: "Käyttäjätunnus",
CONFIMRM_EMAIL_PLACEHOLDER: "Käyttäjätunnus uudelleen",
PASSWORD_PLACEHOLDER: "Salasana",
CREATE_PASSWORD_PLACEHOLDER: "Luo uusi salasana",
VERIFY_PASSWORD_PLACEHOLDER: "Varmista uusi salasana",
START_CREATING_ACCOUNT: "Aloita tunnusten luonti tästä",
CREATE_ACCOUNT: "Luo tunnukset",
LEARN_HOW_TO_JOIN: "Tutustu palveluun",
CONTINUE : "Jatka Palveluun",
CREATE_ACCOUNT_INFO: "Luo ilmaiset tunnukset",
//FAMILIARIZE_FOR_POPUP_SERVICE: "Tule Mukaan Pop Upin Porukkaan",
FAMILIARIZE_FOR_POPUP_SERVICE: "Tutustu palveluun ja tilaa tunnukset",
FORGOT_PASSWORD: "Vaihda salasana",
RESET_PASSWORD_REQUEST_FAILED: "Jotain meni pieleen kun yritimme lähettää salasanan resetointi linkkiä sähköpostiin. Kokeile hetken päästä uudestaan",
RESET_PASSWORD_EMAIL_SENT: "Lähetimme sinulle linkin sähköpostiin salasanan vaihtoa varten. Tarkista sähköpostisi",
REGISTER: "Luo tunnukset",
INTERESTED_JOIN_POP_UP_COMMUNITY:"Tule Mukaan Pop Upin Videoporukkaan!",
JOIN_POP_UP_COMMUNITY:"Liity Pop Upin Videoporukkaan!",
SIGNUP_AND_JOIN_POP_UP_COMMUNITY:"Luo tunnukset ja liity Pop Upin videoporukkaan!",
PAYMENT_PITCH: "Kokeneiden opettajiemme johdolla harjoittelet Joogaa ja Pilatesta missä ja milloin vain. Lisäksi löydät meditaatio- sekä kehonpainoharjoituksia ja pääset osallistumaan livetunneille. Harjoittelu pohjautuu arvoihimme ilo, yhteenkuuluvuus ja turvallisuus.\n\nKatselu onnistuu verkkoselaimella, iOS ja Android mobiilisovelluksilla.",
VALUES: "Ilo, yhteenkuuluvuus ja turvallisuus",
PAYMENT_INFO:"10€/kk tilaus veloitetaan automaattisesti kuukausittain kortiltasi. Voit irtisanoa tilauksen milloin tahansa ja maksat tällöin vain kuluvan katselukauden loppuun. Lisää seuraavassa vaiheessa maksukorttisi tiedot kuukausiveloitusta varten.",
PAYMENT_INFO_TWO:"Seuraavaksi sinulta kysytään luotto/pankkikortti jota haluat käyttää veloitukseen.",
PROCEED_TO_PAYMENT: "Jatka maksamaan",
LOGOUT: "Kirjaudu ulos",
SUBSCRIBE:"Tilaa videopalvelu, 10€ kuukaudessa",
MISSING_ACCOUNT: "Aloita tästä jos sinulla ei vielä ole tunnuksia",
WELCOME_MESSAGE: "Tervetuloa ",
VIDEO_QUICK_ACTION_ONE: "Jooga",
VIDEO_QUICK_ACTION_TWO: "Meditaatio",
VIDEO_QUICK_ACTION_THREE: "Kehonpaino",
VIDEO_PLAY: "Toista",
ADD_AS_FAVOURITE: "Lisää suosikiksi",
REMOVE_FAVOURITE: "Poista suosikeista",
VIDEO_INFO: "Info",
INVALID_EMAIL: "Sähköpostiosoite ei ole kirjoitettu oikein",
USER_DISABLED: "Käyttäjätili on deaktivoitu. Ota yhteyttä popupyogaoulu@gmail.com",
USER_NOT_FOUND: "Tämä käyttäjä ei ole vielä Pop Up Yoga Oulun asiakas. Luo tunnukset niin pääset mukaan.",
WRONG_PASSWORD: "Kirjoitit salasanan väärin",
SIGNUP_ARGUMENT_ERROR: "Sähköposti tai salasana puuttuu",
PROFILE_TITLE: "Profiilisivu",
UPDATE_NEEDED: "Sovelluksesi on liian vanha ja se täytyy päivittää",
NO_SUBSCRIPTION_TEXT: "Jos et ole vielä Pop Up Yoga Oulu videopalvelun asiakas. Voit rekisteröityä lähettämällä sähköpostia osoitteeseen popupyogaoulu@gmail.com. Pavelu maksaa 10€ kuukaudessa.",
SERVICE_TERMS_LINK: "Palveluehdot",
SERVICE_TERMS: "Paljonko maksaa, miten veloitetaan, mitä tietoa tallennetaan...",
REQUEST_TO_RESET_PAWSSWORD_DESCRIPTION: "Kirjoita käyttäjätunnuksesi niin lähetämme sinulle sähköpostiin linkin, jonka avulla voit vaihtaa salasanan.",
BACK_BUTTON: "Takaisin",
BACK_TO_VIDEOS: "Takaisin",
VERIFY_EMAIL_ADDRESS: "Vahvista sähköpostiosoitteesi",
RESET_PASSWORD: "Vaihda salasana",
EMAIL_MISMATCH_TEXT: "Sähköpostit eivät ole samanlaiset.",
EMPTY_SCREEN_TITLE: " ",
CONFIRM_LOGOUT: "Oletko varma että haluat kirjautua ulos sovelluksesta? ",
HEY: "Hei",
CLASS_ADDED_DATE: "Tunti on julkaistu",
LOGIN_ACCEPT_TOS: "Kirjautumalla hyväksyt Pop Up Yoga Finlandin",
SIGN_UP_ACCEPT_TOS: "Rekisteröitymällä hyväksyt Pop Up Yoga Finlandin",
LOGIN_ACCEPT_TOS: "Kirjautumalla hyväksyt Pop Up Yoga Finlandin",
START_LOGIN_HERE: "Kirjaudu",
PAYMENT_PITCH_SHORT: "10€/kuukaudessa, voit irtisanoa milloin tahansa. \n Luo tunnukset, vahivsta sähköposti, maksa ja katsele.",
VIDEO_SEARCH_FILTER_TITLE: "Haku",
WHAT_IS_VIDEOSERVICE:" Mikä on Pop Up Yoga videopalvelu?",
WHAT_IS_VIDEOSERVICE_DESC:"Videopalvelu on Pop Upin Porukoiden kotiharjoittelumahdollisuus. Videopalvelustamme löydät runsaasti harjoitusvideoita niin joogaan, pilatekseen, meditaatioon kuin kehonpainoharjoitteluunkin. Videopalvelun tilaajilla on mahdollisuus liittyä omaan suljettuun Facebook-ryhmään jossa lähetämme viikoittain useita Live-tunteja.",
DOES_CLASSES_FIT_FOR_ME:" Sopivatko tunnit minulle?",
DOES_CLASSES_FIR_FOR_ME_DESC:"Kokeneiden opettajiemme johdolla harjoittelet Joogaa ja Pilatesta turvallisesti. Opettajamme antavat tunneilla vaihtoehtoja, jolloin tunnit soveltuvat niin aloitteleville kuin edistyneemmille joogeille. Harjoittelu pohjautuu arvoihimme ilo, yhteenkuuluvuus ja turvallisuus.",
WHERE_CAN_I_WATCH:" Missä voin katsoa?",
WHERE_CAN_I_WATCH_DESC:"Katselu onnistuu kaikilla laitteilla josta löytyy verkkoselain ja internetyhteys. Lisäksi voit ladata iOS ja Android mobiilisovelluksen puhelimeesi tai tabletille, jolloin videokirjasto on sinulla aina mukanasi.",
WHAT_DOES_IT_COST:" Mitä se maksaa?",
WHAT_DOES_IT_COST_DESC:"Voit harjoitella rajattomasti kiinteään kuukausihintaan - 10€/kk. Tilaus laskutetaan kuukausittain automaattisesti.",
HOW_TO_CANCEL_SUBSCRIPTION:" Miten perun tilauksen?",
HOW_TO_CANCEL_SUBSCRIPTION_DESC:"Tilauksen voi irtisanoa milloin vain, maksat silloin vain kuluvan maksukauden (30-päivää) loppuun. Voit perua tilauksen suoraan verkkopalvelusta tai lähettämällä sähköpostia osoitteeseen support@popupyogaoulu.com.",
READ_MORE_ABOUT_VIDEOSERVICE:"Lue Lisää videopalvelusta",
MANAGE_SUBSCRIPTION: "Hallitse videopalvelun tilausta",
CANCEL_SUBSCRIPTION: "Peruuta videopalvelun tilaus sähköpostilla",
INVITE_FRIEND: "Kutsu kaverisi joogaamaan",
TRY_CANCELLING_WITH_EMAIL: "Emme voineed ladata tilauksen hallintasivua. Voit perua videopalvelun tai päivittää maksutietosi sähköpostilla (support@popupyogaoulu.com)"
};

export default strings;
