import React from 'react';
import {StyleSheet, Text, TouchableOpacity } from 'react-native';
import colors from "../config/colors.js";

interface Props {
  label: string;
  onPress: () => void;
}

class PopUpButton extends React.Component<Props> {
  render() {
    const { textStyle, containerStyle, label, onPress } = this.props;
    return (
      <TouchableOpacity style={[styles.container, containerStyle]} onPress={onPress}>
        <Text style={[styles.text, textStyle]}>{label}</Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.POPUP_LOGO_PURPLE,
    marginBottom: 12,
    paddingVertical: 12,
    borderRadius: 4,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.POPUP_LOGO_PINK,
  },
  text: {
    color: colors.WHITE,
    textAlign: "center",
    height: 20,
    fontSize: 18,
  }
});

export default PopUpButton;
