import React from 'react';
import {ImageBackground, Button, Image, StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Firebase, { db } from '../config/Firebase'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import PopUpButton from '../components/PopUpButton'
import * as RootNavigation from '../controllers/RootNavigation';
import metrics, { logowidth } from "../config/metrics"
import * as Analytics from 'expo-firebase-analytics';

interface VideoItemProps {
  id: string;
  url : string;
  title : string;
  duration: string;
  teacher: string;
  teacherImage: string;
  thumbnail_ipad:string;
  class_type : string;
  long_description: string;
  short_description: string;
  is_favourite: boolean;
}

export default class HomeHeader extends React.Component<VideoItemProps>  {

  state = {
    currentVideoUrl: "",
  }

  onPlayMainVideo = (url) => {
//      console.log("HomeHeader.onPlayMainVideo: " + url.toString());
    db.collection('subscribers')
    .doc(Firebase.auth().currentUser.uid)
    .get()
    .then( (doc) => {
      if (doc.exists) {
        if ( doc.data().is_active ) {
          //console.log("User has active subscription");
          Analytics.logEvent('play_video_v1', {
            screen: this.constructor.name,
             id: this.props.id,
             title: this.props.title,
             class_type: this.props.class_type,
             teacher: this.props.teacher,
             is_favourite: this.props.is_favourite ? 'favourite' : 'not_favourite'
           })
           this.setState({currentVideoUrl: url});
           RootNavigation.navigate('PlayWebVideo', {videoUrl: url});
        }
        else {
           //console.log("User doesn't have active subscription");
           Analytics.logEvent('play_video_failed_v1', {
             reason: 'no active subscription',
             message: '',
           })
           // RootNavigation.resetNaviStack()
          RootNavigation.navigate('SubscriptionOffer', {userId:Firebase.auth().currentUser.uid, user_email:Firebase.auth().currentUser.email})
         }
      } else {
         //console.log("No subscriber doc, new customer");
         Analytics.logEvent('play_video_failed_v1', {
           reason: 'subscribers document missing',
           message: '',
         })
         //RootNavigation.resetNaviStack()
          RootNavigation.navigate('SubscriptionOffer', {userId:Firebase.auth().currentUser.uid, user_email:Firebase.auth().currentUser.email})
      }
    })
    .catch( error => {
     //console.log("Error: " + error);
     Analytics.logEvent('error_v1', {
       action: 'play video',
       reason: 'firestore - error when getting subscribers document',
       message: ''
     })
    });
  }

  hasThumbnailImage(){
//    console.log("hasThumbnailImage: " + this.props.thumbnail_ipad);
    if ( this.props.thumbnail_ipad==null || this.props.thumbnail_ipad==="") {
//      console.log("false");
      return false;
    }
    else {
//      console.log("true");
      return true;
    }
  }

  render() {
//    console.log("metrics.DEVICE_HEIGHT: " + metrics.DEVICE_HEIGHT + " metrics.DEVICE_WIDTH: " + metrics.DEVICE_WIDTH);
    const {
      url,
      title,
      duration,
      teacher,
      teacherImage,
      thumbnail_ipad,
      long_description,
      short_description,
     } = this.props;

     return (
       <View style={styles.container}>
         <View style={styles.leftContainer}>
           { true && (
             <Image
               style={styles.videoImage}
               source={this.props.teacherImage}
             />
           )}
           { false && (
             <Image
               style={styles.videoImage}
               source={this.props.thumbnail_ipad}
             />
           )}
         {false && (
           <TouchableOpacity onPress={() => {RootNavigation.navigate('ShowProfile')}}>
             <Image
               style={styles.logoImage}
               source={imagepaths.logo_transparent}
             />
           </TouchableOpacity>)}
           <PopUpButton
             containerStyle={{position: 'absolute',bottom: 0}}
             label={strings.VIDEO_PLAY}
             onPress={this.onPlayMainVideo.bind(this, this.props.url)}
             />
         </View>
         {( metrics.DEVICE_WIDTH<361 || metrics.DEVICE_HEIGHT<365 )  &&
         <View style={styles.rightContainer}>
           <Text style={{color: colors.WHITE, fontSize: 12, paddingBottom: "1%", paddingHorizontal: "3%", fontWeight: 'bold', textAlign: 'left'}}>{this.props.title} - {this.props.teacher} {this.props.duration}min</Text>
           <Text style={{color: colors.WHITE, fontSize: 12, paddingBottom: "1%", paddingHorizontal: "3%", textAlign: 'left'}}>{this.props.long_description}</Text>
         </View>}
         {( metrics.DEVICE_WIDTH>360 && metrics.DEVICE_WIDTH<415)&&
         <View style={styles.rightContainer}>
           <Text style={{color: colors.WHITE, fontSize: 14, paddingBottom: "1%", paddingHorizontal: "3%", fontWeight: 'bold', textAlign: 'left'}}>{this.props.title} - {this.props.teacher} {this.props.duration}min</Text>
           <Text style={{color: colors.WHITE, fontSize: 14, paddingBottom: "1%", paddingHorizontal: "3%", textAlign: 'left'}}>{this.props.long_description}</Text>
         </View>}
         {( metrics.DEVICE_WIDTH>415)&&
         <View style={styles.rightContainer}>
           <Text style={{color: colors.WHITE, fontSize: 20, paddingBottom: "1%", paddingHorizontal: "3%", fontWeight: 'bold', textAlign: 'left'}}>{this.props.title} - {this.props.teacher} {this.props.duration}min</Text>
           <Text style={{color: colors.WHITE, fontSize: 20, paddingBottom: "1%", paddingHorizontal: "3%", textAlign: 'left'}}>{this.props.long_description}</Text>
         </View>}

      </View>
     )
 }
}

const styles = StyleSheet.create({
  container: {
    //Flex:1,
    // width: "100%",
    height: metrics.DEVICE_HEIGHT/3,
    flexDirection: 'row',
  },
  leftContainer: {
    width: "30%",
    height: "100%",
    flexDirection: 'column',
    // backgroundColor: colors.POPUP_CYAN_D,
  },
  videoImage: {
     position: 'absolute',
     bottom: 0,
     left: 0,
     width: "100%",
     height: metrics.DEVICE_HEIGHT/3,
     opacity: 0.8,
  },
  videoAction: {
    height: "20%",
    // color: colors.WHITE,
    fontSize: 16,
    borderColor: "rgba(0,0,255,0.7)",
  },
  rightContainer: {
    width: "70%",
    height: "100%",
    justifyContent: 'flex-end',
    // backgroundColor: colors.BLACK,
  },
  backgroundImage: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: "100%",
    height: metrics.DEVICE_HEIGHT/3,
    opacity: 0.4,
  },
  textContainer: {
    height: "80%",
    backgroundColor: colors.POPUP_LOGO_PURPLE,
  },
 logoImage: {
   marginTop: 40,
   marginLeft: 10,
   width: 75,
   height: 75,
  },
})
//
// const styles = StyleSheet.create({
//   container: {
//     width: "100%",
//     height: metrics.DEVICE_HEIGHT/3,
//     backgroundColor: colors.BLACK,
//   },
//   topPart: {
//     flex: 1,
//     flexDirection: 'row',
//     width: "100%",
//   },
//   middlePart: {
//     flex: 1,
//     width: "100%",
//   },
//   bottomPart: {
//     flex: 1,
//     flexDirection: 'column',
//     width: "100%",
//     justifyContent: 'flex-start',
//     alignItems: 'flex-start',
//   },
//  backgroundImage: {
//    position: 'absolute',
//    bottom: 0,
//    left: 0,
//    width: metrics.DEVICE_HEIGHT/3,
//    height: metrics.DEVICE_HEIGHT/3,
//    opacity: 0.8,
//  },
//  logoImage: {
//    marginTop: 40,
//    marginLeft: 10,
//    width: 75,
//    height: 75,
//   },
//   headerSearchActions: {
//     marginTop: 50,
//     padding: 10,
//     color: colors.WHITE,
//     fontSize: 17,
//   },
//   headerQuickActions: {
//     flex: 1,
//     color: colors.WHITE,
//     fontSize: 16,
//     borderColor: "rgba(0,0,255,0.7)",
//   },
// })
