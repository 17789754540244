import React from 'react'
import {Image, View, Text, StyleSheet, Button, ScrollView } from 'react-native'
import { WebView } from 'react-native-webview';
//TODO: Read service terms from firebase
// import Firebase from '../config/Firebase'
import * as RootNavigation from '../controllers/RootNavigation';
import strings from "../config/strings"
import serviceterms from '../config/serviceterms'
import colors from "../config/colors"
import imagepaths from '../config/imagepaths';

export default class ServiceTermsScreen extends React.Component {

  handleBackButton = () => {
    // console.log("handleBackButton");
//    RootNavigation.navigate('ShowProfile');
    RootNavigation.pop();
  }

  render() {
    return (
      <ScrollView style={styles.container}>
        <Text style={styles.serviceText}>{serviceterms.SERVICE_TERMS}</Text>
        <Button title={strings.BACK_BUTTON} onPress={() => this.handleBackButton()}/>
      </ScrollView>

    );
  }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.WHITE,
    },
    serviceText: {
      fontSize: 13,
      fontWeight: 'bold',
      color: colors.BLACK,
      padding:10,
      alignSelf: "center",
      marginTop: "1%"
    },
    form: {
      flex: 1,
      backgroundColor: colors.WHITE,
      justifyContent: "center",
      width: "80%"
    },
})
