import { Dimensions } from "react-native";

const { height, width } = Dimensions.get("window");

const metrics = {
  DEVICE_WIDTH: width,
  DEVICE_HEIGHT: height
};



export const logowidth = function calculateLogoWidth() {
    var width = metrics.DEVICE_WIDTH/3;
    if ( width > 210) {
      width = 210;
    }
    return width;
  }


export default metrics;
