import React from "react";
import { Divider, KeyboardAvoidingView, ImageBackground,Linking, ScrollView, TouchableOpacity, Dimensions, Image, StyleSheet, Text, View} from 'react-native';
import {createStackNavigator, StackNavigator} from '@react-navigation/stack'
import { LinearGradient } from 'expo-linear-gradient';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateEmail, updatePassword, updateFirstName, updateLastName, signup, getUser } from '../actions/User'
import PopUpButton from '../components/PopUpButton'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import PopUpTextInput from '../components/PopUpTextInput';
import * as RootNavigation from '../controllers/RootNavigation';
import Firebase, { db } from '../config/Firebase'
import * as Analytics from 'expo-firebase-analytics';

class SignUpReduxScreen extends React.Component  {

    widthScale = 1;

    state = {
      learnMoreActive: false,
    };

    constructor() {
      super()
      this.width = Dimensions.get('window').width
      this.height = Dimensions.get('window').height
      this.widthScale = this.calculateImageScaleFactor(Dimensions.get('window').width)
    }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', (e) => {
      const { width, height } = e.window;
      this.widthScale = this.calculateImageScaleFactor(width,height)
      this.width = width;
      this.height = height;
      this.forceUpdate();
    })
  }

  calculateImageScaleFactor = (width,height) => {
    // console.log("widthScale =" + width/800*0.70);
    // console.log("heightScale = " + height/200);
    return width/800*0.70;
  }

  showLoginScreen = () => {
      // console.log("SignUpReduxScreen.showLoginScreen");
      RootNavigation.navigate('Login');
  }

  showServiceTerms = () => {
    // console.log("showServiceTerms");
    RootNavigation.navigate('ShowServiceTerms');
  }


  onLeardMoreToggled(learnMoreActive){
    // console.log("SplashScreen.onLeardMoreToggled to " + !learnMoreActive);
    Analytics.logEvent('toggle_learn_more_v1', {
      screen:this.constructor.name,
      state:!learnMoreActive,
    })
    this.setState({learnMoreActive:!learnMoreActive})
  }

  isLearnMoreActive() {
    let learnMoreActive = this.state.learnMoreActive;
    return learnMoreActive;
  }

  render() {
    const width = this.width;
    const height = this.height;
    var scale = this.widthScale;
    if ( scale > 1.5 ) {
      scale = 1.5
    }
    if (Dimensions.get('window').width< 420  ) {
      return (
        <View style={styles.containerPhonePortrait}>
          <ScrollView  contentContainerStyle={{flex: 1}}>
            <ImageBackground
            style={styles.backgroundImage}
            imageStyle={{resizeMode: 'cover'}}
            source={imagepaths.background}
            >
            <View style={{flex:1}}>
              <View style={styles.containerTopPhonePortrait}>
                <Image
                  style={styles.logoLeftPhonePortrait}
                  source={imagepaths.logo_transparent}
                  />
              </View>
              <View style={styles.containerPhonePortraitBottom}>
                <KeyboardAvoidingView style={styles.formPhonePortrait}>
                  <Text style={{fontFamily: 'Arial', fontSize: 30, color: colors.WHITE,fontWeight: 'bold', textAlign: 'center',marginBottom: "2%"}}></Text>
                  <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.WHITE, textAlign: 'center', marginBottom: "2%"}}>{strings.SIGNUP_INFO_TEXT_SHORT}</Text>
                  <Text style={{fontSize: 18, color: colors.WHITE,  paddingBottom: "2%", fontWeight: 'bold', textAlign:'left'}}> {strings.START_CREATING_ACCOUNT} </Text>
                  <PopUpTextInput
                    autoFocus={false}
                    value={this.props.user.firstname}
                    onChangeText={firstname => this.props.updateFirstName(firstname)}
                    placeholder={strings.NAME_PLACEHOLDER}
                    />
                  <PopUpTextInput
                    autoFocus={false}
                    value={this.props.user.email}
                    onChangeText={email => this.props.updateEmail(email)}
                    placeholder={strings.EMAIL_PLACEHOLDER}
                    />
                  <PopUpTextInput
                    secureTextEntry={true}
                    autoFocus={false}
                    value={this.props.user.password}
                    onChangeText={password => this.props.updatePassword(password)}
                    placeholder={strings.CREATE_PASSWORD_PLACEHOLDER}
                    />
                  <PopUpButton
                    textStyle={{fontSize:16}}
                    containerStyle={{width:"100%",alignSelf:'center'}}
                    label="Luo tunnus ja liity videoporukkaan"
                    onPress={() => this.props.signup()}
                    />
                  <TouchableOpacity style={{width: "100%", alignSelf:'center', }}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end'}}>
                      <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                      <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                    </View>
                  </TouchableOpacity>
                  {this.isLearnMoreActive() && (
                    <View style={styles.learnMoreformPhonePortrait}>
                      <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}</Text>
                      <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}</Text>
                      <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}</Text>
                      <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}</Text>
                      <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}</Text>
                      <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}</Text>
                      <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}</Text>
                      <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}</Text>
                      <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}</Text>
                      <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                      </Text>

                      <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom:"2%"}}  onPress={() => this.showLoginScreen()}>
                        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                          <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
                        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <Text style={{fontSize: 10, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                          <Text style={{fontSize: 10, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
                {!this.isLearnMoreActive() && (
                  <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom:"2%"}}  onPress={() => this.showLoginScreen()}>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "1%"}}>
                      <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER}</Text>
                      <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.LOGIN} </Text>
                    </View>
                  </TouchableOpacity>)}
                {!this.isLearnMoreActive() && (
                  <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom:"2%"}}  onPress={() => this.showServiceTerms()}>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "2%"}}>
                      <Text style={{fontSize: 10, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                      <Text style={{fontSize: 10, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                    </View>
                </TouchableOpacity>)}
              </KeyboardAvoidingView>
              </View>
             </View>
           </ImageBackground>
        </ScrollView>
        </View>
      );
    }
    else if (Dimensions.get('window').height< 365 ) {
      return (
        <View style={styles.container}>
          <ScrollView  contentContainerStyle={{flex: 1}}>
            <ImageBackground
              style={styles.backgroundImage}
              imageStyle={{resizeMode: 'cover'}}
              source={imagepaths.background}
              >
              <View style={{flex:1, flexDirection: 'row'}}>
                <View style={styles.firstPartPhoneLanscape}>
                 <Image
                     style={styles.logoLeftPhoneLanscape}
                       source={imagepaths.logo_transparent}
                     />
               </View>
               <View style={styles.secondPartPhoneLanscape}>
                 <KeyboardAvoidingView
                   style={styles.formPhoneLanscape} behavior="padding">
                   <Text style={{fontSize: 16, color: colors.WHITE, paddingTop: "5%",paddingBottom: "1%", fontWeight: 'bold', textAlign:'left'}}> {strings.START_CREATING_ACCOUNT} </Text>
                    <PopUpTextInput style={{height:'20',width:'90%', alignSelf: 'center'}}
                      value={this.props.user.firstname}
                      onChangeText={firstname => this.props.updateFirstName(firstname)}
                      placeholder={strings.NAME_PLACEHOLDER}
                    />
                   <PopUpTextInput
                     style={{height:'20',width:'90%', alignSelf: 'center'}}
                     value={this.props.user.email}
                     onChangeText={email => this.props.updateEmail(email)}
                     placeholder={strings.EMAIL_PLACEHOLDER}
                   />
                   <PopUpTextInput
                     style={{height:'20',width:'90%', alignSelf: 'center'}}
                     secureTextEntry={true}
                     value={this.props.user.password}
                     onChangeText={password => this.props.updatePassword(password)}
                     placeholder={strings.CREATE_PASSWORD_PLACEHOLDER}
                   />
                   <PopUpButton
                    textStyle={{fontSize:14}}
                    containerStyle={{width: "90%",alignSelf:'center' }}
                    label={strings.SIGNUP_AND_JOIN_POP_UP_COMMUNITY}
                     onPress={() => this.props.signup()}
                   />
                   <TouchableOpacity style={{width: "100%", alignSelf:'center', }}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                     <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end'}}>
                       <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                       <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                     </View>
                   </TouchableOpacity>
                   {this.isLearnMoreActive() && (
                     <View style={styles.learnMoreformPhonePortrait}>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}</Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}</Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}</Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}</Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}</Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}</Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}</Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}</Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}</Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                       </Text>

                       <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom:"2%"}}  onPress={() => this.showLoginScreen()}>
                         <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                           <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                           <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                         </View>
                       </TouchableOpacity>
                       <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
                         <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                           <Text style={{fontSize: 12, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                           <Text style={{fontSize: 12, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                         </View>
                       </TouchableOpacity>
                     </View>
                   )}
                </KeyboardAvoidingView>
               {!this.isLearnMoreActive() && (
                 <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "4%"}}  onPress={() => this.showServiceTerms()}>
                   <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                     <Text style={{fontSize: 12, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                     <Text style={{fontSize: 12, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                   </View>
                 </TouchableOpacity>)}
             </View>
               <View style={styles.thirdPartPhoneLanscape}>
                 <PopUpButton
                   textStyle={{fontSize:12}}
                    containerStyle={{width:100,alignSelf:'center'}}
                    label={strings.LOGIN}
                     onPress={this.showLoginScreen}
                   />
               </View>
             </View>
            </ImageBackground>
          </ScrollView>
        </View>
      );
    }
    else if (Dimensions.get('window').height < 1000 ) {
      return (
        <View style={styles.container}>
         <ScrollView  contentContainerStyle={{flex: 1}}>
          <ImageBackground
            style={styles.backgroundImage}
            imageStyle={{resizeMode: 'cover'}}
            source={imagepaths.background}
            >
          <View style={{flex:1, flexDirection: 'row'}}>
            <View style={styles.firstPart}>
             <Image
               style={styles.logoLeft}
                 source={imagepaths.logo_transparent}
               />
           </View>
             <View style={styles.secondPart}>
               <Image
                   style={[styles.opeImageSmall,{width: 800*scale, height:200*scale}]}
                     source={imagepaths.opet_background}
                   />
               <KeyboardAvoidingView style={styles.form} behavior="padding">

               <Text style={{fontFamily: 'Arial', fontSize: 30, color: colors.WHITE,fontWeight: 'bold', textAlign: 'center',marginBottom: "2%"}}></Text>
                 <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.WHITE, textAlign: 'center', marginBottom: "2%"}}>{strings.SIGNUP_INFO_TEXT_SHORT}</Text>
                 <Text style={{fontSize: 18, color: colors.WHITE,  paddingBottom: "2%", fontWeight: 'bold', textAlign:'left'}}> {strings.START_CREATING_ACCOUNT} </Text>
                    <PopUpTextInput
                      width="50%"
                      value={this.props.user.firstname}
                      onChangeText={firstname => this.props.updateFirstName(firstname)}
                      placeholder={strings.NAME_PLACEHOLDER}
                    />
                   <PopUpTextInput
                     width="50%"
                     value={this.props.user.email}
                     onChangeText={email => this.props.updateEmail(email)}
                     placeholder={strings.EMAIL_PLACEHOLDER}
                     />
                     <PopUpTextInput
                       width="50%"
                       secureTextEntry={true}
                       value={this.props.user.password}
                       onChangeText={password => this.props.updatePassword(password)}
                       placeholder={strings.CREATE_PASSWORD_PLACEHOLDER}
                     />
                      <PopUpButton
                        label={strings.SIGNUP_AND_JOIN_POP_UP_COMMUNITY}
                         onPress={() => this.props.signup()}
                       />
                       <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 20,marginBottom: "2%"}}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                         <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                           <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                           <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                         </View>
                     </TouchableOpacity>
                     {this.isLearnMoreActive() && (
                       <View style={styles.learnMoreformPhonePortrait}>
                         <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}</Text>
                         <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}</Text>
                         <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}</Text>
                         <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}</Text>
                         <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}</Text>
                         <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}</Text>
                         <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}</Text>
                         <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}</Text>
                         <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}</Text>
                         <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                         </Text>

                         <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom:"2%"}}  onPress={() => this.showLoginScreen()}>
                           <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                             <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                             <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                           </View>
                         </TouchableOpacity>
                         <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
                           <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                             <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                             <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                           </View>
                         </TouchableOpacity>
                       </View>
                     )}
                   </KeyboardAvoidingView>
                   {!this.isLearnMoreActive() && (
                     <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom:"2%"}}  onPress={() => this.showLoginScreen()}>
                        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                          <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                        </View>
                    </TouchableOpacity>)}
                    {!this.isLearnMoreActive() && (
                      <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
                        <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                          <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                        </View>
                      </TouchableOpacity>)}
              </View>
              <View style={styles.thirdPart}>
              </View>
           </View>
         </ImageBackground>
         </ScrollView>
        </View>
      );
    } else {
      return (
          <View style={styles.container}>
            <View style={styles.firstPart}>
              <Image
                style={styles.logoLeft}
                source={imagepaths.logo_transparent}
                />
            </View>
            <ScrollView contentContainerStyle={{flex: 1}}>
              <View style={styles.secondPart}>
                <Image
                  style={[styles.opeImageLarge,{width: 800*scale, height:200*scale}]}
                  source={imagepaths.opet_background}
                  />
                <KeyboardAvoidingView style={styles.form} behavior="padding">
                  <Text style={{fontFamily: 'Arial', fontSize: 46, color: colors.WHITE,fontWeight: 'bold', textAlign: 'center',marginBottom: "5%"}}></Text>
                  <Text style={{fontFamily: 'Arial', fontSize: 30, color: colors.WHITE, textAlign: 'center', marginBottom: "5%"}}>{strings.SIGNUP_INFO_TEXT}</Text>
                  <Text style={{fontSize: 18, color: colors.WHITE,  paddingBottom: "2%", fontWeight: 'bold', textAlign:'left'}}> {strings.START_CREATING_ACCOUNT} </Text>
                  <PopUpTextInput
                    width="50%"
                    value={this.props.user.firstname}
                    onChangeText={firstname => this.props.updateFirstName(firstname)}
                    placeholder={strings.NAME_PLACEHOLDER}
                  />
                 <PopUpTextInput
                   width="50%"
                   value={this.props.user.email}
                   onChangeText={email => this.props.updateEmail(email)}
                   placeholder={strings.EMAIL_PLACEHOLDER}
                   />
                   <PopUpTextInput
                     width="50%"
                     secureTextEntry={true}
                     value={this.props.user.password}
                     onChangeText={password => this.props.updatePassword(password)}
                     placeholder={strings.CREATE_PASSWORD_PLACEHOLDER}
                   />
                  <PopUpButton
                    label={strings.SIGNUP_AND_JOIN_POP_UP_COMMUNITY}
                     onPress={() => this.props.signup()}
                   />
                 <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 20,marginBottom: "2%"}}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                   <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                     <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                     <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                   </View>
               </TouchableOpacity>
               {this.isLearnMoreActive() && (
                 <View style={styles.learnMoreformPhonePortrait}>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                   </Text>

                   <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                     <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                       <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                       <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                     </View>
                   </TouchableOpacity>
                 </View>
               )}
               {!this.isLearnMoreActive() && (
                 <TouchableOpacity style={{width: "100%", alignSelf:'center', marginTop: "8%",paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                  <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                    <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                    <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                  </View>
                </TouchableOpacity>)}
                  <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                      <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                      <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                    </View>
                  </TouchableOpacity>
               </KeyboardAvoidingView>
             </View>
             <View style={styles.thirdPart}>
            </View>
          </ScrollView>
        </View>
      );
    }
  }
}


  const styles = StyleSheet.create ({
    containerPhonePortrait: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: colors.BLACK,
    },
    containerTopPhonePortrait: {
      // backgroundColor: colors.BLACK,
      width: "100%",
      height: "25%",
    },
    logoLeftPhonePortrait: {
      marginTop: "3%",
      marginLeft: "3%",
      width: 50,
      height: 50,
    },
    containerPhonePortraitBottom: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "100%",
      height: "75%",
      paddingBottom: "5%"
    },
    opeImagePhonePortrait: {
    alignSelf: 'center',
    marginTop: "5%",
    marginBottom: "10%",
    transform: [
      { rotateX: '25deg'},
    ],
    opacity: 0.7
    },
    formPhonePortrait: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "85%",
      // backgroundColor: colors.BLACK
    },
    backgroundImage: {
      flex: 1,
      // resizeMode: "repeat",
      justifyContent: "center"
    // alignSelf: 'center',
    // marginTop: "10%",
    // marginBottom: "10%",
    // transform: [
    //   { rotateX: '25deg'},
    // ],
    // opacity: 0.7
    },
    learnMoreformPhonePortrait: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      paddingTop: "3%"
      // backgroundColor: colors.BLACK
    },
    learnMoreformTitlePhonePortrait:
    {
      fontFamily: 'Arial',
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.LIGHT_GREY,
      textAlign: 'left',
      padding: 3,
      marginBottom: "2%",
      marginLeft: "5%",
      marginRight: "0%",
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: colors.LIGHT_GREY,
    },
    learnMoreformDescriptionPhonePortrait: {
      fontFamily: 'Arial',
      fontSize: 14,
      color: colors.LIGHT_GREY,
      textAlign: 'left',
      padding: 10,
      marginBottom: "5%",
      marginLeft: "5%",
      marginRight: "0%",
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: colors.LIGHT_GREY,
    },
    firstPartPhoneLanscape: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "15%",
    },
    secondPartPhoneLanscape: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      alignItems: 'center',
      width: "70%",
    },
    thirdPartPhoneLanscape: {
      // backgroundColor: colors.BLACK,
      justifyContent:'flex-start',
      alignItems: 'center',
      paddingTop: "3%",
      width: "15%",
    },
    formPhoneLanscape: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      // backgroundColor: colors.BLACK
    },
   logoLeftPhoneLanscape: {
     position: 'absolute',
     top:"5%",
     left: "25%",
     width: 60,
     height: 60,
    },
    opeImageSmallPhoneLanscape: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      marginTop: "3%",
      // width: "80%",
      // height: 216,
      transform: [
      //   { perspective: 850 },
      //   { translateX: - Dimensions.get('window').width * 0.24 },
        { rotateX: '25deg'},
        // { rotateZ: '1deg'}
      ],
      opacity: 0.5
   },
      container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: colors.BLACK,
      },
      firstPart: {
        // backgroundColor: colors.BLACK,
        justifyContent: "center",
        width: "15%",
      },
      secondPart: {
        // backgroundColor: colors.BLACK,
        justifyContent: "center",
        alignItems: 'center',
        width: "70%",
      },
      thirdPart: {
        // backgroundColor: colors.BLACK,
        justifyContent:'flex-start',
        alignItems: 'center',
        // paddingTop: 30,
        width: "15%",
      },
      form: {
        flex: 1,
        alignSelf: "center",
        justifyContent: 'flex-start',
        width: "100%",
        // backgroundColor: colors.BLACK
      },
     logoLeft: {
       position: 'absolute',
       top:20,
       left: "15%",
       width: 120,
       height: 120,
      },
      opeImageSmall: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "5%",
        // width: "80%",
        // height: 216,
        // opacity: 0.9,
        transform: [
        //   { perspective: 850 },
        //   { translateX: - Dimensions.get('window').width * 0.24 },
          { rotateX: '25deg'},
          // { rotateZ: '1deg'}
        ],
        opacity: 0.7
     },
      opeImageLarge: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "10%",
        // width: "80%",
        // height: 216*1.3,
        opacity: 0.9,
        transform: [
        //   { perspective: 850 },
        //   { translateX: - Dimensions.get('window').width * 0.24 },
          { rotateX: '25deg'},
          // { rotateZ: '1deg'}
        ],
        opacity: 0.7
     },
  });



const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateEmail, updatePassword, signup, getUser, updateFirstName, updateLastName, }, dispatch)
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUpReduxScreen)
