import React, { Component } from 'react';
import { WebView } from 'react-native-webview';
import { Video } from 'expo-av';
import {Button, View, StyleSheet, ActivityIndicator } from 'react-native'
import * as RootNavigation from '../controllers/RootNavigation';
import strings from '../config/strings';
import colors from '../config/colors';
import Iframe from 'react-iframe'

interface Props {
  label: string;
}

export default class WebVideoPlayer extends Component<Props>  {

  onBackToVideos() {
//    console.log("onBackToVideos");
    RootNavigation.navigate('ShowHomeScreen', {userId: null})
  }

  render() {
    const videoUrl = "https://player.vimeo.com/video/" + this.props.videoUrl
   // console.log("Playing " + videoUrl);
    return (
      <View style={styles.container}>
        <Iframe url={videoUrl}
                width="100%"
                height="100%"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"/>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.BLACK,
  },
   sectionHeader: {
     width: "100%",
     height: "10%",
     alignItems: 'flex-start',
     justifyContent: 'flex-end',
     borderWidth: StyleSheet.hairlineWidth,
     borderColor: colors.BLACK,
     backgroundColor: colors.BLACK,
   }
})
