import * as React from 'react';
import { StackActions, NavigationActions } from '@react-navigation/native';

export const navigationRef = React.createRef();

// export const resetAction = StackActions.reset({
// //  index:0,
// //  actions: [NavigationActions.navigate({ routeName: 'ShowSplash'})]
// });

export function resetNaviStack() {
  // console.log("resetNaviStack");
  var currentState = navigationRef.current.getRootState();
  navigationRef.current?.dispatch(StackActions.pop(currentState.index));
  // navigationRef.current?.dispatch(resetAction);
}

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

export function pop(...args) {
  navigationRef.current?.dispatch(StackActions.pop(...args));
}

export function getCurrentRouteName() {
  // console.log(navigationRef.current.getRootState());
  var currentState = navigationRef.current.getRootState();
  //console.log("current route name is " + currentState.routes[currentState.index].name);
  return currentState.routes[currentState.index].name;
}
