import React from "react";
import { KeyboardAvoidingView, Linking, ScrollView, TouchableOpacity, Dimensions, Image, StyleSheet, Text, View} from 'react-native';
import {createStackNavigator, StackNavigator} from '@react-navigation/stack'
import { LinearGradient } from 'expo-linear-gradient';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateEmail, updatePassword, login, getUser } from '../actions/User'
import PopUpButton from '../components/PopUpButton'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import PopUpTextInput from '../components/PopUpTextInput';
import * as RootNavigation from '../controllers/RootNavigation';
import Firebase, { db } from '../config/Firebase'

class LoginWebScreen extends React.Component  {

  width  = 0;
  height = 0;

  constructor() {
    super()
    this.width = Dimensions.get('window').width
    this.height = Dimensions.get('window').height
  }

  componentDidMount = async () => {
    Dimensions.addEventListener('change', (e) => {
      // console.log("update " + e.window.width);
      const { width, height } = e.window;
      this.width = width;
      this.height = height;
      this.forceUpdate();
    })
  }

  onForgotPassword() {
    // console.log("onForgotPassword");
    RootNavigation.navigate('ShowForgotPasswordScreen')
  }

  onSignUpPressed = () => {
      // console.log("LoginWebScreen.onSignUpPressed");
      // Linking.openURL('https://popupyogaoulu.com/joogavideot/')
      RootNavigation.navigate('SignUp');
  }

  showLoginScreen = () => {
      // console.log("LoginWebScreen.showLoginScreen");
      RootNavigation.navigate('Login');
  }

  showServiceTerms = () => {
    // console.log("showServiceTerms");
    RootNavigation.navigate('ShowServiceTerms');
  }


  render() {
    const width = this.width;
    const height = this.height;
    if (this.width< 420  ) {

      return (
        <View style={styles.containerPhonePortrait}>
          <View style={styles.containerTopPhonePortrait}>
            <Image
              style={styles.logoLeftPhonePortrait}
              source={imagepaths.logo_transparent}
              />
          </View>
          <View style={styles.containerPhonePortraitBottom}>
            <KeyboardAvoidingView style={styles.formPhonePortrait}>
              <Text style={{fontFamily: 'Arial', fontSize: 30, color: colors.WHITE,fontWeight: 'bold', textAlign: 'center',marginBottom: "2%"}}></Text>
              <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.WHITE, textAlign: 'center', marginBottom: "2%"}}></Text>
              <Text style={{fontSize: 18, color: colors.WHITE,  paddingBottom: "2%", fontWeight: 'bold', textAlign:'left'}}> {strings.LOGIN} </Text>
                <PopUpTextInput
                  value={this.props.user.email}
                  onChangeText={email => this.props.updateEmail(email)}
                  placeholder={strings.ACCOUNT_PLACEHOLDER}
                />
                <PopUpTextInput
                  secureTextEntry={true}
                  value={this.props.user.password}
                  onChangeText={password => this.props.updatePassword(password)}
                  placeholder={strings.PASSWORD_PLACEHOLDER}
                />
                <PopUpButton
                  textStyle={{fontSize:16}}
                  containerStyle={{width:"100%",alignSelf:'center'}}
                  label={strings.LOGIN}
                   onPress={() => this.props.login()}
                 />
            </KeyboardAvoidingView>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.onSignUpPressed()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "1%"}}>
                <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>Uusi Asiakas?</Text>
                <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.CREATE_ACCOUNT} </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{width: "100%", alignSelf:'center'}}  onPress={() => this.showServiceTerms()}>
              <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',marginTop: "2%"}}>
                <Text style={{fontSize: 10, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.SIGN_UP_ACCEPT_TOS} </Text>
                <Text style={{fontSize: 10, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
              </View>
            </TouchableOpacity>
           </View>
        </View>
      );
    }
    else if (this.height< 365) {
      return (
        <View style={styles.container}>
          <View style={styles.firstPartPhoneLanscape}>
         <Image
             style={styles.logoLeftPhoneLanscape}
               source={imagepaths.logo_transparent}
             />
           </View>
           <View style={styles.secondPartPhoneLanscape}>
             <KeyboardAvoidingView style={styles.formPhoneLanscape} behavior="padding">
              <Text style={{fontSize: 16, color: colors.WHITE, paddingTop: "5%",paddingBottom: "2%", fontWeight: 'bold', textAlign:'left'}}> {strings.LOGIN} </Text>
               <PopUpTextInput
                 width="50%"
                 value={this.props.user.email}
                 onChangeText={email => this.props.updateEmail(email)}
                 placeholder={strings.ACCOUNT_PLACEHOLDER}
               />
               <PopUpTextInput
                 width="50%"
                 secureTextEntry={true}
                 value={this.props.user.password}
                 onChangeText={password => this.props.updatePassword(password)}
                 placeholder={strings.PASSWORD_PLACEHOLDER}
               />
               <PopUpButton
                 textStyle={{fontSize: 16}}
                 label={strings.LOGIN}
                  onPress={() => this.props.login()}
                />
                <TouchableOpacity style={{width: "100%", padding:"1%", alignSelf:'center'}}  onPress={() => this.onSignUpPressed()}>
                  <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                    <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, fontWeight: 'bold', textAlign: 'center'}}> Uusi Asiakas </Text>
                    <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.CREATE_ACCOUNT} </Text>
                  </View>
                </TouchableOpacity>
              </KeyboardAvoidingView>
              <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                  <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.LOGIN_ACCEPT_TOS} </Text>
                  <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                </View>
              </TouchableOpacity>
           </View>
           <View style={styles.thirdPartPhoneLanscape}>
             <PopUpButton
              textStyle={{fontSize: 12}}
               containerStyle={{width: 100 }}
                label={strings.FORGOT_PASSWORD}
                 onPress={this.onForgotPassword}
               />
            </View>
        </View>
      );
    }
    else if (this.height < 820 ) {
      return (
        <View style={styles.container}>
          <View style={styles.firstPart}>
         <Image
             style={styles.logoLeft}
               source={imagepaths.logo_transparent}
             />
           </View>
           <View style={styles.secondPart}>
             <Image
                 style={styles.opeImageSmall}
                   source={imagepaths.opet_background}
                 />
             <KeyboardAvoidingView style={styles.form} behavior="padding">
               <Text style={{fontFamily: 'Arial', fontSize: 26, color: colors.POPUP_LOGO_PINK, textAlign: 'center', marginBottom: "1%"}}> {strings.LOGIN_SCREEN_TITLE}</Text>
               <Text style={{fontFamily: 'Arial', fontSize: 26, color: colors.POPUP_LOGO_PINK, textAlign: 'center', marginBottom: "3%"}}> Pop Up Yoga Videopalveluun</Text>
               <PopUpTextInput
                 width="50%"
                 value={this.props.user.email}
                 onChangeText={email => this.props.updateEmail(email)}
                 placeholder={strings.ACCOUNT_PLACEHOLDER}
               />
               <PopUpTextInput
                 width="50%"
                 secureTextEntry={true}
                 value={this.props.user.password}
                 onChangeText={password => this.props.updatePassword(password)}
                 placeholder={strings.PASSWORD_PLACEHOLDER}
               />
               <PopUpButton
                 label={strings.LOGIN}
                  onPress={() => this.props.login()}
                />
              <Text style={{fontSize: 18, color: colors.WHITE,  paddingBottom: "1%", paddingTop:"1%", opacity: 0.7, fontWeight: 'bold', textAlign: 'center'}}> Uusi Asiakas? </Text>
                 <TouchableOpacity style={{width: "100%", padding:"1%", alignSelf:'center'}}  onPress={() => this.onSignUpPressed()}>
                   <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.LEARN_HOW_TO_JOIN} </Text>
                 </TouchableOpacity>
              </KeyboardAvoidingView>
              <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: "2%"}}  onPress={() => this.showServiceTerms()}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                  <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.LOGIN_ACCEPT_TOS} </Text>
                    <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                </View>
              </TouchableOpacity>
           </View>
           <View style={styles.thirdPart}>
             <PopUpButton
              textStyle={{fontSize: 16}}
               containerStyle={{width: 150 }}
                label={strings.FORGOT_PASSWORD}
                 onPress={this.onForgotPassword}
               />
            </View>
        </View>
      );
    } else {
      return (
        <View style={styles.container}>
          <View style={styles.firstPart}>
         <Image
             style={styles.logoLeft}
               source={imagepaths.logo_transparent}
             />
           </View>
           <View style={styles.secondPart}>
             <Image
                 style={styles.opeImageLarge}
                   source={imagepaths.opet_background}
                 />
             <KeyboardAvoidingView style={styles.form} behavior="padding">
             <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.POPUP_LOGO_PINK, textAlign: 'center', marginBottom: 10}}> </Text>
             <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.POPUP_LOGO_PINK, textAlign: 'center', marginBottom: "15%",}}>Pop Up Yoga Videopalvelu</Text>
             <PopUpTextInput
               width="50%"
               value={this.props.user.email}
               onChangeText={email => this.props.updateEmail(email)}
               placeholder={strings.ACCOUNT_PLACEHOLDER}
             />
             <PopUpTextInput
               width="50%"
               secureTextEntry={true}
               value={this.props.user.password}
               onChangeText={password => this.props.updatePassword(password)}
               placeholder={strings.PASSWORD_PLACEHOLDER}
             />
             <PopUpButton
               label={strings.LOGIN}
                onPress={() => this.props.login()}
              />
              <Text style={{fontSize: 18, color: colors.WHITE,  paddingBottom: 5, paddingTop:35, opacity: 0.7, fontWeight: 'bold', textAlign: 'center'}}> Uusi Asiakas? </Text>
                 <TouchableOpacity style={{width: "100%", padding:5, alignSelf:'center'}}  onPress={() => this.onSignUpPressed()}>
                   <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.LEARN_HOW_TO_JOIN} </Text>
                 </TouchableOpacity>
              </KeyboardAvoidingView>
              <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 10}}  onPress={() => this.showServiceTerms()}>
                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                  <Text style={{fontSize: 16, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.LOGIN_ACCEPT_TOS} </Text>
                    <Text style={{fontSize: 16, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.SERVICE_TERMS_LINK} </Text>
                </View>
              </TouchableOpacity>
           </View>
           <View style={styles.thirdPart}>
             <PopUpButton
              textStyle={{fontSize: 16}}
               containerStyle={{width: 150 }}
                label={strings.FORGOT_PASSWORD}
                 onPress={this.onForgotPassword}
               />
            </View>
        </View>
      );
    }
  }
}


  const styles = StyleSheet.create ({

      containerPhonePortrait: {
        flex: 1,
        flexDirection: 'column',
      },
      containerTopPhonePortrait: {
        backgroundColor: colors.BLACK,
        width: "100%",
        height: "20%",
      },
      logoLeftPhonePortrait: {
        marginTop: "3%",
        marginLeft: "3%",
        width: 50,
        height: 50,
      },
      containerPhonePortraitBottom: {
        backgroundColor: colors.BLACK,
        justifyContent: "center",
        width: "100%",
        height: "80%",
        paddingBottom: "5%"
      },
      opeImagePhonePortrait: {
      alignSelf: 'center',
      marginTop: "5%",
      marginBottom: "10%",
      transform: [
        { rotateX: '25deg'},
      ],
      opacity: 0.7
      },
      formPhonePortrait: {
        flex: 1,
        alignSelf: "center",
        justifyContent: 'flex-start',
        width: "85%",
        backgroundColor: colors.BLACK
      },
       containerPhoneLanscape: {
         flex: 1,
         flexDirection: 'column',
       },
      firstPartPhoneLanscape: {
        backgroundColor: colors.BLACK,
        justifyContent: "center",
        width: "15%",
      },
      secondPartPhoneLanscape: {
        backgroundColor: colors.BLACK,
        justifyContent: "center",
        alignItems: 'center',
        width: "70%",
      },
      thirdPartPhoneLanscape: {
        backgroundColor: colors.BLACK,
        justifyContent:'flex-start',
        alignItems: 'center',
        paddingTop: "3%",
        width: "15%",
      },
      formPhoneLanscape: {
        flex: 1,
        alignSelf: "center",
        justifyContent: 'flex-start',
        width: "100%",
        backgroundColor: colors.BLACK
      },
     logoLeftPhoneLanscape: {
       position: 'absolute',
       top:"5%",
       left: "25%",
       width: 60,
       height: 60,
      },
      opeImageSmallPhoneLanscape: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginTop: "3%",
        // width: "80%",
        // height: 216,
        transform: [
        //   { perspective: 850 },
        //   { translateX: - Dimensions.get('window').width * 0.24 },
          { rotateX: '25deg'},
          // { rotateZ: '1deg'}
        ],
        opacity: 0.5
     },
      container: {
        flex: 1,
        flexDirection: 'row',
      },
      firstPart: {
        backgroundColor: colors.BLACK,
        justifyContent: "center",
        width: "15%",
      },
      secondPart: {
        backgroundColor: colors.BLACK,
        justifyContent: "center",
        alignItems: 'center',
        width: "70%",
      },
      thirdPart: {
        backgroundColor: colors.BLACK,
        justifyContent:'flex-start',
        alignItems: 'center',
        paddingTop: 30,
        width: "15%",
      },
      form: {
        flex: 1,
        alignSelf: "center",
        justifyContent: 'flex-start',
        width: "100%",
        backgroundColor: colors.BLACK
      },
     logoLeft: {
       position: 'absolute',
       top:20,
       left: "15%",
       width: 120,
       height: 120,
      },
      opeImageSmall: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "5%",
        width: "80%",
        height: 216,
        opacity: 0.9,
        transform: [
        //   { perspective: 850 },
        //   { translateX: - Dimensions.get('window').width * 0.24 },
          { rotateX: '25deg'},
          // { rotateZ: '1deg'}
        ],
        opacity: 0.7
     },
      opeImageLarge: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "10%",
        width: "80%",
        height: 216*1.3,
        opacity: 0.9,
        transform: [
        //   { perspective: 850 },
        //   { translateX: - Dimensions.get('window').width * 0.24 },
          { rotateX: '25deg'},
          // { rotateZ: '1deg'}
        ],
        opacity: 0.7
     },
  });


const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateEmail, updatePassword, login, getUser }, dispatch)
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginWebScreen)
