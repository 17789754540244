import React from "react";
import { ImageBackground, KeyboardAvoidingView, Linking, ScrollView, TouchableOpacity, Dimensions, Image, StyleSheet, Text, View} from 'react-native';
import {createStackNavigator, StackNavigator} from '@react-navigation/stack'
import { LinearGradient } from 'expo-linear-gradient';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateEmail, updatePassword, signup, getUser } from '../actions/User'
import PopUpButton from '../components/PopUpButton'
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import PopUpTextInput from '../components/PopUpTextInput';
import * as RootNavigation from '../controllers/RootNavigation';
import Firebase, { db } from '../config/Firebase'
import metrics, { logowidth } from "../config/metrics"
import Constants from 'expo-constants'
import * as Analytics from 'expo-firebase-analytics';

class SplashScreen extends React.Component  {

  widthScale = 1;

  state = {
    learnMoreActive: false
  };

  constructor() {
    super()
    this.width = Dimensions.get('window').width
    this.height = Dimensions.get('window').height
    this.widthScale = this.calculateImageScaleFactor(Dimensions.get('window').width)
  }

  handleUrl = async (url) => {
    // console.log("SplashScreen handleUrl " + url);
    if ( url ==Constants.manifest.extra.app_baseurl + "paymentSuccess" && Firebase.auth().currentUser) {
      // console.log("Handling paymentSuccess url");
      //TODO: This is never fired as home screen is loaded before
      Analytics.logEvent('subscription_v1', {
        result: 'success',
        screen: this.constructor.name,
        reason: 'returned from payment page with success',
        message: 'might fire duplicate events',
      })
      Firebase.auth().currentUser.reload().then(function() {
        //Do nothing here
      }).catch(function(error){
        // console.log("ERROR: " + error);
        if ( error.code == "auth/user-disabled") {
          //This will cause logout and resetting navi stack
        }
      })
    } else if ( url ==Constants.manifest.extra.app_baseurl + "paymentCancelled") {
      // console.log("Handling paymentCancelled url");
      Analytics.logEvent('subscription_v1', {
        result: 'in_progress',
        screen: this.constructor.name,
        reason: 'payment cancelled',
        message: 'might fire duplicate events',
      })
      alert("Maksu on peruutettu, sinua ei ole veloitettu")
    }
  }

  calculateImageScaleFactor = (width,height) => {
    return width/800*0.70;
  }

  componentDidMount = async () => {
    // console.log("Splash Screen componentDidMount");
    Dimensions.addEventListener('change', (e) => {
      const { width, height } = e.window;
      this.widthScale = this.calculateImageScaleFactor(width,height)
      this.width = width;
      this.height = height;
      this.forceUpdate();
    })

    const url = await Linking.getInitialURL();
    if (url) {
      this.handleUrl(url);
    }

    Linking.addEventListener('url', ({ url }) => {
      this.handleUrl(url);
    });

    Firebase.auth().onAuthStateChanged( user => {
      if (user) {
        //Getting user here to ensure it's fetched always
        if (this.props.user.retries == null) {
          // console.log("User is not populated fully");
          this.props.getUser(user.uid)
        }
        if(this.props.user != null) {
          if ( Firebase.auth().currentUser.emailVerified) {
            //Check subscription
            db.collection('subscribers')
            .doc(Firebase.auth().currentUser.uid)
            .get()
            .then( (doc) => {
              if (doc.exists) {
                if ( doc.data().is_active ) {
                  // console.log("User has active subscription");
                  Analytics.logEvent('login_v1', {
                    result: 'success',
                    screen: this.constructor.name,
                    reason: '',
                    message: '',
                  })
                  RootNavigation.navigate('ShowHomeScreen', {userId: user.email})
                } else {
                  // console.log("User doesn't have active subscription");
                  Analytics.logEvent('login_v1', {
                    result: 'failed',
                    screen: this.constructor.name,
                    reason: 'no active subscription',
                    message: 'show subscription page',
                  })
                  RootNavigation.navigate('SubscriptionOffer', {userId: user.uid, user_email:user.email})
                 }
              } else {
                // console.log("No subscriber doc, new customer");
                Analytics.logEvent('login_v1', {
                  result: 'failed',
                  screen: this.constructor.name,
                  reason: 'no subscriber document',
                  message: 'show subscription page',
                })
                RootNavigation.navigate('SubscriptionOffer', {userId:user.uid, user_email:user.email})
              }
            })
            .catch( error => {
              // console.log("Error: " + error);
            });
          } else {
            // console.log("User email is not verified");
            Analytics.logEvent('signup_v1', {
              result: 'in_progress',
              screen: this.constructor.name,
              reason: 'email not verified',
              message: '',
            })
            RootNavigation.navigate('WaitForEmailVerification', {userId: user.email})
          }
        }
      } else {
        // console.log("No User");
        var currentRoute = RootNavigation.getCurrentRouteName();
        if (currentRoute != "ShowSplash") {
          //This causes to unmount all components and will unsubscribe from video and favourite updates
          RootNavigation.resetNaviStack()
        }
      }
    })
  }

  showSignupScreen = async () => {
      RootNavigation.navigate('SignUp')
  }

  showLoginScreen = async () => {
      // console.log("SplashScreen.showLoginScreen");
      RootNavigation.navigate('Login');
  }

  onLeardMoreToggled(learnMoreActive){
    // console.log("SplashScreen.onLeardMoreToggled to " + !learnMoreActive);
    Analytics.logEvent('toggle_learn_more_v1', {
      screen:this.constructor.name,
      state:!learnMoreActive,
    })
    this.setState({learnMoreActive:!learnMoreActive})
  }

  isLearnMoreActive() {
    let learnMoreActive = this.state.learnMoreActive;
    return learnMoreActive;
  }


render() {
  const width = this.width;
  const height = this.height;
  var scale = this.widthScale;
  if ( scale > 1.5 ) {
    scale = 1.5
  }
  if (Dimensions.get('window').width< 420  ) {
    return (
      <View style={styles.containerPhonePortrait}>
      <ScrollView  contentContainerStyle={{flex: 1}}>
        <ImageBackground
          style={styles.backgroundImage}
          imageStyle={{resizeMode: 'cover'}}
          source={imagepaths.background}
          >
          <View style={{flex:1}}>
            <View style={styles.containerTopPhonePortrait}>
              <Image
                style={styles.logoLeftPhonePortrait}
                source={imagepaths.logo_transparent}
                />
            </View>
            <View style={styles.containerPhonePortraitBottom}>
              <View style={{width: '90%',alignSelf: 'center',textAlign: 'center'}}>
              <Text style={{fontFamily: 'Arial', fontWeight: 'bold', fontSize:24, color: colors.POPUP_LOGO_PINK,alignSelf: 'center',textAlign: 'center'}}>Joogaa suurella sydämellä.</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{width.SPLASH_PITCH_TWO}</Text>
              <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{strings.SPLASH_PITCH_TWO}</Text>
              </View>
              <View style={styles.formPhonePortrait} behavior="padding">
                <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, alignSelf: 'center',textAlign: 'center', marginBottom: "1%",opacity:1.0}}>Harjoittele kotona. Rajaton katseluoikeus, {'\n'} viikottaisia livetunteja. Voit irtisanoa {'\n'}koska tahansa.</Text>
                <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{strings.SPLASH_PITCH_TWO}</Text>
                <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{strings.SPLASH_PITCH_TWO}</Text>
                <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{strings.SPLASH_PITCH_TWO}</Text>
                <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{strings.SPLASH_PITCH_TWO}</Text>
                <PopUpButton
                  textStyle={{fontSize: 14}}
                  containerStyle={{width: "75%", alignSelf:'center'}}
                  label="Liity Pop Upin Videoporukkaan"
                   onPress={this.showSignupScreen}
                   />
                 <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 20,marginBottom: "2%"}}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                 <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                   <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                   <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                 </View>
               </TouchableOpacity>

               {this.isLearnMoreActive() && (
                 <View style={styles.learnMoreformPhonePortrait}>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}
                   </Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}
                   </Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}
                   </Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}
                   </Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}
                   </Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}
                   </Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}
                   </Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}
                   </Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}
                   </Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                   </Text>
                   <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                     <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                       <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                       <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                     </View>
                   </TouchableOpacity>
                 </View>)}

               </View>
               {!this.isLearnMoreActive() && (
                <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                  <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                    <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                    <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                  </View>
                </TouchableOpacity>)}
             </View>
           </View>
         </ImageBackground>
      </ScrollView>
      </View>
    );
  }
  else if (Dimensions.get('window').height< 365 ) {
    return (
      <View style={styles.container}>
        <ScrollView  contentContainerStyle={{flex: 1}}>
          <ImageBackground
            style={styles.backgroundImage}
            imageStyle={{resizeMode: 'cover'}}
            source={imagepaths.background}
            >
            <View style={{flex:1, flexDirection: 'row'}}>
              <View style={styles.firstPartPhoneLanscape}>
               <Image
                   style={styles.logoLeftPhoneLanscape}
                     source={imagepaths.logo_transparent}
                   />
              </View>
              <View style={styles.secondPartPhoneLanscape}>
                <View style={styles.formPhoneLanscape} behavior="padding">
                  <Text style={{fontFamily: 'Arial', fontSize: 24, color: colors.POPUP_LOGO_PINK,marginTop: "15%", marginBottom: "1%",textAlign: 'center'}}>Joogaa suurella sydämellä.</Text>
                  <Text style={{fontFamily: 'Arial', fontSize: 16, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "5%", marginRight: "5%"}}>Harjoittele kotona. Rajaton katseluoikeus sekä viikottaisia {'\n'}livetunteja. Voit irtisanoa koska tahansa.</Text>
                  <PopUpButton
                    textStyle={{fontSize: 16}}
                    containerStyle={{width: "50%", alignSelf:'center' }}
                    label="Liity Pop Upin Videoporukkaan"
                     onPress={this.showSignupScreen}
                     />
                     <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 20,marginBottom: "2%"}}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                     <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                       <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                       <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                     </View>
                   </TouchableOpacity>

                   {this.isLearnMoreActive() && (
                     <View style={styles.learnMoreformPhonePortrait}>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}
                       </Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}
                       </Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}
                       </Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}
                       </Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}
                       </Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}
                       </Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}
                       </Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}
                       </Text>
                       <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}
                       </Text>
                       <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                       </Text>

                       <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom:"2%"}}  onPress={() => this.showLoginScreen()}>
                         <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                           <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                           <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                         </View>
                       </TouchableOpacity>
                     </View>
                   )}
                  {!this.isLearnMoreActive() && (
                    <TouchableOpacity style={{width: "100%", alignSelf:'center',paddingBottom:"1%"}}  onPress={() => this.showLoginScreen()}>
                     <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                       <Text style={{fontSize: 14, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                       <Text style={{fontSize: 14, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                     </View>
                   </TouchableOpacity>)}

                 </View>
               </View>
               <View style={styles.thirdPartPhoneLanscape}>
                </View>
              </View>
            </ImageBackground>
          </ScrollView>
      </View>
    );
  }
  else if (Dimensions.get('window').height< 750 ) {
    return (
      <View style={styles.container}>
        <ScrollView  contentContainerStyle={{flex: 1}}>
        <ImageBackground
          style={styles.backgroundImage}
          imageStyle={{resizeMode: 'cover'}}
          source={imagepaths.background}
          >
          <View style={{flex:1, flexDirection: 'row'}}>
            <View style={styles.firstPart}>
             <Image
                 style={styles.logoLeft}
                   source={imagepaths.logo_transparent}
                 />
            </View>
            <View style={styles.secondPart}>
              <KeyboardAvoidingView style={styles.form} behavior="padding">
                <Text style={{fontFamily: 'Arial', fontSize: 40, color: colors.POPUP_LOGO_PINK,marginTop: "5%", marginBottom: "1%",textAlign: 'center'}}>{strings.SPLASH_PITCH_ONE}</Text>
                <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{strings.SPLASH_PITCH_TWO}</Text>
                <Text style={{fontFamily: 'Arial', fontSize: 20, color: colors.WHITE, textAlign: 'center', marginBottom: "2%"}}>Harjoittele kotona. Rajaton katseluoikeus sekä viikottaisia {'\n'}livetunteja. Voit irtisanoa koska tahansa.</Text>
                <PopUpButton
                  textStyle={{fontSize: 20}}
                  containerStyle={{width: "75%",alignSelf:'center'}}
                  label={strings.INTERESTED_JOIN_POP_UP_COMMUNITY}
                   onPress={this.showSignupScreen}
                   />

                   <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 20,marginBottom: "2%"}}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                   <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                     <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                     <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                   </View>
                 </TouchableOpacity>

                 {this.isLearnMoreActive() && (
                   <View style={styles.learnMoreformPhonePortrait}>
                     <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}
                     </Text>
                     <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}
                     </Text>
                     <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}
                     </Text>
                     <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}
                     </Text>
                     <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}
                     </Text>
                     <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}
                     </Text>
                     <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}
                     </Text>
                     <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}
                     </Text>
                     <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}
                     </Text>
                     <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                     </Text>

                     <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                       <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                         <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                         <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                       </View>
                     </TouchableOpacity>
                   </View>
                 )}
                {!this.isLearnMoreActive() && ( <TouchableOpacity style={{flex:1,width: "100%", alignSelf:'center', marginTop: "8%",paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                   <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                   <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                   <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                   </View>
                   </TouchableOpacity>)}
              </KeyboardAvoidingView>
            </View>
           <View style={styles.thirdPart}>
           </View>
          </View>
        </ImageBackground>
        </ScrollView>
      </View>
    );
  } else {
    return (
      <View style={styles.container}>
        <View style={styles.firstPart}>
         <Image
             style={styles.logoLeft}
               source={imagepaths.logo_transparent}
             />
         </View>
         <ScrollView  contentContainerStyle={{flex: 1}}>
         <View style={styles.secondPart}>
            <Image
                style={[styles.opeImageLarge,{width: 800*scale, height:200*scale}]}
                  source={imagepaths.opet_background}
                />
           <KeyboardAvoidingView style={styles.form} behavior="padding">

           <Text style={{fontFamily: 'Arial', fontSize: 46, color: colors.POPUP_LOGO_PINK,marginTop: "2%", marginBottom: "1%",textAlign: 'center'}}>{strings.SPLASH_PITCH_ONE}</Text>
             <Text style={{fontFamily: 'Arial', fontSize: 36, color: colors.WHITE, textAlign: 'center', marginBottom: "2%", marginLeft: "15%", marginRight: "15%"}}>{strings.SPLASH_PITCH_TWO}</Text>
               <Text style={{fontFamily: 'Arial', fontSize: 20, color: colors.WHITE, textAlign: 'center', marginBottom: "1%"}}> {strings.SPLASH_PITCH} </Text>

               <PopUpButton
                 containerStyle={{width: 550, alignSelf:'center' }}
                 textStyle={{fontSize: 22}}
                  label={strings.INTERESTED_JOIN_POP_UP_COMMUNITY}
                   onPress={this.showSignupScreen}
                 />
                 <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 20,marginBottom: "2%"}}  onPress={() => {this.onLeardMoreToggled(this.state.learnMoreActive)}}>
                 <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                   <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}></Text>
                   <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>{strings.READ_MORE_ABOUT_VIDEOSERVICE}</Text>
                 </View>
               </TouchableOpacity>

               {this.isLearnMoreActive() && (
                 <View style={styles.learnMoreformPhonePortrait}>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_IS_VIDEOSERVICE_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.DOES_CLASSES_FIT_FOR_ME}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.DOES_CLASSES_FIR_FOR_ME_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHERE_CAN_I_WATCH}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHERE_CAN_I_WATCH_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.WHAT_DOES_IT_COST}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.WHAT_DOES_IT_COST_DESC}</Text>
                   <Text style={styles.learnMoreformTitlePhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION}</Text>
                   <Text style={styles.learnMoreformDescriptionPhonePortrait}>{strings.HOW_TO_CANCEL_SUBSCRIPTION_DESC}
                   </Text>

                   <TouchableOpacity style={{width: "100%", alignSelf:'center', paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                     <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                       <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                       <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                     </View>
                   </TouchableOpacity>
                 </View>
               )}
              {!this.isLearnMoreActive() && ( <TouchableOpacity style={{width: "100%", alignSelf:'center', marginTop: "8%",paddingBottom: 2}}  onPress={() => this.showLoginScreen()}>
                 <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start'}}>
                   <Text style={{fontSize: 18, color: colors.WHITE, opacity: 0.7, textAlign: 'center'}}>  {strings.ALREADY_USER} </Text>
                   <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}>  {strings.LOGIN} </Text>
                 </View>
                 </TouchableOpacity>)}
            </KeyboardAvoidingView>
         </View>
         <View style={styles.thirdPart}>
          </View>
        </ScrollView>
      </View>
    );
    }
  }
}

const styles = StyleSheet.create ({
    containerPhonePortrait: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: colors.BLACK,
    },
    containerTopPhonePortrait: {
      // flex:1,
      // backgroundColor: colors.BLACK,
      width: "100%",
      height: "25%",
    },
    logoLeftPhonePortrait: {
      marginTop: "3%",
      marginLeft: "3%",
      width: 50,
      height: 50,
    },
    containerPhonePortraitBottom: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "100%",
      height: "75%",
      paddingBottom: "5%"
    },
    backgroundImage: {
      flex: 1,
      // resizeMode: "repeat",
      justifyContent: "center"
    // alignSelf: 'center',
    // marginTop: "10%",
    // marginBottom: "10%",
    // transform: [
    //   { rotateX: '25deg'},
    // ],
    // opacity: 0.7
    },
    formPhonePortrait: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "85%",
      // backgroundColor: colors.BLACK
    },
    learnMoreformPhonePortrait: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      // backgroundColor: colors.BLACK
    },
    learnMoreformTitlePhonePortrait:
    {
      fontFamily: 'Arial',
      fontSize: 14,
      fontWeight: 'bold',
      color: colors.LIGHT_GREY,
      textAlign: 'left',
      padding: 3,
      marginBottom: "2%",
      marginLeft: "5%",
      marginRight: "0%",
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: colors.LIGHT_GREY,
    },
    learnMoreformDescriptionPhonePortrait: {
      fontFamily: 'Arial',
      fontSize: 14,
      color: colors.LIGHT_GREY,
      textAlign: 'left',
      padding: 10,
      marginBottom: "5%",
      marginLeft: "5%",
      marginRight: "0%",
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: colors.LIGHT_GREY,
    },
    firstPartPhoneLanscape: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "15%",
    },
    secondPartPhoneLanscape: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      alignItems: 'center',
      width: "70%",
    },
    thirdPartPhoneLanscape: {
      // backgroundColor: colors.BLACK,
      justifyContent:'flex-start',
      alignItems: 'center',
      paddingTop: "3%",
      width: "15%",
    },
    formPhoneLanscape: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      // backgroundColor: colors.BLACK
    },
   logoLeftPhoneLanscape: {
     position: 'absolute',
     top:"5%",
     left: "25%",
     width: 60,
     height: 60,
    },
    opeImageSmallPhoneLanscape: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      marginTop: "3%",
      // width: "80%",
      // height: 216,
      transform: [
      //   { perspective: 850 },
      //   { translateX: - Dimensions.get('window').width * 0.24 },
        { rotateX: '25deg'},
        // { rotateZ: '1deg'}
      ],
      opacity: 0.5
   },
    container: {
      flex: 1,
      flexDirection: 'row',
      backgroundColor: colors.BLACK,
    },
    firstPart: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      width: "15%",
    },
    secondPart: {
      // backgroundColor: colors.BLACK,
      justifyContent: "center",
      alignItems: 'center',
      width: "70%",
    },
    thirdPart: {
      // backgroundColor: colors.BLACK,
      justifyContent:'flex-start',
      alignItems: 'center',
      paddingTop: 30,
      width: "15%",
    },
    form: {
      flex: 1,
      alignSelf: "center",
      justifyContent: 'flex-start',
      width: "100%",
      // backgroundColor: colors.BLACK
    },
   logoLeft: {
     position: 'absolute',
     top:20,
     left: "15%",
     width: 120,
     height: 120,
    },
    opeImageSmall: {
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      // marginTop: "5%",
      // width: "80%",
      // height: 216,
      // opacity: 0.9,
      transform: [
      //   { perspective: 850 },
      //   { translateX: - Dimensions.get('window').width * 0.24 },
        { rotateX: '25deg'},
        // { rotateZ: '1deg'}
      ],
      opacity: 0.7
   },
   opeImageLarge: {
     alignItems: 'center',
     justifyContent: 'center',
     marginTop: "10%",
     //width: 800,
     //height: 200,
     opacity: 0.9,
     transform: [
     //   { perspective: 850 },
     //   { translateX: - Dimensions.get('window').width * 0.24 },
       { rotateX: '25deg'},
       // { rotateZ: '1deg'}
     ],
     opacity: 0.7
  },
});


const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateEmail, updatePassword, signup, getUser }, dispatch)
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SplashScreen)
