import React from 'react';
import {TouchableOpacity, Linking, ScrollView, Button, Image, StyleSheet, Text, View} from 'react-native';
import colors from '../config/colors'
import strings from '../config/strings';
import imagepaths from '../config/imagepaths';
import * as RootNavigation from '../controllers/RootNavigation';
import VideoItem from '../components/VideoItem'
import metrics, { logowidth } from "../config/metrics"
import {connect} from 'react-redux'
import PopUpButton from '../components/PopUpButton'
import Firebase, { db } from '../config/Firebase'
import * as Analytics from 'expo-firebase-analytics';


export default class VideoItemDetailsScreen extends React.Component  {

  constructor(props) {
    super(props)
    this.state = {
      is_favourite: props.videoItem.is_favourite
    }
  }

  updateFavouriteStatus = (status) => {
      this.setState({is_favourite:status});
  }

  componentDidMount = () => {
    if (this.props.videoitem !=null && this.props.videoItem.is_favourite != null) {
      this.setSate({is_favourite:this.props.videoItem.is_favourite})
    }
  }

  onPlayvideo = () => {
    db.collection('subscribers')
    .doc(Firebase.auth().currentUser.uid)
    .get()
    .then( (doc) => {
      if (doc.exists) {
        if ( doc.data().is_active ) {
          // console.log("User has active subscription");
          Analytics.logEvent('play_video_v1', {
              screen: this.constructor.name,
               id: this.props.videoItem.uid,
               title: this.props.videoItem.title,
               class_type: this.props.videoItem.class_type,
               teacher: this.props.videoItem.teacher,
               is_favourite: this.props.videoItem.is_favourite ? 'favourite' : 'not_favourite'
             })
          RootNavigation.navigate('PlayWebVideo', {videoUrl: this.props.videoItem.url});
        } else {
          // console.log("User doesn't have active subscription");
          Analytics.logEvent('play_video_failed_v1', {
               screen: this.constructor.name,
               reason: 'no active subscription',
               message: '',
             })
          RootNavigation.navigate('SubscriptionOffer', {userId:Firebase.auth().currentUser.uid, user_email:Firebase.auth().currentUser.email})
         }
      } else {
        // console.log("No subscriber doc, new customer");
        Analytics.logEvent('play_video_failed_v1', {
           screen: this.constructor.name,
           reason: 'subscribers document missing',
           message: '',
         })
        RootNavigation.navigate('SubscriptionOffer', {userId:Firebase.auth().currentUser.uid, user_email:Firebase.auth().currentUser.email})
      }
    })
    .catch( error => {
      // console.log("Error: " + error);
      Analytics.logEvent('error_v1', {
         screen: this.constructor.name,
         action: 'play video',
         reason: 'firestore - error when getting subscribers document',
         message: ''
       })
    });
  }


  onRemoveFavourite = (obj) => {
    let favourite_videos;
    db.collection('favourite_videos')
    .doc(Firebase.auth().currentUser.uid)
    .get()
    .then( (doc) => {
      if(doc.exists) {
        favourite_videos = doc.data().video_uids;
        const index = favourite_videos.indexOf(this.props.videoItem.uid);
        if ( index > -1 ) {
          favourite_videos.splice(index,1);
          db.collection("favourite_videos").doc(Firebase.auth().currentUser.uid).update({
            video_uids: favourite_videos
          })
          .then(function() {
            Analytics.logEvent('remove_favourite_video_v1', {
               id: obj.props.videoItem.uid,
               title: obj.props.videoItem.title,
               class_type: obj.props.videoItem.class_type,
               teacher: obj.props.videoItem.teacher
             })
            obj.updateFavouriteStatus(false);
          })
          .catch(function(error){
            // console.log("Error while updating document: " + error);
            Analytics.logEvent('error_v1', {
                action: 'remove favourite video',
                reason: 'error when updating favourite document',
                message: ''
              })
          });
        }
      } else {
        // console.log("User doesn't have favourites in db, will set false locally");
        Analytics.logEvent('error_v1', {
            action: 'remove favourite video',
            reason: 'favourites document missing',
            message: ''
          })
        obj.updateFavouriteStatus(false);
      }
    })
    .catch(function(error){
      // console.log("Error: " + error);
    });
  }

  onAddFavourite = (obj) => {
      //Get user favourite videos
      let favourite_videos;
      db.collection('favourite_videos')
      .doc(Firebase.auth().currentUser.uid)
      .get()
      .then( (doc) => {
        if (doc.exists) {
          favourite_videos = doc.data().video_uids;
          // //Append new favourite
          favourite_videos.push(this.props.videoItem.uid);
          db.collection("favourite_videos").doc(Firebase.auth().currentUser.uid).update({
            video_uids: favourite_videos
          })
          .then(function() {
            Analytics.logEvent('add_favourite_video_v1', {
               id: obj.props.videoItem.uid,
               title: obj.props.videoItem.title,
               class_type: obj.props.videoItem.class_type,
               teacher: obj.props.videoItem.teacher
             })
            obj.updateFavouriteStatus(true);
          })
          .catch(function(error) {
            console.error("Error writing document: ", error);
            Analytics.logEvent('error_v1', {
              action: 'add favourite video',
              reason: 'error when updating favourite document',
              message: ''
            })
          });
          }
        else {
          favourite_videos = [this.props.videoItem.uid]
          db.collection("favourite_videos").doc(Firebase.auth().currentUser.uid).set({
            video_uids: favourite_videos
          })
          .then(function() {
            Analytics.logEvent('add_favourite_video_v1', {
               id: this.props.videoItem.uid,
               title: this.props.videoItem.title,
               class_type: this.props.videoItem.class_type,
               teacher: this.props.videoItem.teacher
             })
            obj.updateFavouriteStatus(true);
          })
          .catch(function(error) {
            // console.error("Error writing document: ", error);
            Analytics.logEvent('error_v1', {
              action: 'add favourite video',
              reason: 'error when setting favourite document',
              message: ''
            })
          });
        }
      })
      .catch( error => {
        // console.log("Error: " + error);
        Analytics.logEvent('error_v1', {
          action: 'add favourite video',
          reason: 'error to get document from collection',
          message: ''
        })
      });

  }

  render() {
      if ( metrics.DEVICE_WIDTH < 1000 ) {
        return (
          <ScrollView style={{backgroundColor:colors.WHITE}}>
               <View style={styles.container}>
                 <View style={styles.formSmall}>
                   <Image style={styles.image} source={this.props.videoItem.teacherImage} />
                   <View>
                     <Text style={styles.titleText}>{this.props.videoItem.title} ({this.props.videoItem.duration}min) </Text>
                     <Text style={styles.titleText}>- {this.props.videoItem.teacher} -</Text>
                   </View>
                   <PopUpButton style={styles.headerQuickActions} label={strings.VIDEO_PLAY} onPress={() => this.onPlayvideo()}/>
                    <Text style={styles.descriptionText}>{this.props.videoItem.long_description}</Text>
                    <Text style={styles.descriptionText}>{strings.CLASS_ADDED_DATE}</Text>
                    <Text style={styles.formText}>{this.props.videoItem.added} </Text>
                    <Text style={styles.formText}>  </Text>
                    {this.state != null && this.state.is_favourite === false && (
                      <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, alignSelf:'center'}} onPress={() => this.onAddFavourite(this)}>
                        <Image style={styles.likeImage} source={imagepaths.icon_add_to_favourites} />
                       <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.ADD_AS_FAVOURITE} </Text>
                    </TouchableOpacity>
                    )}
                    {this.state != null && this.state.is_favourite === true && (
                      <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, alignSelf:'center'}} onPress={() => this.onRemoveFavourite(this)}>
                        <Image style={styles.unlikeImage} source={imagepaths.icon_remove_from_favourites} />
                       <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.REMOVE_FAVOURITE} </Text>
                    </TouchableOpacity>
                    )}
                 </View>
               </View>
           </ScrollView>
        )
        } else {
         return (
         <ScrollView style={{backgroundColor:colors.WHITE}}>
              <View style={styles.container}>
                <View style={styles.formLarge}>
                  <Image style={styles.profileImage} source={this.props.videoItem.teacherImage} />
                  <View>
                    <Text style={styles.titleText}>{this.props.videoItem.title} ({this.props.videoItem.duration}min) </Text>
                    <Text style={styles.titleText}>- {this.props.videoItem.teacher} -</Text>
                  </View>
                  <PopUpButton style={styles.headerQuickActions} label={strings.VIDEO_PLAY} onPress={() => this.onPlayvideo()}/>
                   <Text style={styles.descriptionText}>{this.props.videoItem.long_description}</Text>
                   <Text style={styles.descriptionText}>{strings.CLASS_ADDED_DATE}</Text>
                   <Text style={styles.formText}>{this.props.videoItem.added} </Text>
                   <Text style={styles.formText}>  </Text>
                  {this.state != null && this.state.is_favourite === false && (
                    <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, alignSelf:'center'}} onPress={() => this.onAddFavourite(this)}>
                      <Image style={styles.likeImage} source={imagepaths.icon_add_to_favourites} />
                     <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.ADD_AS_FAVOURITE} </Text>
                  </TouchableOpacity>
                  )}
                  {this.state != null && this.state.is_favourite === true && (
                    <TouchableOpacity style={{width: metrics.DEVICE_WIDTH/2, alignSelf:'center'}} onPress={() => this.onRemoveFavourite(this)}>
                      <Image style={styles.unlikeImage} source={imagepaths.icon_remove_from_favourites} />
                     <Text style={{fontSize: 18, color: colors.DODGER_BLUE, textAlign: 'center'}}> {strings.REMOVE_FAVOURITE} </Text>
                  </TouchableOpacity>
                  )}

                </View>
              </View>
          </ScrollView>
        )
      }
    }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.WHITE,
    alignItems: 'center',
    justifyContent: "space-between",
  },
  likeImageUp: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 60/1.5,
    height:55/1.5,
  },
  image: {
    marginBottom: 10,
    alignSelf: "center",
    width: metrics.DEVICE_WIDTH,
    height: metrics.DEVICE_HEIGHT/3.5,
  },
  likeImage: {
    // marginBottom: 10,
    alignSelf: "center",
    width: 60/1.5,
    height:55/1.5,
  },
  unlikeImage: {
    // marginBottom: 10,
    alignSelf: "center",
    width: 60/1.5,
    height:55/1.5,
  },
  profileImage: {
    marginTop: "10%",
    marginBottom: 10,
    alignSelf: "center",
    width: logowidth(),
    height: logowidth(),
    borderRadius: 150 / 2,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: colors.POPUP_LOGO_PURPLE
  },
  formSmall: {
    flex: 1,
    backgroundColor: colors.WHITE,
    justifyContent:'flex-start',
    width: "80%",
  },
  formLarge: {
    flex: 1,
    backgroundColor: colors.WHITE,
    justifyContent:'flex-start',
    width: "50%",
  },
  titleText: {
    fontSize: 16,
    color: colors.POPUP_LOGO_PURPLE,
    padding:3,
    alignSelf:'center',
    textAlign: 'center'
  },
  formText: {
    fontSize: 18,
    color: colors.BLACK,
    //padding:10,
    alignSelf:'center'
  },
  descriptionText: {
    fontSize: 15,
    color: colors.BLACK,
    padding:10,
    alignSelf:'center'
  }
})
